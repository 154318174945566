import { Button } from '@chakra-ui/react';
import { EventViewModel } from '../../types/models';
import { formatDate, formatLocaleDate } from '../../utils/helpers/helpers';
import { toast } from 'react-toastify';
import { deleteEvent } from '../../services/eventsServices';
import { useApiCall } from '../../utils/hooks/useApiCall';

interface PatientVisitCardProps {
  event: EventViewModel;
  refetch: () => void;
}

export const PatientVisitCard = ({ event, refetch }: PatientVisitCardProps) => {
  const api = useApiCall();
  const handleDeleteEvent = async () => {
    try {
      await deleteEvent(event.id, api);
      refetch();
      toast.success('Evento cancelado com sucesso');
    } catch (error) {
      toast.error('Ocorreu um erro ao cancelar o evento');
    }
  };
  return (
    <div className="border border-x-slate-200 p-2 rounded-lg flex flex-col gap-4 items-end">
      <div className="w-full flex justify-between text-sm ">
        <div className="w-2/5">{event.title}</div>
        <div className="w-1/5">{event.agenda.researcher?.people.name}</div>
        <div className="w-1/5">
          {formatLocaleDate(event.start)} - {formatLocaleDate(event.end)}
        </div>
      </div>
      <div>
        <Button onClick={handleDeleteEvent}>Cancelar</Button>
      </div>
    </div>
  );
};
