import {
  Box,
  IconButton,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import { PanelWrapper } from '../../components/shared/PanelWrapper/PannelWrapper';
import { FilterContainer } from '../../components/shared/Filter/FilterContainer';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { useQuery } from '../../utils/hooks/useQuery';
import { ResearchCenterStudyViewModel } from '../../types/models';
import { useState } from 'react';
import { ResearchCenterStudyModal } from '../../components/ResearchCenterStudies/ResearchCenterStudyModal';
import { DeleteConfirmationPopover } from '../../components/shared/Popover/DeleteConfirmationPopover';
import { toast } from 'react-toastify';
import { useApiCall } from '../../utils/hooks/useApiCall';
import { deleteResearchCenterStudy } from '../../services/researchCenterStudiesServices';
import { formatDate } from '../../utils/helpers/helpers';

const columns = [
  { label: 'Nome' },
  { label: 'Nome Estudo', key: 'acronym' },
  { label: 'Nome Centro', key: 'name' },
  { label: 'Data Inicio Rec', key: 'studyId' },
  { label: 'Data Fim Rec', key: 'industry.name' },
  { label: 'Ações', key: 'actions' },
];

export const ResearchCenterStudyPage = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [centerStudyData, setCenterStudy] = useState<
    ResearchCenterStudyViewModel | undefined
  >();
  const [centerStudyLoading, setCenterStudyLoading] = useState(false);
  const { data: centerStudies, refetch } = useQuery<
    ResearchCenterStudyViewModel[]
  >('/research-center-studies');
  const api = useApiCall();

  const handleClose = () => {
    setCenterStudy(undefined);
    onClose();
  };

  const handleOpenEditModal = (usr: ResearchCenterStudyViewModel) => {
    setCenterStudy(usr);
    onOpen();
  };

  const handleDelete = async (centerStudyId: number) => {
    try {
      setCenterStudyLoading(true);
      await deleteResearchCenterStudy(centerStudyId, api);
      refetch();
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      setCenterStudyLoading(false);
    }
  };

  return (
    <>
      <ResearchCenterStudyModal
        centerStudyId={centerStudyData?.id}
        refetch={refetch}
        isOpen={isOpen}
        onClose={handleClose}
      />
      <PanelWrapper title="Estudos Clínicos por Centro de Pesquisa">
        <FilterContainer onOpenModal={onOpen} />
        <Box className="rounded-lg shadow p-4 pt-10 mt-5">
          <Table>
            <Thead>
              <Tr>
                {columns.map((column) => (
                  <Th key={column.key}>{column.label}</Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {centerStudies?.map((row) => (
                <Tr
                  key={row.id}
                  className="hover:bg-gray-50 hover:cursor-pointer"
                >
                  <Td>{row.name}</Td>
                  <Td>{row.clinicalStudy?.name}</Td>
                  <Td>{row.researchCenter?.people?.name}</Td>
                  <Td>{formatDate(row.recruitingStartDate)}</Td>
                  <Td>{formatDate(row.recruitingEndDate)}</Td>

                  <Td>
                    <div className="w-full flex justify-around">
                      <DeleteConfirmationPopover
                        loading={centerStudyLoading}
                        onConfirm={() => handleDelete(row.id)}
                      >
                        <IconButton
                          colorScheme="red"
                          aria-label="Excluir"
                          icon={<DeleteIcon color="white" />}
                        />
                      </DeleteConfirmationPopover>
                      <IconButton
                        onClick={() => handleOpenEditModal(row)}
                        colorScheme="blue"
                        aria-label="Editar"
                        icon={<EditIcon color="white" />}
                      />
                    </div>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      </PanelWrapper>
    </>
  );
};
