import { Select, Text } from '@chakra-ui/react';
import { ChangeEvent } from 'react';

interface SelectInputProps {
  id: string;
  options: { value: string; label: string }[];
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
  label: string;
  value: string;
  disabled?: boolean;
}

export const SelectInput = ({
  id,
  options,
  onChange,
  label,
  value,
  disabled = false,
}: SelectInputProps) => {
  return (
    <>
      <Text className="text-sm text-slate-700">{label}</Text>
      <Select disabled={disabled} value={value} id={id} onChange={onChange}>
        <option value={undefined}>SELECIONE</option>
        {options.map((opt) => (
          <option key={opt.value} value={opt.value}>
            {opt.label}
          </option>
        ))}
      </Select>
    </>
  );
};
