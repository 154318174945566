import { format } from 'date-fns';
import { AttachmentViewModel, EventViewModel } from '../../types/models';
import { FormSection } from '../shared/Form/FormSection';
import { LabeledInput } from '../shared/Form/LabeledInput';
import { MainModal } from '../shared/Modal/MainModal';
import { ResearcherVisitProcedureCard } from './ResearcherVisitProcedureCard';
import { Box, Button, useDisclosure } from '@chakra-ui/react';
import { getOneEvent, modifyEvent } from '../../services/eventsServices';
import { useApiCall } from '../../utils/hooks/useApiCall';
import { useCallback, useEffect, useState } from 'react';
import { ConfirmationPopover } from '../shared/Popover/ConfirmationPopover';
import { toast } from 'react-toastify';
import { modifyEventVisit } from '../../services/eventVisitsServices';
import {
  VisitSuggestion,
  getNextSuggestedVisit,
} from '../../services/visitsServices';
import { SuggestVisitModal } from './SuggestVisitModal';
import { AttachmentCard } from '../Attachments/AttachmentCard';
import { SelectInput } from '../shared/Form/SelectInput';
import { modifyStudyPatient } from '../../services/studyPatientsServices';

interface ResearcherEventModalProps {
  isOpen: boolean;
  onClose: () => void;
  eventId?: number;
  refetch: (agendaId: number) => Promise<void>;
}

export const ResearcherEventModal = ({
  isOpen,
  onClose,
  eventId,
  refetch,
}: ResearcherEventModalProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [event, setEvent] = useState<EventViewModel | undefined>();
  const [randomized, setRandomized] = useState<string>('');
  const [markedProcedures, setMarkedProcedures] = useState<number[]>([]);
  const [unsuccess, setUnsuccess] = useState<number[]>([]);
  const [nextSuggestedPatientVisit, setNextSuggestedPatientVisit] = useState<
    VisitSuggestion | undefined
  >();
  const [attachments, setAttachments] = useState<AttachmentViewModel[]>([]);

  const {
    isOpen: isOpenSuggestion,
    onOpen: onOpenSuggestion,
    onClose: onCloseSuggestion,
  } = useDisclosure();
  const api = useApiCall();

  const getAttachments = useCallback(
    async (data?: EventViewModel) => {
      if (data?.eventVisit.visit && data.studyPatient.researchCenterStudyId) {
        const attachments = await api.get<AttachmentViewModel[]>(
          `/attachments/${data?.eventVisit.visit?.id}/${data.studyPatient.researchCenterStudyId}`
        );
        setAttachments(attachments);
      }
    },
    [api]
  );

  const fetchEvent = useCallback(async () => {
    if (eventId) {
      const data = await getOneEvent(eventId, api);
      setEvent(data);
      setMarkedProcedures([
        ...JSON.parse(data?.eventVisit.successProcedures ?? '[]'),
        ...JSON.parse(data?.eventVisit.unsuccessProcedures ?? '[]'),
      ]);
      setUnsuccess(JSON.parse(data?.eventVisit.unsuccessProcedures ?? '[]'));
      getAttachments(data);
    }
  }, [api, eventId, getAttachments]);

  useEffect(() => {
    if (eventId && eventId !== event?.id) {
      fetchEvent();
    }
  }, [eventId, event, fetchEvent]);

  const handleSetUnproductive = async () => {
    try {
      if (event) {
        setLoading(true);
        await modifyEvent(event.id, { unproductive: true }, api);
      }
    } catch (error) {
      toast.error('Ocorreu um erro na requisição');
    } finally {
      setLoading(false);
    }
  };

  const handleSuccessProcedure = async (procedureId: number) => {
    try {
      setLoading(true);
      const successProcedures = Array.from(
        new Set([
          ...JSON.parse(event?.eventVisit.successProcedures ?? '[]'),
          procedureId,
        ])
      );
      await modifyEventVisit(
        event?.eventVisit?.id ?? 0,
        { successProcedures: JSON.stringify(successProcedures) },
        api
      );
      fetchEvent();
    } catch (error) {
      toast.error('Ocorreu um erro na requisição');
    } finally {
      setLoading(false);
    }
  };

  const handleUnsuccessProcedure = async (procedureId: number) => {
    try {
      const unsuccessProcedures = Array.from(
        new Set([
          ...JSON.parse(event?.eventVisit.unsuccessProcedures ?? '[]'),
          procedureId,
        ])
      );
      await modifyEventVisit(
        event?.eventVisit?.id ?? 0,
        { unsuccessProcedures: JSON.stringify(unsuccessProcedures) },
        api
      );
      fetchEvent();
    } catch (error) {
      toast.error('Ocorreu um erro na requisição');
    } finally {
      setLoading(false);
    }
  };

  const handleResetProcedure = async (procedureId: number) => {
    try {
      const unsuccessProcedures = JSON.stringify(
        JSON.parse(event?.eventVisit.unsuccessProcedures ?? '[]').filter(
          (pr: number) => pr !== procedureId
        )
      );
      const successProcedures = JSON.stringify(
        JSON.parse(event?.eventVisit.successProcedures ?? '[]').filter(
          (pr: number) => pr !== procedureId
        )
      );
      await modifyEventVisit(
        event?.eventVisit?.id ?? 0,
        { unsuccessProcedures, successProcedures },
        api
      );
      fetchEvent();
    } catch (error) {
      toast.error('Ocorreu um erro na requisição');
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    onCloseSuggestion();
    setEvent(undefined);
    onClose();
  };

  const handleFinishEvent = async () => {
    if (markedProcedures.length !== event?.eventVisit.visit.procedures.length) {
      return toast.error('Existem procedimentos em aberto');
    }
    if (randomized === '' || randomized == 'SELECIONE') {
      return toast.error('Ë necessario informar a randomização');
    }

    setLoading(true);
    await modifyEvent(
      event?.id ?? 0,
      {
        finished: true,
        finishedAt: new Date().toISOString(),
        pending: Boolean(unsuccess.length),
      },
      api
    );

    await modifyStudyPatient(
      event.studyPatientId,
      {
        randomized: true,
        passedRandomization: Boolean(randomized),
      },
      api
    );

    const nextSuggestedDate = await getNextSuggestedVisit(
      event.eventVisit.visit.clinicalStudyId ?? 0,
      event.eventVisit.visitId,
      event.studyPatientId,
      event.agendaId,
      api
    );
    refetch(event.agendaId);
    setLoading(false);

    if (!nextSuggestedDate.nextVisit) {
      toast.error('Nenhuma visita disponivel');
      handleClose();
      return;
    }
    setNextSuggestedPatientVisit(nextSuggestedDate);

    onOpenSuggestion();
  };

  return (
    <>
      <SuggestVisitModal
        agendaId={event?.agendaId ?? 0}
        studyPatient={event?.studyPatient}
        isOpen={isOpenSuggestion}
        onClose={handleClose}
        refetch={refetch}
        nextVisitSuggestion={nextSuggestedPatientVisit}
      />
      <MainModal
        title="Evento"
        isOpen={isOpen}
        onClose={handleClose}
        loading={false}
        size="xl"
        hideConfirmButton
        onConfirm={() => {}}
      >
        <FormSection title="Informações do Paciente">
          <LabeledInput
            id=""
            onChange={() => {}}
            label="Nome"
            value={event?.studyPatient.patient.people.name ?? ''}
            disabled
          />
          <LabeledInput
            id=""
            onChange={() => {}}
            label="Documento"
            value={event?.studyPatient.patient.people.document ?? ''}
            disabled
          />
          <LabeledInput
            id=""
            onChange={() => {}}
            label="Dt. Nascimento"
            type="date"
            value={
              format(
                new Date(event?.studyPatient.patient.people.birthDate ?? 0),
                'yyyy-MM-dd'
              ) ?? ''
            }
            disabled
          />
          {event?.eventVisit.visit.randomized ? (
            <SelectInput
              id=""
              onChange={(e) => setRandomized(e.target.value)}
              label="Randomização"
              value={randomized}
              options={[
                { value: '1', label: 'Apto' },
                { value: '0', label: 'Inapto' },
              ]}
            />
          ) : null}
        </FormSection>
        <FormSection title="Visita">
          <LabeledInput
            id=""
            onChange={() => {}}
            label="Nome Visita"
            value={event?.eventVisit.visit?.name ?? ''}
            disabled
          />
          <LabeledInput
            id=""
            onChange={() => {}}
            label="Notas Internas"
            value={event?.eventVisit.visit?.internalNotes ?? ''}
            disabled
          />
          <LabeledInput
            id=""
            onChange={() => {}}
            label="Lembrete"
            value={event?.eventVisit.visit?.internalReminder ?? ''}
            disabled
          />
          <Box className="mt-5" />
          <FormSection title="Anexos">
            <div className="flex flex-col gap-4">
              {attachments.map((attachment) => (
                <AttachmentCard
                  readOnly
                  refresh={() => getAttachments(event)}
                  key={attachment.id}
                  file={attachment}
                />
              ))}
            </div>
          </FormSection>
          <Box className="mt-5" />
          <FormSection title="Procedimentos da Visita">
            <Box className="flex w-full flex-col gap-2">
              {event?.eventVisit.visit.procedures.map((procedure) => (
                <ResearcherVisitProcedureCard
                  key={procedure.id}
                  procedure={procedure}
                  onSuccess={handleSuccessProcedure}
                  onUnsuccess={handleUnsuccessProcedure}
                  onReset={handleResetProcedure}
                  unSelected={!markedProcedures.includes(procedure.id)}
                  unsuccess={unsuccess.includes(procedure.id)}
                />
              ))}
            </Box>
          </FormSection>
        </FormSection>
        <div className="w-full flex justify-between">
          <ConfirmationPopover
            confirmationText="Tem certeza de que deseja marcar esta visita como improdutiva?"
            triggerText="Improdutiva"
            confirmationTitle="Improdutiva"
            triggerColorScheme="red"
          >
            <Button
              isLoading={loading}
              onClick={handleSetUnproductive}
              colorScheme="red"
            >
              Confirmar
            </Button>
          </ConfirmationPopover>
          <ConfirmationPopover
            confirmationText="Tem certeza de que deseja finalizar esta visita?"
            triggerText="Finalizar"
          >
            <Button isLoading={loading} onClick={handleFinishEvent}>
              Confirmar
            </Button>
          </ConfirmationPopover>
        </div>
      </MainModal>
    </>
  );
};
