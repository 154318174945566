import { Link } from 'react-router-dom';

interface MenuItemProps {
  to: string;
  title: string;
  onClick?: () => void;
}

export const MenuItem = ({ to, title, onClick }: MenuItemProps) => {
  return (
    <li className="w-full flex flex-grow">
      <Link
        className="hover:text-blue-100 hover:font-bold hover:bg-blue-900 rounded-md p-2 w-full pl-5 font-semibold text-md"
        onClick={onClick}
        to={to}
      >
        {title}
      </Link>
    </li>
  );
};
