import React, { useMemo } from 'react';
import { Tr, Td } from '@chakra-ui/react';
import { StudyPatientViewModel } from '../../types/models';
import { isAfter, subDays } from 'date-fns';

const filterRecentPatients = (patients: StudyPatientViewModel[]) =>
  patients.filter(
    (patient) =>
      patient.createdAt &&
      isAfter(new Date(patient.createdAt), subDays(new Date(), 30))
  );

const calculatePercentageOrLength = (num1: number, num2?: number) =>
  num2 ? `${((num1 / num2) * 100).toFixed(1)}%` : num1;

const DashboardRow = ({
  firstCell,
  array1,
  array2,
  array3,
  lastCell,
}: {
  firstCell: string;
  array1: StudyPatientViewModel[];
  array2?: StudyPatientViewModel[];
  array3?: StudyPatientViewModel[];
  lastCell?: number;
}) => {
  const firstResult = useMemo(() => {
    if (array3 && array2) {
      return (
        filterRecentPatients(array1).length -
        filterRecentPatients(array2).length -
        filterRecentPatients(array3).length
      );
    }

    if (array2) {
      return calculatePercentageOrLength(
        filterRecentPatients(array1).length,
        filterRecentPatients(array2).length
      );
    }

    return filterRecentPatients(array1).length;
  }, [array1, array2, array3]);

  const secondResult = useMemo(() => {
    if (array3 && array2) {
      return array1.length - array2.length - array3.length;
    }

    if (array2) {
      return calculatePercentageOrLength(array1.length, array2.length);
    }

    return array1.length;
  }, [array1, array2, array3]);

  return (
    <Tr>
      <Td>{firstCell}</Td>
      <Td isNumeric>{firstResult}</Td>
      <Td isNumeric>{secondResult}</Td>
      <Td isNumeric>{lastCell ?? ''}</Td>
    </Tr>
  );
};

export default DashboardRow;
