import {
  Box,
  IconButton,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import { PanelWrapper } from '../../components/shared/PanelWrapper/PannelWrapper';
import { FilterContainer } from '../../components/shared/Filter/FilterContainer';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { useQuery } from '../../utils/hooks/useQuery';
import { VisitViewModel } from '../../types/models';
import { useState } from 'react';
import { VisitModal } from '../../components/Visits/VisitModal';
import { DeleteConfirmationPopover } from '../../components/shared/Popover/DeleteConfirmationPopover';
import { toast } from 'react-toastify';
import { useApiCall } from '../../utils/hooks/useApiCall';
import { deleteVisit } from '../../services/visitsServices';

const columns = [
  { label: 'Nome', key: 'acronym' },
  { label: 'Acromimo Estudo', key: 'studyId' },
  { label: 'Nome Estudo', key: 'name' },
  { label: 'Quantidade Procedimentos', key: 'industry.name' },
  { label: 'Ações', key: 'actions' },
];

export const VisitsPage = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [visitData, setVisit] = useState<VisitViewModel | undefined>();
  const [visitLoading, setVisitLoading] = useState(false);
  const { data: visits, refetch } = useQuery<VisitViewModel[]>('/visits');
  const api = useApiCall();

  const handleClose = () => {
    setVisit(undefined);
    onClose();
  };

  const handleOpenEditModal = (usr: VisitViewModel) => {
    setVisit(usr);
    onOpen();
  };

  const handleDelete = async (visitId: number) => {
    try {
      setVisitLoading(true);
      await deleteVisit(visitId, api);
      refetch();
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      setVisitLoading(false);
    }
  };

  return (
    <>
      <VisitModal
        visitId={visitData?.id}
        refetch={refetch}
        isOpen={isOpen}
        onClose={handleClose}
      />
      <PanelWrapper title="Visitas">
        <FilterContainer onOpenModal={onOpen} />
        <Box className="rounded-lg shadow p-4 pt-10 mt-5">
          <Table>
            <Thead>
              <Tr>
                {columns.map((column) => (
                  <Th key={column.key}>{column.label}</Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {visits?.map((row) => (
                <Tr
                  key={row.id}
                  className="hover:bg-gray-50 hover:cursor-pointer"
                >
                  <Td>{row.name}</Td>
                  <Td>{row.clinicalStudy?.acronym}</Td>
                  <Td>{row.clinicalStudy?.name}</Td>
                  <Td>{row.procedures.length}</Td>

                  <Td>
                    <div className="w-full flex justify-around">
                      <DeleteConfirmationPopover
                        loading={visitLoading}
                        onConfirm={() => handleDelete(row.id)}
                      >
                        <IconButton
                          colorScheme="red"
                          aria-label="Excluir"
                          icon={<DeleteIcon color="white" />}
                        />
                      </DeleteConfirmationPopover>
                      <IconButton
                        onClick={() => handleOpenEditModal(row)}
                        colorScheme="blue"
                        aria-label="Editar"
                        icon={<EditIcon color="white" />}
                      />
                    </div>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      </PanelWrapper>
    </>
  );
};
