import { toast } from 'react-toastify';
import { AgendaViewModel, EventViewModel } from '../types/models';
import { ApiCallContextProps } from '../utils/hooks/useApiCall';

export const createAgenda = async (
  payload: Partial<AgendaViewModel>,
  api: ApiCallContextProps
) => {
  try {
    await api.post?.('/agendas', payload);
    toast.success('Agenda Criada com sucesso');
  } catch (error) {
    throw new Error('Ocorreu um erro ao criar Agenda');
  }
};

export const modifyAgenda = async (
  id: number,
  payload: Partial<AgendaViewModel>,
  api: ApiCallContextProps
) => {
  try {
    await api.put?.(`/agendas/${id}`, payload);
    toast.success('Agenda Editada com sucesso');
  } catch (error) {
    throw new Error('Ocorreu um erro ao editar Agenda');
  }
};

export const deleteAgenda = async (id: number, api: ApiCallContextProps) => {
  try {
    await api.del?.(`/agendas/${id}`);
    toast.success('Agenda excluído com sucesso');
  } catch (error) {
    throw new Error('Ocorreu um erro ao excluir Agenda');
  }
};

export const getManyAgenda = async (api: ApiCallContextProps) => {
  try {
    await api.get?.(`/agendas`);
  } catch (error) {
    throw new Error('Ocorreu um erro ao recuperar informações');
  }
};

export const getOneAgenda = async (id: number, api: ApiCallContextProps) => {
  try {
    await api.get?.(`/agendas/${id}`);
  } catch (error) {
    throw new Error('Ocorreu um erro ao recuperar informações');
  }
};

export const getOneAgendaByResearcherId = async (
  id: number,
  api: ApiCallContextProps
) => {
  try {
    return await api.get<AgendaViewModel>?.(`/agendas/researcher/${id}`);
  } catch (error) {
    throw new Error('Ocorreu um erro ao recuperar informações');
  }
};

export const getAgendaEvents = async (
  id: number,
  api: ApiCallContextProps,
  date?: string
) => {
  try {
    return await api.get<{
      dayEvents: EventViewModel[];
      events: EventViewModel[];
      pendingEvents: EventViewModel[];
    }>?.(`/agendas/events/${id}${date ? `?date=${date}` : ''}`);
  } catch (error) {
    throw new Error('Ocorreu um erro ao recuperar informações');
  }
};
