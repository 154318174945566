import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { publicApi } from '../configs/axiosConfig';
import { toast } from 'react-toastify';
import { UserViewModel } from '../../types/models';

interface AuthContextProps {
  token?: string;
  user?: UserViewModel;
  signIn?: (payload: { username: string; password: string }) => Promise<void>;
  loading?: boolean;
  isLoggedIn?: boolean;
  initialized?: boolean;
  logout?: () => void;
}

const AuthContext = createContext<AuthContextProps>({});

interface AuthContextProviderProps {
  children: ReactNode;
}

export const AuthProvider = ({ children }: AuthContextProviderProps) => {
  const [token, setToken] = useState('');
  const [user, setUser] = useState<UserViewModel | undefined>();
  const [loading, setLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [initialized, setInitialized] = useState(false);

  const initSession = () => {
    const authState = JSON.parse(localStorage.getItem('authState') ?? '{}');
    if (!authState?.user || !authState?.token) return setInitialized(true);

    setToken(authState.token);
    setUser(authState.user);
    setIsLoggedIn(true);
    setInitialized(true);
  };

  useEffect(() => {
    initSession();
  }, []);

  const signIn = async ({
    username,
    password,
  }: {
    username: string;
    password: string;
  }) => {
    try {
      setLoading(true);
      const { token, user } = await publicApi().post<
        { username: string; password: string },
        { token: string; user: UserViewModel }
      >('/users/auth', { username, password });

      setToken(token);
      setUser(user);
      setIsLoggedIn(true);
      localStorage.setItem('authState', JSON.stringify({ user, token }));
    } catch (error) {
      toast.error('Usuário e/ou Senha incorreto(s)');
    } finally {
      setLoading(false);
    }
  };

  const logout = () => {
    setUser(undefined);
    setToken('');
    setInitialized(true);
    setIsLoggedIn(false);
    localStorage.clear();
  };

  return (
    <AuthContext.Provider
      value={{ token, user, signIn, loading, isLoggedIn, initialized, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
