import { EquipmentViewModel, ProcedureViewModel } from '../../types/models';
import { useForm } from '../../utils/hooks/useForm';
import { MainModal } from '../shared/Modal/MainModal';
import { FormSection } from '../shared/Form/FormSection';
import { LabeledInput } from '../shared/Form/LabeledInput';
import { SelectInput } from '../shared/Form/SelectInput';
import { useEffect, useState } from 'react';
import { useApiCall } from '../../utils/hooks/useApiCall';
import { toast } from 'react-toastify';
import { useQuery } from '../../utils/hooks/useQuery';
import {
  createProcedure,
  modifyProcedure,
} from '../../services/proceduresServices';
import { AggregatorInput } from '../shared/Form/AggregatorInput';

interface ProcedureModalProps {
  isOpen: boolean;
  onClose: () => void;
  refetch: () => void;
  procedure?: ProcedureViewModel;
}

const defaultForm: Partial<ProcedureViewModel> = {
  id: undefined,
};

export const ProcedureModal = ({
  isOpen,
  onClose,
  refetch,
  procedure,
}: ProcedureModalProps) => {
  const [loading, setLoading] = useState(false);
  const { formData, onFormChange, onSelectFormChange, setFormData } =
    useForm(defaultForm);
  const api = useApiCall();
  const [equipmentsToRemove, setEquipmentsToRemove] = useState<number[]>([]);

  const { data: equipmentData } = useQuery<EquipmentViewModel[]>('/equipments');

  useEffect(() => {
    if (procedure) {
      setFormData({
        ...procedure,
      });
    } else {
      setFormData(defaultForm);
    }
  }, [procedure, setFormData]);

  const handleClose = () => {
    setEquipmentsToRemove([]);
    setFormData(defaultForm);
    onClose();
  };

  const handleSubmit = async () => {
    // const errors = [];

    // //@ts-ignore
    // if (!formData.peopleId || formData.peopleId === 'SELECIONE')
    //   errors.push('É necessário selecionar uma pessoa');
    // if (!formData.tcle) errors.push('O campo TCLE é obrigatório');
    // //@ts-ignore
    // if (!formData.tcleDate) errors.push('O campo DATA TCLE é obrigatório');

    // formData.peopleId = Number(formData.peopleId);

    // if (errors.length) return errors.forEach((error) => toast.error(error));
    // setLoading(true);

    // if (formData.tcleDate) {
    //   formData.tcleDate = new Date(formData.tcleDate).toISOString();
    // }
    try {
      if (procedure) {
        const id = formData.id ?? 0;
        delete formData.id;
        delete formData.createdAt;
        formData.updatedAt = new Date().toISOString();
        //@ts-ignore
        formData.equipmentsToRemove = equipmentsToRemove;
        await modifyProcedure(id, formData, api);
      } else {
        await createProcedure(formData, api);
      }

      setLoading(false);
      refetch();
      handleClose();
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleAddSelectAggregator =
    (type: 'equipments') => (id: number, name: string) => {
      setFormData({
        ...formData,
        [type]: [
          //@ts-ignore
          ...(formData[type] ?? []),
          //@ts-ignore
          {
            id,
            //@ts-ignore
            name,
          },
        ],
      });

      setEquipmentsToRemove(
        equipmentsToRemove.filter(
          //@ts-ignore
          (item) => Number(item) !== Number(id)
        )
      );
    };

  const handleRemoveSelectAggregator =
    (type: 'equipments') => (localId?: string, id?: number) => {
      const tempAddInfo = [...(formData[type] ?? [])];
      const filteredAddInfo = tempAddInfo.filter(
        (researcher) => String(researcher.id) !== String(id)
      );

      if (id) {
        setEquipmentsToRemove([...(equipmentsToRemove ?? []), id]);
      }
      setFormData({ ...formData, [type]: filteredAddInfo });
    };

  const formatSelectData = (items: any[], people = false) =>
    items?.map((item) => ({
      value: String(item.id),
      label: people ? item.name : item.people.name,
    }));

  return (
    <MainModal
      title="Criar Procedimento"
      isOpen={isOpen}
      onClose={handleClose}
      onConfirm={handleSubmit}
      loading={loading}
    >
      <div>
        <FormSection title="Informações do Procedimento">
          <SelectInput
            id="type"
            label="Tipo de Coleta"
            onChange={onSelectFormChange}
            options={[
              { value: 'MATERIAL', label: 'Coleta de Material Biológico' },
              { value: 'DATA', label: 'Coleta de Dados' },
            ]}
            value={formData.type ?? ''}
          />
          <LabeledInput
            value={formData.name ?? ''}
            id="name"
            label="Nome do Procedimento"
            onChange={onFormChange}
          />
          <LabeledInput
            value={formData.identifier ?? ''}
            id="identifier"
            type="text"
            label="Sigla Identificadora"
            onChange={onFormChange}
          />
          <AggregatorInput
            label="Equipamentos"
            options={formatSelectData(
              equipmentData?.filter(
                (cs) =>
                  !formData.equipments
                    ?.map((equipment) => String(equipment.id))
                    .includes(String(cs.id))
              ) ?? [],
              true
            )}
            select
            items={formData.equipments ?? []}
            onAddItem={handleAddSelectAggregator('equipments')}
            onRemoveItem={handleRemoveSelectAggregator('equipments')}
          />
        </FormSection>
      </div>
    </MainModal>
  );
};
