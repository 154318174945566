import { toast } from 'react-toastify';
import { PatientViewModel } from '../types/models';
import { ApiCallContextProps } from '../utils/hooks/useApiCall';

export const createPatient = async (
  payload: Partial<PatientViewModel>,
  api: ApiCallContextProps
) => {
  try {
    await api.post?.('/patients', payload);
    toast.success('Paciente Criado com sucesso');
  } catch (error) {
    throw new Error('Ocorreu um erro ao criar Paciente');
  }
};

export const modifyPatient = async (
  id: number,
  payload: Partial<PatientViewModel>,
  api: ApiCallContextProps
) => {
  try {
    await api.put?.(`/patients/${id}`, payload);
    toast.success('Paciente Editada com sucesso');
  } catch (error) {
    throw new Error('Ocorreu um erro ao editar Paciente');
  }
};

export const deletePatient = async (id: number, api: ApiCallContextProps) => {
  try {
    await api.del?.(`/patients/${id}`);
    toast.success('Paciente excluído com sucesso');
  } catch (error) {
    throw new Error('Ocorreu um erro ao excluir Paciente');
  }
};

export const getManyPatient = async (api: ApiCallContextProps) => {
  try {
    await api.get?.(`/patients`);
  } catch (error) {
    throw new Error('Ocorreu um erro ao recuperar informações');
  }
};

export const getOnePatient = async (id: number, api: ApiCallContextProps) => {
  try {
    await api.get?.(`/patients/${id}`);
  } catch (error) {
    throw new Error('Ocorreu um erro ao recuperar informações');
  }
};
