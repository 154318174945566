import { Box, Button, Text } from '@chakra-ui/react';
import { FilterColumn } from './FilterColumn';
import { Dispatch, SetStateAction } from 'react';
import { useForm } from '../../../utils/hooks/useForm';
import {
  AutoCompleteSelectInput,
  DataBaseModelViewCommonProp,
} from '../Form/AutoCompleteInput';
import {
  getManyResearchCenterStudy,
  getOneResearchCenterStudy,
} from '../../../services/researchCenterStudiesServices';
import { useApiCall } from '../../../utils/hooks/useApiCall';
import { toast } from 'react-toastify';
import { ResearchCenterStudyViewModel } from '../../../types/models';

interface FilterContainerProps<T> {
  onOpenModal: () => void;
  onSearch?: Dispatch<SetStateAction<ResearchCenterStudyViewModel | undefined>>;
}

const defaultForm = {
  researchCenterStudyId: null,
};

export function TemplateAttachmentFilter<
  T extends DataBaseModelViewCommonProp,
>({ onOpenModal, onSearch }: FilterContainerProps<T>) {
  const { onFormChange, formData } = useForm(defaultForm);
  const api = useApiCall();
  const handleSubmit = () => {
    const parsedQuery = Object.entries(formData)
      .filter(([_, value]) => value)
      .map(([key, value]) => `&${key}=${value}`)
      .join('');

    handleSearch?.();
  };

  const handleSearch = async () => {
    if (formData.researchCenterStudyId) {
      const data = await getOneResearchCenterStudy(
        formData.researchCenterStudyId,
        api
      );

      onSearch?.(data);
    } else {
      toast.error('É necessário selecionar um estudo');
    }
  };
  return (
    <Box className="rounded-lg shadow p-4 pt-5">
      <Text className="mb-5 text-lg font-bold">Filtro</Text>
      <Box className=" w-full flex gap-4">
        <FilterColumn>
          <AutoCompleteSelectInput
            onFormChange={onFormChange}
            filterKey="researchCenterStudyId"
            filterApiGetter={getManyResearchCenterStudy}
          />
        </FilterColumn>
        {/* <FilterColumn>
          <Input id="address" placeholder="Endereço..." />
        </FilterColumn> */}
      </Box>
      <Box className="w-full flex justify-end mt-4">
        <Box className="w-1/2 flex justify-end gap-6">
          <Button onClick={onOpenModal} colorScheme="blue">
            Adicionar
          </Button>
          <Button onClick={handleSubmit} colorScheme="blue" variant="outline">
            Pesquisar
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
