import { Heading, Text } from '@chakra-ui/react';
import { ReactNode } from 'react';

interface PanelWrapperProps {
  title: string;
  subtitle?: string;
  children: ReactNode;
}

export const PanelWrapper = ({
  title,
  children,
  subtitle,
}: PanelWrapperProps) => {
  return (
    <div className="p-10">
      <Heading>{title}</Heading>
      {subtitle ? <Text>{subtitle}</Text> : null}
      <div className="w-full mt-10">{children}</div>
    </div>
  );
};
