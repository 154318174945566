import {
  Box,
  IconButton,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import { PanelWrapper } from '../../components/shared/PanelWrapper/PannelWrapper';
import { FilterContainer } from '../../components/shared/Filter/FilterContainer';
import { format } from 'date-fns';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { useQuery } from '../../utils/hooks/useQuery';
import { PeopleViewModel } from '../../types/models';
import { PeopleModal } from '../../components/People/PeopleModal';
import { useState } from 'react';
import { DeleteConfirmationPopover } from '../../components/shared/Popover/DeleteConfirmationPopover';
import { deletePeople } from '../../services/peopleServices';
import { toast } from 'react-toastify';
import { useApiCall } from '../../utils/hooks/useApiCall';

const columns = [
  { label: 'Nome', key: 'name' },
  { label: 'CPF/CNPJ', key: 'document' },
  { label: 'Data Inclusão', key: 'createdAt' },
  { label: 'Ações', key: 'actions' },
];

export const PeoplePage = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const api = useApiCall();
  const [loading, setLoading] = useState(false);
  const [peopleData, setPeople] = useState<PeopleViewModel | undefined>();
  const {
    data: people,
    // loading: peopleLoading,
    refetch,
    setSearch,
  } = useQuery<PeopleViewModel[]>('/people');

  const handleClose = () => {
    setPeople(undefined);
    onClose();
  };

  const handleOpenEditModal = (ppl: PeopleViewModel) => {
    setPeople(ppl);
    onOpen();
  };

  const handleDelete = async (id: number) => {
    try {
      setLoading(true);
      await deletePeople(id, api);
      refetch();
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <PeopleModal
        people={peopleData}
        refetch={refetch}
        isOpen={isOpen}
        onClose={handleClose}
      />

      <PanelWrapper title="Pessoas">
        <FilterContainer onSearch={setSearch} onOpenModal={onOpen} />
        <Box className="rounded-lg shadow p-4 pt-10 mt-5">
          <Table>
            <Thead>
              <Tr>
                {columns.map((column) => (
                  <Th key={column.key}>{column.label}</Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {people?.map((ppl) => (
                <Tr
                  key={ppl.id}
                  className="hover:bg-gray-50 hover:cursor-pointer"
                >
                  <Td>{ppl.name}</Td>
                  <Td>{ppl.document}</Td>
                  <Td>{format(new Date(ppl.createdAt), 'dd/MM/yyyy')}</Td>
                  <Td>
                    <div className="w-full flex justify-around">
                      <DeleteConfirmationPopover
                        loading={loading}
                        onConfirm={() => handleDelete(ppl.id)}
                      >
                        <IconButton
                          colorScheme="red"
                          aria-label="teste"
                          icon={<DeleteIcon color="white" />}
                        />
                      </DeleteConfirmationPopover>
                      <IconButton
                        onClick={() => handleOpenEditModal(ppl)}
                        colorScheme="blue"
                        aria-label="teste"
                        icon={<EditIcon color="white" />}
                      />
                    </div>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      </PanelWrapper>
    </>
  );
};
