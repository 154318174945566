import {
  Box,
  IconButton,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import { PanelWrapper } from '../../components/shared/PanelWrapper/PannelWrapper';
import { FilterContainer } from '../../components/shared/Filter/FilterContainer';
import { format } from 'date-fns';
import { DeleteIcon, EditIcon, LockIcon } from '@chakra-ui/icons';
import { useQuery } from '../../utils/hooks/useQuery';
import { UserViewModel } from '../../types/models';
import { useState } from 'react';
import { UserModal } from '../../components/Users/UserModal';
import { DeleteConfirmationPopover } from '../../components/shared/Popover/DeleteConfirmationPopover';
import { toast } from 'react-toastify';
import { useApiCall } from '../../utils/hooks/useApiCall';
import { deleteUser } from '../../services/usersServices';
import { PermissionsModal } from '../../components/Users/PermissionsModal';

const columns = [
  { label: 'ID', key: 'id' },
  { label: 'Nome de usuário', key: 'name' },
  { label: 'Nome Pessoa', key: 'people.name' },
  { label: 'Permissões', key: 'document' },
  { label: 'Data Inclusão', key: 'createdAt' },
  { label: 'Ações', key: 'actions' },
];

export const UsersPage = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenPermissions,
    onOpen: onOpenPermissions,
    onClose: onClosePermissions,
  } = useDisclosure();
  const [userData, setUser] = useState<UserViewModel | undefined>();
  const [userLoading, setUserLoading] = useState(false);
  const { data: users, refetch } = useQuery<UserViewModel[]>('/users');
  const api = useApiCall();

  const handleClose = () => {
    setUser(undefined);
    onClosePermissions();
    onClose();
  };

  const handleOpenEditModal = (usr: UserViewModel) => {
    setUser(usr);
    onOpen();
  };

  const handleOpenPermissionsModal = (usr: UserViewModel) => {
    setUser(usr);
    onOpenPermissions();
  };

  const handleDelete = async (userId: number) => {
    try {
      setUserLoading(true);
      await deleteUser(userId, api);
      refetch();
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      setUserLoading(false);
    }
  };

  return (
    <>
      <UserModal
        user={userData}
        refetch={refetch}
        isOpen={isOpen}
        onClose={handleClose}
      />
      <PermissionsModal
        isOpen={isOpenPermissions}
        onClose={handleClose}
        refetch={refetch}
        user={userData}
      />
      <PanelWrapper title="Usuários">
        <FilterContainer onOpenModal={onOpen} />
        <Box className="rounded-lg shadow p-4 pt-10 mt-5">
          <Table>
            <Thead>
              <Tr>
                {columns.map((column) => (
                  <Th key={column.key}>{column.label}</Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {users?.map((row) => (
                <Tr
                  key={row.id}
                  className="hover:bg-gray-50 hover:cursor-pointer"
                >
                  <Td>{row.id}</Td>
                  <Td>{row.username}</Td>
                  <Td>{row.people?.name}</Td>
                  <Td>
                    {row.permission?.map((per) => per.identifier).join(', ')}
                  </Td>
                  <Td>{format(new Date(row.createdAt), 'dd/MM/yyyy')}</Td>
                  <Td>
                    <div className="w-full flex justify-around">
                      <DeleteConfirmationPopover
                        loading={userLoading}
                        onConfirm={() => handleDelete(row.id)}
                      >
                        <IconButton
                          colorScheme="red"
                          aria-label="Excluir"
                          icon={<DeleteIcon color="white" />}
                        />
                      </DeleteConfirmationPopover>{' '}
                      <IconButton
                        onClick={() => handleOpenPermissionsModal(row)}
                        colorScheme="blue"
                        aria-label="Permissões"
                        icon={<LockIcon color="white" />}
                      />
                      <IconButton
                        onClick={() => handleOpenEditModal(row)}
                        colorScheme="blue"
                        aria-label="Editar"
                        icon={<EditIcon color="white" />}
                      />
                    </div>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      </PanelWrapper>
    </>
  );
};
