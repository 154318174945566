import {
  Box,
  Card,
  CardBody,
  CardHeader,
  useDisclosure,
} from '@chakra-ui/react';
import { PanelWrapper } from '../../components/shared/PanelWrapper/PannelWrapper';
import { FormSection } from '../../components/shared/Form/FormSection';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { formatDate } from 'date-fns';
import FullCalendar from '@fullcalendar/react';
import { useAuth } from '../../utils/hooks/useAuth';
import { useCallback, useEffect, useState } from 'react';
import { EventViewModel } from '../../types/models';
import { getAgendaEvents } from '../../services/agendasServices';
import { useApiCall } from '../../utils/hooks/useApiCall';
import { toast } from 'react-toastify';
import { formatLocaleDate, isInDayRange } from '../../utils/helpers/helpers';
import { ResearcherEventModal } from '../../components/Researchers/ResearcherEventModal';
import DownloadDayCell from '../../components/Calendar/DownloadDay';

export const DashboardPage = () => {
  const [events, setEvents] = useState<EventViewModel[]>([]);
  const [dayEvents, setDayEvents] = useState<EventViewModel[]>([]);
  const [pendingEvents, setPendingEvents] = useState<EventViewModel[]>([]);
  const [event, setEvent] = useState<EventViewModel | undefined>();
  const { user } = useAuth();
  const { onOpen, onClose, isOpen } = useDisclosure();
  const api = useApiCall();
  const getResearcherAgendaEvents = useCallback(
    async (agendaId: number) => {
      try {
        if (agendaId) {
          const data = await getAgendaEvents(
            agendaId,
            api,
            formatDate(new Date(), 'yyyy-MM-dd')
          );

          if (data.events) {
            setEvents(data.events.filter((ev) => !ev?.finished));
            setDayEvents(data.dayEvents.filter((ev) => !ev?.finished));
            setPendingEvents(data.pendingEvents);
          }
        }
      } catch (error) {
        console.error(error);
        toast.error('Ocorreu um erro ao recuperar as informações do servidor');
      }
    },
    [api]
  );

  const handleOpenEventModal = (event: EventViewModel) => {
    setEvent(event);
    onOpen();
  };

  const handleCloseEventModal = () => {
    setEvent(undefined);
    onClose();
  };

  useEffect(() => {
    const agendaId = user?.people?.researcher?.[0]?.agenda?.[0]?.id;
    if (agendaId) {
      getResearcherAgendaEvents(agendaId);
    }
  }, [getResearcherAgendaEvents, user, user?.people?.researcher]);

  return (
    <>
      <ResearcherEventModal
        isOpen={isOpen}
        onClose={handleCloseEventModal}
        eventId={event?.id}
        refetch={getResearcherAgendaEvents}
      />

      <PanelWrapper title="Dashboard">
        <Box className="w-full flex gap-4">
          <Box className="w-1/2">
            <FormSection title="Compromissos do dia">
              <Box className="w-full flex flex-col gap-3">
                {dayEvents.length ? (
                  <>
                    {dayEvents.map((event) => (
                      <Card
                        className="cursor-pointer"
                        key={event.title}
                        onClick={() => handleOpenEventModal(event)}
                      >
                        <CardHeader>
                          <b>{event.title}</b>
                        </CardHeader>
                        <CardBody>
                          <div>
                            <div>
                              <b>Paciente: </b>
                              {event.studyPatient.patient.people.name}
                            </div>
                            <div>
                              {formatLocaleDate(event.start)} -{' '}
                              {formatLocaleDate(event.end)}
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    ))}
                  </>
                ) : (
                  <>Não Existem Compromissos para hoje</>
                )}
              </Box>
            </FormSection>

            <FormSection title="Pendencias">
              <Box className="w-full flex flex-col gap-3">
                {pendingEvents.length ? (
                  <>
                    {pendingEvents.map((event) => {
                      const pendingProcedures = JSON.parse(
                        event.eventVisit.unsuccessProcedures ?? '[]'
                      );
                      return (
                        <Card
                          className="cursor-pointer"
                          key={event.title}
                          onClick={() => handleOpenEventModal(event)}
                        >
                          <CardHeader>
                            <b>{event.title}</b>
                          </CardHeader>
                          <CardBody>
                            <Box className="flex w-full flex-col gap-2 mb-3">
                              <b>Procedimentos pendentes:</b>{' '}
                              {event.eventVisit.visit.procedures
                                .filter((proc) =>
                                  pendingProcedures.includes(proc.id)
                                )
                                .map((proc) => proc.name)
                                .join(', ')}
                            </Box>
                            <div>
                              <div>
                                <b>Paciente: </b>
                                {event.studyPatient.patient.people.name}
                              </div>
                              <div>
                                {formatLocaleDate(event.start)} -{' '}
                                {formatLocaleDate(event.end)}
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      );
                    })}
                  </>
                ) : (
                  <>Não Existem Pendencias</>
                )}
              </Box>
            </FormSection>
          </Box>
          <Box className="w-1/2">
            <FormSection title="Calendário">
              <FullCalendar
                locale="pt-Br"
                initialView="timeGridDay"
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                events={events as any}
                dayCellContent={(props) => (
                  <DownloadDayCell
                    events={events?.filter((event) => {
                      return isInDayRange(
                        new Date(event.start),
                        new Date(event.end),
                        props.date
                      );
                    })}
                  />
                )}
                // dateClick={handleSelectDate}
              />
            </FormSection>
          </Box>
        </Box>
      </PanelWrapper>
    </>
  );
};
