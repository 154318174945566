import { add, format } from 'date-fns';
import { VisitSuggestion } from '../../services/visitsServices';
import { MainModal } from '../shared/Modal/MainModal';
import { FormSection } from '../shared/Form/FormSection';
import { PatientViewModel, StudyPatientViewModel } from '../../types/models';
import { toast } from 'react-toastify';
import { createEvent } from '../../services/eventsServices';
import { useApiCall } from '../../utils/hooks/useApiCall';
import { setPendingSchedule } from '../../services/studyPatientsServices';

export const InfoDisplay = ({
  title,
  info,
}: {
  title: string;
  info: string;
}) => {
  return (
    <FormSection boldTitle title={title}>
      <p>{info}</p>
    </FormSection>
  );
};

interface SuggestVisitModalProps {
  nextVisitSuggestion?: VisitSuggestion;
  studyPatient?: StudyPatientViewModel;
  isOpen: boolean;
  onClose: () => void;
  agendaId: number;
  refetch: (agendaId: number) => Promise<void>;
}

export const SuggestVisitModal = ({
  nextVisitSuggestion,
  isOpen,
  onClose,
  studyPatient,
  agendaId,
  refetch,
}: SuggestVisitModalProps) => {
  const api = useApiCall();
  const handleSubmit = async () => {
    try {
      const formData = {
        title: `${nextVisitSuggestion?.nextVisit.name} - ${studyPatient?.patient.people.name}`,
        visitId: nextVisitSuggestion?.nextVisit.id,
        agendaId,
        start: new Date(nextVisitSuggestion?.firstOption ?? 0).toISOString(),
        end: add(new Date(nextVisitSuggestion?.firstOption ?? 0), {
          minutes: nextVisitSuggestion?.nextVisit.duration,
        }).toString(),
        studyPatientId: Number(studyPatient?.id),
      };
      const errors = [];
      if (!formData.title) errors.push('O campo título é obritatório');
      if (!formData.visitId) errors.push('É necessário selecionar uma visita');
      if (!formData.agendaId) errors.push('É necessário selecionar uma agenda');
      if (!formData.start || !formData.end)
        errors.push('É necessário selecionar uma data de início e fim');
      if (errors.length) return errors.forEach((error) => toast.error(error));
      formData.agendaId = Number(formData.agendaId);
      formData.visitId = Number(formData.visitId);
      formData.start = new Date(formData.start ?? 0).toISOString();
      formData.end = new Date(formData.end ?? 0).toISOString();

      await createEvent(formData, api);
      await setPendingSchedule(studyPatient?.id ?? 0, false, api);
      onClose();
      refetch(agendaId);
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  const handleCancel = async () => {
    try {
      await setPendingSchedule(studyPatient?.id ?? 0, true, api);
      refetch(agendaId);
    } catch (error: any) {
      if (studyPatient?.id) toast.error(error.message);
    } finally {
      onClose();
    }
  };

  return (
    <MainModal
      isOpen={isOpen}
      loading={false}
      onClose={handleCancel}
      title="Sugestão de Visita"
      confirmText="Agendar"
      onConfirm={handleSubmit}
    >
      <div>
        Verificamos uma possibilidade de agenda para a próxima visita em{' '}
        {format(
          new Date(nextVisitSuggestion?.firstOption ?? 0),
          "dd/MM/yyyy 'às' HH:mm"
        )}
        .
      </div>

      <FormSection title="Informações da Visita">
        <InfoDisplay
          title="Nome da Próxima Visita"
          info={nextVisitSuggestion?.nextVisit?.name ?? ''}
        />
      </FormSection>

      <div>Deseja agendar esta data agora?</div>
    </MainModal>
  );
};
