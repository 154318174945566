import { useCallback, useEffect, useState } from 'react';
import { FormSection } from '../shared/Form/FormSection';
import { MainModal } from '../shared/Modal/MainModal';
import { getOneEvent } from '../../services/eventsServices';
import { useApiCall } from '../../utils/hooks/useApiCall';
import { EventViewModel } from '../../types/models';
import { LabeledInput } from '../shared/Form/LabeledInput';
import { Box, Button, useDisclosure } from '@chakra-ui/react';
import { NewEventModal } from '../StudyPatients/NewEventModal';
import { toast } from 'react-toastify';
import { ResearcherEventModal } from './ResearcherEventModal';

interface PendingEventModalProps {
  isOpen: boolean;
  onClose: () => void;
  eventId?: number;
  refetch: () => void;
}

export const PendingEventModal = ({
  isOpen,
  onClose,
  eventId,
  refetch,
}: PendingEventModalProps) => {
  const [event, setEvent] = useState<EventViewModel | undefined>(undefined);
  const {
    onOpen: onOpenEvent,
    onClose: onCloseEvent,
    isOpen: isOpenEvent,
  } = useDisclosure();
  const {
    onOpen: onOpenEdit,
    onClose: onCloseEdit,
    isOpen: isOpenEdit,
  } = useDisclosure();
  const api = useApiCall();
  const getVisitData = useCallback(async () => {
    if (eventId) {
      const data = await getOneEvent(eventId, api);
      setEvent(data);
    }
  }, [api, eventId]);

  useEffect(() => {
    getVisitData();
  }, [getVisitData]);

  const handleCloseEvent = () => {
    onClose();
    refetch();
    toast.success('Visita Reagendada com Sucesso');
  };

  return (
    <>
      <ResearcherEventModal
        isOpen={isOpenEdit}
        onClose={onCloseEdit}
        eventId={event?.id}
        refetch={
          handleCloseEvent as unknown as (agendaId: number) => Promise<void>
        }
      />
      <NewEventModal
        isOpen={isOpenEvent}
        onClose={onCloseEvent}
        refetch={handleCloseEvent}
        studyPatient={event?.studyPatient}
        eventId={event?.id}
        visitId={event?.eventVisit.visit.id}
      />
      <MainModal
        isOpen={isOpen}
        onClose={onClose}
        loading={false}
        onConfirm={() => {}}
        title="Visita Pendente"
        hideConfirmButton
      >
        <FormSection title="Informações do Evento">
          <LabeledInput
            id="pacientName"
            label="Nome Paciente"
            value={event?.studyPatient.patient.people.name ?? 0}
            onChange={() => []}
            disabled
          />
          <LabeledInput
            id="studyName"
            label="Nome Estudo"
            value={
              event?.studyPatient?.researchCenterStudy?.clinicalStudy.name ?? 0
            }
            onChange={() => []}
            disabled
          />

          <FormSection title="Procedimentos Pendentes">
            {JSON.parse(event?.eventVisit.unsuccessProcedures ?? '[]')
              .length ? (
              <ul>
                {JSON.parse(event?.eventVisit.unsuccessProcedures ?? '[]').map(
                  (unsuccessProcedure: number) => (
                    <li>
                      {
                        event?.eventVisit?.visit.procedures.find(
                          (visit) => visit.id === unsuccessProcedure
                        )?.name
                      }
                    </li>
                  )
                )}
              </ul>
            ) : (
              <>Não existem Procedimentos pendentes nesta visita</>
            )}
          </FormSection>
        </FormSection>
        <FormSection title="Ações">
          <Box className="flex w-full justify-around items-center gap-4">
            <Button onClick={onOpenEvent}>Reagendar</Button>
            <Button onClick={onOpenEdit}>Editar Visita</Button>
          </Box>
        </FormSection>
      </MainModal>
    </>
  );
};
