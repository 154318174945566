import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import { ButtonGroup } from '@chakra-ui/react';
import { useRef } from 'react';
import { EventViewModel,  } from '../../types/models';
import { useQuery } from '../../utils/hooks/useQuery';
import DayCell from '../../../src/components/Calendar/DayCell';
import DownloadDayCell from '../../components/Calendar/DownloadDay';
import { isInDayRange } from '../../utils/helpers/helpers';

export const CalendarPage = () => {
  const calendarRef = useRef(null);
  const { data: events } = useQuery<EventViewModel[]>('/events');
  
  return (
    <div className="p-20">
      <FullCalendar
        weekends={false}
        locale="pt-Br"
        businessHours={{
          start: 6,
          end: 20,
        }}
        headerToolbar={{
          start: 'dayGridMonth,timeGridDay,dayGrid,timeGridWeek',
        }}
        ref={calendarRef}
        plugins={[dayGridPlugin, timeGridPlugin]}

        dayCellContent={(props) => (
          <ButtonGroup gap='2'> 
            <DownloadDayCell events={events?.filter((event) => isInDayRange(new Date(event.start),new Date(event.end), props.date) )} />
            <DayCell day={props}   calendarRef={calendarRef} /> 
          </ButtonGroup>
        )}
        events={(events as any) ?? []}
      />
    </div>
  );
};
