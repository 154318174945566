import {
  EquipmentViewModel,
  ResearchCenterViewModel,
} from '../../types/models';
import { useForm } from '../../utils/hooks/useForm';
import { MainModal } from '../shared/Modal/MainModal';
import { FormSection } from '../shared/Form/FormSection';
import { LabeledInput } from '../shared/Form/LabeledInput';
import { useEffect, useState } from 'react';
import { useApiCall } from '../../utils/hooks/useApiCall';
import { toast } from 'react-toastify';
import {
  createEquipment,
  modifyEquipment,
} from '../../services/equipmentsServices';
import { formatDate, formatSelectData } from '../../utils/helpers/helpers';
import { SelectInput } from '../shared/Form/SelectInput';
import { useQuery } from '../../utils/hooks/useQuery';

interface EquipmentModalProps {
  isOpen: boolean;
  onClose: () => void;
  refetch: () => void;
  equipment?: EquipmentViewModel;
}

const defaultForm: Partial<EquipmentViewModel> = {
  id: undefined,
  name: undefined,
  brand: '',
  patrimonyCode: '',
  validationDate: undefined,
  validationDueDate: undefined,
};

export const EquipmentModal = ({
  isOpen,
  onClose,
  refetch,
  equipment,
}: EquipmentModalProps) => {
  const [loading, setLoading] = useState(false);
  const { formData, onFormChange, setFormData, onSelectFormChange } =
    useForm(defaultForm);
  const api = useApiCall();

  const { data: researchCenterData } =
    useQuery<ResearchCenterViewModel[]>('/research-centers');

  useEffect(() => {
    if (equipment) {
      setFormData({
        ...equipment,
        validationDate: formatDate(equipment.validationDate),
        validationDueDate: formatDate(equipment.validationDueDate),
      });
    } else {
      setFormData(defaultForm);
    }
  }, [equipment, setFormData]);

  const handleSubmit = async () => {
    const errors = [];

    //@ts-ignore

    if (!formData.name) errors.push('O campo Nome é obrigatório');
    //@ts-ignore
    if (!formData.brand) errors.push('O campo Marca é obrigatório');
    if (errors.length) return errors.forEach((error) => toast.error(error));
    setLoading(true);

    if (formData.validationDate) {
      formData.validationDate = new Date(formData.validationDate).toISOString();
    }
    if (formData.validationDueDate) {
      formData.validationDueDate = new Date(
        formData.validationDueDate
      ).toISOString();
    }
    try {
      formData.researchCenterId = Number(formData.researchCenterId);
      if (equipment) {
        const id = formData.id ?? 0;
        delete formData.id;
        delete formData.createdAt;
        formData.updatedAt = new Date().toISOString();
        await modifyEquipment(id, formData, api);
      } else {
        await createEquipment(formData, api);
      }

      setLoading(false);
      setFormData(defaultForm);
      refetch();
      onClose();
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <MainModal
      title="Criar Equipamento"
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={handleSubmit}
      loading={loading}
    >
      <div>
        <FormSection title="Informações do Centro de Pesquisa">
          <SelectInput
            value={String(formData.researchCenterId) ?? ''}
            onChange={onSelectFormChange}
            label="Centro de Pesquisa"
            id="researchCenterId"
            options={formatSelectData(researchCenterData ?? [])}
          />
        </FormSection>
        <FormSection title="Informações do Equipamento">
          <LabeledInput
            value={formData.name ?? ''}
            id="name"
            label="Nome"
            onChange={onFormChange}
          />
          <LabeledInput
            value={formData.brand ?? ''}
            id="brand"
            label="Marca"
            onChange={onFormChange}
          />
          <LabeledInput
            value={formData.patrimonyCode ?? ''}
            id="patrimonyCode"
            label="Patrimonio"
            onChange={onFormChange}
          />
          <LabeledInput
            value={formData.validationDate ?? ''}
            id="validationDate"
            type="date"
            label="Data Validação"
            onChange={onFormChange}
          />

          <LabeledInput
            value={formData.validationDueDate ?? ''}
            id="validationDueDate"
            type="date"
            label="Data Vencimento Validação"
            onChange={onFormChange}
          />
        </FormSection>
      </div>
    </MainModal>
  );
};
