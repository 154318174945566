import {
  PeopleViewModel,
  AttachmentViewModel,
  VisitViewModel,
  ResearchCenterStudyViewModel,
} from '../../types/models';
import { useForm } from '../../utils/hooks/useForm';
import { MainModal } from '../shared/Modal/MainModal';
import { FormSection } from '../shared/Form/FormSection';
import { LabeledInput } from '../shared/Form/LabeledInput';
import { SelectInput } from '../shared/Form/SelectInput';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useApiCall } from '../../utils/hooks/useApiCall';
import { toast } from 'react-toastify';

import { Button, Input } from '@chakra-ui/react';
import { AttachmentCard } from './AttachmentCard';

interface AttachmentModalProps {
  isOpen: boolean;
  onClose: () => void;
  refetch?: () => void;
  visit?: VisitViewModel;
  researchCenterStudy?: ResearchCenterStudyViewModel;
  researchCenterStudies?: ResearchCenterStudyViewModel[];
  visits?: VisitViewModel[];
}

const defaultForm: Partial<AttachmentViewModel> = {
  id: undefined,
  researchCenterStudyId: undefined,
  visitId: undefined,
};

const certerTypeOptions = [
  { value: 'MAIN', label: 'Principal' },
  { value: 'SATELLITE', label: 'Satélite' },
];

export const AttachmentModal = ({
  isOpen,
  onClose,
  refetch,
  visit,
  researchCenterStudy,
  researchCenterStudies,
  visits,
}: AttachmentModalProps) => {
  const [loading, setLoading] = useState(false);
  const { formData, onFormChange, onSelectFormChange, setFormData } =
    useForm(defaultForm);
  const [attachments, setAttachments] = useState<AttachmentViewModel[]>([]);
  const api = useApiCall();
  const [file, setFile] = useState<any>();
  const [fileName, setFileName] = useState<string>('');

  const formattedOptions = useMemo(
    () =>
      researchCenterStudies?.map((ppl) => ({
        value: String(ppl.id),
        label: ppl.name,
      })),
    [researchCenterStudies]
  );

  const formattedVisitOptions = useMemo(
    () =>
      visits?.map((ppl) => ({
        value: String(ppl.id),
        label: ppl.name,
      })),
    [visits]
  );

  const fileInputRef = useRef(null);

  const getAttachments = useCallback(async () => {
    const data = await api.get<AttachmentViewModel[]>(
      `/attachments/${visit?.id}/${researchCenterStudy?.id}`
    );
    setAttachments(data);
  }, [api, visit?.id, researchCenterStudy?.id]);

  useEffect(() => {
    if (visit) {
      setFormData({
        researchCenterStudyId: researchCenterStudy?.id,
        visitId: visit.id,
      });
      getAttachments();
    } else {
      setFormData(defaultForm);
    }
  }, [visit, setFormData, getAttachments, researchCenterStudy?.id]);

  const handleSubmit = async () => {
    const form = new FormData();
    form.append('file', file);
    form.append('researchCenterStudyId', String(researchCenterStudy?.id));
    form.append('visitId', String(visit?.id));
    form.append('provider', 'GOOGLE');
    form.append('fileName', fileName);

    try {
      const data = await api.post('/attachments', form);
      getAttachments();
      toast.success('Upload realizado com sucesso');
      setFileName('');
      setFile(null);
    } catch (error) {
      toast.error('Ocorreu um erro ao realizar o upload');
    }
  };

  //   const handleSubmit = async () => {
  //     const errors = [];

  //     console.log(formData.peopleId);

  //     //@ts-ignore
  //     if (!formData.peopleId || formData.peopleId === 'SELECIONE')
  //       errors.push('É necessário selecionar uma pessoa');
  //     if (!formData.cnes) errors.push('O campo CNES é obrigatório');
  //     //@ts-ignore
  //     if (formData.centerType === 'SELECIONE')
  //       errors.push('O campo TIPO é obrigatório');

  //     formData.peopleId = Number(formData.peopleId);
  //     formData.backupHospitalId = formData.backupHospitalId
  //       ? Number(formData.backupHospitalId)
  //       : undefined;
  //     if (errors.length) return errors.forEach((error) => toast.error(error));
  //     setLoading(true);
  //     try {
  //       if (attachment) {
  //         const id = formData.id ?? 0;
  //         delete formData.id;
  //         delete formData.createdAt;
  //         formData.updatedAt = new Date().toISOString();
  //         await modifyAttachment(id, formData, api);
  //       } else {
  //         await createAttachment(formData, api);
  //       }

  //       setLoading(false);
  //       refetch();
  //       onClose();
  //     } catch (error: any) {
  //       toast.error(error.message);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  return (
    <MainModal
      title="Anexo de Templates de Visita"
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={handleSubmit}
      hideConfirmButton
      loading={loading}
    >
      <div>
        <FormSection title="Informações do Estudo">
          <SelectInput
            value={String(formData.researchCenterStudyId) ?? ''}
            id="researchCenterStudyId"
            options={formattedOptions ?? []}
            onChange={onSelectFormChange}
            disabled
            label="Estudo"
          />
          <SelectInput
            value={String(formData.visitId) ?? ''}
            id="researchCenterStudyId"
            options={formattedVisitOptions ?? []}
            onChange={onSelectFormChange}
            disabled
            label="Estudo"
          />
        </FormSection>
        <FormSection title="Arquivo">
          <div className="flex flex-col gap-4">
            {attachments.map((attachment) => (
              <AttachmentCard
                refresh={getAttachments}
                key={attachment.id}
                file={attachment}
              />
            ))}
          </div>

          <input
            style={{ display: 'none' }}
            onChange={(e) => setFile(e.target?.files?.[0])}
            ref={fileInputRef}
            type="file"
          />
          <div className="flex items-end gap-4">
            <LabeledInput
              id="fileName"
              label="Nome Arquivo"
              value={fileName}
              type="text"
              onChange={(e) => setFileName(e.target.value)}
            />
            {file ? (
              <Button mb="8px" onClick={handleSubmit}>
                Salvar
              </Button>
            ) : (
              <Button
                isDisabled={fileName ? false : true}
                mb="8px"
                onClick={() => {
                  //@ts-ignore
                  fileInputRef.current?.click();
                }}
              >
                Adicionar Anexo
              </Button>
            )}
          </div>
        </FormSection>
      </div>
    </MainModal>
  );
};
