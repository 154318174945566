import {
  AddInfoViewModel,
  ClinicalStudyViewModel,
  ProcedureViewModel,
  VisitViewModel,
} from '../../types/models';
import { useForm } from '../../utils/hooks/useForm';
import { MainModal } from '../shared/Modal/MainModal';
import { FormSection } from '../shared/Form/FormSection';
import { LabeledInput } from '../shared/Form/LabeledInput';
import { SelectInput } from '../shared/Form/SelectInput';
import { useCallback, useEffect, useState } from 'react';
import { useApiCall } from '../../utils/hooks/useApiCall';
import { toast } from 'react-toastify';
import { useQuery } from '../../utils/hooks/useQuery';
import {
  createVisit,
  getOneVisit,
  modifyVisit,
} from '../../services/visitsServices';
import { AggregatorInput } from '../shared/Form/AggregatorInput';
import { formatSelectData } from '../../utils/helpers/helpers';
import {
  Checkbox,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';

interface VisitModalProps {
  isOpen: boolean;
  onClose: () => void;
  refetch: () => void;
  visitId?: number;
}

const defaultForm: Partial<VisitViewModel> = {
  id: undefined,
};

export const VisitModal = ({
  isOpen,
  onClose,
  refetch,
  visitId,
}: VisitModalProps) => {
  const [loading, setLoading] = useState(false);
  const { formData, onFormChange, onSelectFormChange, setFormData } =
    useForm(defaultForm);
  const [visitMaterialsToRemove, setVisitMaterialsToRemove] = useState<
    number[]
  >([]);
  const [visit, setVisit] = useState<VisitViewModel | undefined>();
  const [proceduresToRemove, setProceduresToRemove] = useState<number[]>([]);
  const { data: clinicalStudyData } =
    useQuery<ClinicalStudyViewModel[]>('/clinical-studies');
  const { data: proceduresData } =
    useQuery<ProcedureViewModel[]>('/procedures');
  const api = useApiCall();

  useEffect(() => {
    if (visit) {
      setFormData({
        ...visit,
      });
    } else {
      setFormData(defaultForm);
    }
  }, [visit, setFormData]);

  const getVisit = useCallback(async () => {
    if (visitId) {
      const data = await getOneVisit(visitId, api);
      setVisit(data);
    }
  }, [api, visitId]);

  const handleClose = () => {
    setFormData(defaultForm);
    setVisit(undefined);
    onClose();
  };

  useEffect(() => {
    if (visitId && visit?.id !== visitId) {
      getVisit();
    }
    if (visit) {
      setFormData({
        ...visit,
      });
    } else {
      setFormData(defaultForm);
    }
  }, [getVisit, setFormData, visit, visitId]);

  const handleSubmit = async () => {
    try {
      const errors = [];
      if (!formData.kitName)
        errors.push('É necessário preencher o Nome do Kit');
      if (!formData.clinicalStudyId)
        errors.push('É necessário Selecionar o estudo clínico');
      if (
        (!formData.visitWindow && formData.visitWindow !== 0) ||
        (!formData.visitGte && formData.visitGte !== 0) ||
        (!formData.visitLte && formData.visitLte !== 0)
      )
        errors.push(
          'É necessário preencher as informações sobre a janela da visita'
        );

      if (!formData.name || formData.name.length < 3)
        errors.push('Por favor, preencha um nome válido');

      if (errors.length) return errors.forEach((error) => toast.error(error));
      formData.duration = Number(formData.duration);
      if (visit) {
        const id = formData.id ?? 0;
        delete formData.id;
        delete formData.createdAt;
        formData.updatedAt = new Date().toISOString();
        //@ts-ignore
        formData.proceduresToRemove = proceduresToRemove;
        //@ts-ignore
        formData.visitMaterialsToRemove = visitMaterialsToRemove;
        await modifyVisit(id, formData, api);
      } else {
        await createVisit(formData, api);
      }

      setLoading(false);
      handleClose();
      refetch();
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleAddSelectAggregator =
    (type: 'visitMaterials' | 'procedures') => (id: number, name: any) => {
      if (type === 'visitMaterials') {
        setFormData({
          ...formData,
          [type]: [
            ...(formData[type] ?? []),
            {
              //@ts-ignore
              ...name,
            },
          ],
        });
      } else {
        setFormData({
          ...formData,
          [type]: [
            //@ts-ignore
            ...(formData[type] ?? []),
            //@ts-ignore
            {
              id,
              //@ts-ignore
              name,
            },
          ],
        });
      }

      if (type === 'visitMaterials') {
        setVisitMaterialsToRemove(
          visitMaterialsToRemove.filter((item) => Number(item) !== Number(id))
        );
      } else {
        setProceduresToRemove(
          proceduresToRemove.filter((item) => Number(item) !== Number(id))
        );
      }
    };

  const handleRemoveSelectAggregator =
    (type: 'visitMaterials' | 'procedures') =>
    (localId?: string, id?: number) => {
      const tempAddInfo = [...(formData[type] ?? [])];
      const filteredItems = tempAddInfo.filter(
        (researcher) => String(researcher.id) !== String(id)
      );

      if (id) {
        setProceduresToRemove([...(proceduresToRemove ?? []), id]);
      }

      setFormData({ ...formData, [type]: filteredItems });
    };

  const handleRemoveAggregated = (tempId?: string, id?: number) => {
    const tempAddInfo = [...(formData.visitMaterials ?? [])];
    const filteredAddInfo = tempAddInfo.filter(
      (info) => info.tempId !== tempId
    );

    if (id) {
      setVisitMaterialsToRemove([...visitMaterialsToRemove, id]);
    }

    setFormData({ ...formData, visitMaterials: filteredAddInfo });
  };

  return (
    <MainModal
      title="Criar Visita"
      isOpen={isOpen}
      onClose={handleClose}
      onConfirm={handleSubmit}
      loading={loading}
    >
      <Tabs>
        <TabList>
          <Tab>Informações</Tab>
          <Tab>Procedimentos</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <div>
              <FormSection title="Estudo Clínico de Origem">
                <SelectInput
                  value={String(formData.clinicalStudyId ?? '')}
                  id="clinicalStudyId"
                  options={formatSelectData(clinicalStudyData ?? [], true)}
                  onChange={onSelectFormChange}
                  label="Estudo Clínico"
                />
              </FormSection>

              <FormSection title="Informações da Visita">
                <LabeledInput
                  value={formData.name ?? ''}
                  id="name"
                  label="Nome"
                  onChange={onFormChange}
                />
                <Checkbox
                  id="randomized"
                  onChange={onFormChange}
                  checked={formData.randomized}
                >
                  Randomizada?
                </Checkbox>
                <LabeledInput
                  value={formData.visitWindow ?? ''}
                  id="visitWindow"
                  label="Janela Visita"
                  type="number"
                  onChange={onFormChange}
                />
                <LabeledInput
                  value={formData.visitGte ?? ''}
                  id="visitGte"
                  label="Intervalo Antes"
                  type="number"
                  onChange={onFormChange}
                />
                <LabeledInput
                  value={formData.visitLte ?? ''}
                  id="visitLte"
                  label="Intervalo Depois"
                  type="number"
                  onChange={onFormChange}
                />
                <LabeledInput
                  value={formData.duration ?? ''}
                  id="duration"
                  label="Duração Visita (em minutos)"
                  type="number"
                  onChange={onFormChange}
                />
                <LabeledInput
                  value={formData.internalReminder ?? ''}
                  id="internalReminder"
                  label="Lembrete Interno"
                  onChange={onFormChange}
                />
                <LabeledInput
                  value={formData.internalNotes ?? ''}
                  id="internalNotes"
                  label="Notas Monitor"
                  onChange={onFormChange}
                />
                <LabeledInput
                  value={formData.messageToPatient ?? ''}
                  id="messageToPatient"
                  label="Mensagem ao Paciente"
                  onChange={onFormChange}
                />
                <LabeledInput
                  value={formData.sendMessageWithin ?? ''}
                  id="sendMessageWithin"
                  label="Enviar Mensagem com Antecedencia de (horas)"
                  type="number"
                  onChange={onFormChange}
                />
              </FormSection>
            </div>
          </TabPanel>
          <TabPanel>
            <FormSection title="Procedimentos da visita">
              <AggregatorInput
                label="Procedimentos da Visita"
                items={
                  (formData.procedures as unknown as AddInfoViewModel[]) ?? []
                }
                select
                options={formatSelectData(proceduresData ?? [], true)}
                onAddItem={handleAddSelectAggregator('procedures')}
                onRemoveItem={handleRemoveSelectAggregator('procedures')}
              />

              <LabeledInput
                value={formData.kitName ?? ''}
                id="kitName"
                label="Nome do Kit"
                type="text"
                onChange={onFormChange}
              />
              <AggregatorInput
                label="Materiais a serem coletados na Visita"
                items={
                  (formData.visitMaterials as unknown as AddInfoViewModel[]) ??
                  []
                }
                compost
                options={formatSelectData([])}
                onAddItem={handleAddSelectAggregator('visitMaterials')}
                onRemoveItem={handleRemoveAggregated}
              />
            </FormSection>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </MainModal>
  );
};
