import {
  ClinicalStudyViewModel,
  PeopleViewModel,
  PatientViewModel,
  ResearchCenterViewModel,
} from '../../types/models';
import { useForm } from '../../utils/hooks/useForm';
import { MainModal } from '../shared/Modal/MainModal';
import { FormSection } from '../shared/Form/FormSection';
import { SelectInput } from '../shared/Form/SelectInput';
import { useEffect, useMemo, useState } from 'react';
import { useApiCall } from '../../utils/hooks/useApiCall';
import { toast } from 'react-toastify';
import { useQuery } from '../../utils/hooks/useQuery';
import { createPatient, modifyPatient } from '../../services/patientsServices';

interface PatientModalProps {
  isOpen: boolean;
  onClose: () => void;
  refetch: () => void;
  patient?: PatientViewModel;
}

const defaultForm: Partial<PatientViewModel> = {
  id: undefined,
  peopleId: undefined,
};

export const PatientModal = ({
  isOpen,
  onClose,
  refetch,
  patient,
}: PatientModalProps) => {
  const [loading, setLoading] = useState(false);
  const { formData, onFormChange, onSelectFormChange, setFormData } =
    useForm(defaultForm);
  const api = useApiCall();

  const { data: researchCenterData } =
    useQuery<ResearchCenterViewModel[]>('/research-centers');

  const { data: peopleData, refetch: peopleRefetch } = useQuery<
    PeopleViewModel[]
  >('/people?type=patients');

  const { data: clinicalStudyData } =
    useQuery<ClinicalStudyViewModel[]>('/clinical-studies');

  const formattedOptions = useMemo(
    () =>
      peopleData
        ?.filter(
          (ppl) =>
            !ppl.Patient.length &&
            !ppl.researcher?.length &&
            !ppl.manager.length &&
            !ppl.researchCenter.length &&
            !ppl.researchCenterBackup.length
        )
        .map((ppl) => ({
          value: String(ppl.id),
          label: ppl.name,
        })),
    [peopleData]
  );

  useEffect(() => {
    peopleRefetch();
    if (patient) {
      setFormData({
        ...patient,
      });
    } else {
      setFormData(defaultForm);
    }
  }, [patient, setFormData]);

  const handleSubmit = async () => {
    const errors = [];

    //@ts-ignore
    if (!formData.peopleId || formData.peopleId === 'SELECIONE')
      errors.push('É necessário selecionar uma pessoa');
    if (
      !formData.researchCenterId ||
      //@ts-ignore
      formData.researchCenterId === 'SELECIONE'
    )
      errors.push('É necessário selecionar um centro de pesquisa');
    // if (!formData.tcle) errors.push('O campo TCLE é obrigatório');
    // //@ts-ignore
    // if (!formData.tcleDate) errors.push('O campo DATA TCLE é obrigatório');

    formData.peopleId = Number(formData.peopleId);
    formData.researchCenterId = Number(formData.researchCenterId);

    if (errors.length) return errors.forEach((error) => toast.error(error));
    setLoading(true);

    try {
      if (patient) {
        const id = formData.id ?? 0;
        delete formData.id;
        delete formData.createdAt;
        formData.updatedAt = new Date().toISOString();
        await modifyPatient(id, formData, api);
      } else {
        await createPatient(formData, api);
      }

      setLoading(false);
      refetch();
      onClose();
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      setLoading(false);
      handleClose();
    }
  };

  const formatSelectData = (items: any[], people = false) =>
    items?.map((item) => ({
      value: String(item.id),
      label: people ? item.name : item.people.name,
    }));

  const handleClose = () => {
    setFormData(defaultForm);
    onClose();
  };

  return (
    <MainModal
      title="Criar Paciente"
      isOpen={isOpen}
      onClose={handleClose}
      onConfirm={handleSubmit}
      loading={loading}
    >
      <div>
        <FormSection title="Informações do Centro de Pesquisa">
          <SelectInput
            value={String(formData.researchCenterId) ?? ''}
            onChange={onSelectFormChange}
            label="Centro de Pesquisa"
            id="researchCenterId"
            options={formatSelectData(researchCenterData ?? [])}
          />
        </FormSection>
        <FormSection title="Informações do Paciente">
          <SelectInput
            value={String(formData.peopleId) ?? ''}
            id="peopleId"
            options={formattedOptions ?? []}
            onChange={onSelectFormChange}
            label="Pessoa"
          />
          {/* <AggregatorInput
            label="Estudos"
            options={formatSelectData(
              clinicalStudyData?.filter(
                (cs) =>
                  !formData.clinicalStudies
                    ?.map((clinicalSt) => String(clinicalSt.id))
                    .includes(String(cs.id))
              ) ?? [],
              true
            )}
            select
            items={formData.clinicalStudies ?? []}
            onAddItem={handleAddSelectAggregator('clinicalStudies')}
            onRemoveItem={handleRemoveSelectAggregator('clinicalStudies')}
          /> */}
        </FormSection>
      </div>
    </MainModal>
  );
};
