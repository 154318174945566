export const defaultForm = {
  value: '',
  label: '',
};

enum TypeOptionsEnum {
  'BLOOD' = 'Sangue',
  'SERUM' = 'Soro',
  'PLASMA' = 'Plasma',
  'URINE' = 'Urina',
}

const optionsFormatter = (object: { [key: string]: string }) =>
  Object.entries(object).map(([value, label]) => ({
    value,
    label,
  }));

export const typeOptions = optionsFormatter(TypeOptionsEnum);
enum UnitOptionsEnum {
  'FRASCO_2ML' = 'Frasco 2ml',
  'FRASCO_4ML' = 'Frasco 4ml',
  'FRASCO_5ML' = 'Frasco 5ml',
  'TUBO_5ML' = 'Tubo 5ml',
}

export const unitOptions = optionsFormatter(UnitOptionsEnum);

export const defaultMiniForm = {
  type: undefined,
  unit: undefined,
  quantity: undefined,
};

export const renderNames = (string: string) => {
  return (
    Object.entries({ ...UnitOptionsEnum, ...TypeOptionsEnum }).find(
      ([key]) => key === string
    )?.[1] ?? 'N/A'
  );
};
