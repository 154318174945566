import { toast } from 'react-toastify';
import { ManagerViewModel } from '../types/models';
import { ApiCallContextProps } from '../utils/hooks/useApiCall';

export const createManager = async (
  payload: Partial<ManagerViewModel>,
  api: ApiCallContextProps
) => {
  try {
    await api.post?.('/managers', payload);
    toast.success('Coordenador Criado com sucesso');
  } catch (error) {
    throw new Error('Ocorreu um erro ao criar Coordenador');
  }
};

export const modifyManager = async (
  id: number,
  payload: Partial<ManagerViewModel>,
  api: ApiCallContextProps
) => {
  try {
    await api.put?.(`/managers/${id}`, payload);
    toast.success('Coordenador Editada com sucesso');
  } catch (error) {
    throw new Error('Ocorreu um erro ao editar Coordenador');
  }
};

export const deleteManager = async (id: number, api: ApiCallContextProps) => {
  try {
    await api.del?.(`/managers/${id}`);
    toast.success('Coordenador excluído com sucesso');
  } catch (error) {
    throw new Error('Ocorreu um erro ao excluir Coordenador');
  }
};

export const getManyManager = async (api: ApiCallContextProps) => {
  try {
    await api.get?.(`/managers`);
  } catch (error) {
    throw new Error('Ocorreu um erro ao recuperar informações');
  }
};

export const getOneManager = async (id: number, api: ApiCallContextProps) => {
  try {
    await api.get?.(`/managers/${id}`);
  } catch (error) {
    throw new Error('Ocorreu um erro ao recuperar informações');
  }
};
