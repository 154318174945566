import {
  Button,
  Card,
  CardBody,
  CardFooter,
  IconButton,
  Input,
  Stack,
  Text,
} from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';
import { useState } from 'react';
import { SelectInput } from './SelectInput';
import { LabeledInput } from './LabeledInput';
import {
  defaultForm,
  defaultMiniForm,
  renderNames,
  typeOptions,
  unitOptions,
} from './formHelpers';

interface AggregatorInputProps {
  label: string;
  items: any;
  onAddItem: any;
  onRemoveItem: (tempId?: string, id?: number) => void;
  select?: boolean;
  options?: { value: string; label: string }[];
  compost?: boolean;
}

export const AggregatorInput = ({
  label,
  items,
  onAddItem,
  onRemoveItem,
  select = false,
  options,
  compost,
}: AggregatorInputProps) => {
  const [name, setName] = useState('');
  const [selectedOption, setSelectedOption] = useState(defaultForm);
  const [miniFormData, setMiniFormData] = useState<{
    type?: 'BLOOD' | 'SERUM' | 'PLASMA' | 'URINE';
    unit?: 'FRASCO_2ML' | 'FRASCO_4ML' | 'FRASCO_5ML' | 'TUBO_5ML';
    quantity?: number;
  }>(defaultMiniForm);

  const handleSelectChange = (e: any) => {
    const filteredSelectedOption = options?.find(
      (opt) => opt.value === e.target.value
    );
    if (filteredSelectedOption) setSelectedOption(filteredSelectedOption);
  };

  const handleAdd = () => {
    if (select && selectedOption.value) {
      onAddItem(selectedOption.value, selectedOption.label);
      setSelectedOption(defaultForm);
    } else if (
      compost &&
      miniFormData.quantity &&
      miniFormData.type &&
      miniFormData.unit
    ) {
      onAddItem(undefined, { ...miniFormData, tempId: String(Math.random()) });
      setMiniFormData(defaultMiniForm);
    } else if (!compost && name && !select) {
      onAddItem(name);
      setName('');
    }
  };

  const handleCompostMiniForm = (e: any) => {
    const { id, value } = e.target;
    setMiniFormData({ ...miniFormData, [id]: value });
  };

  return (
    <Card
      direction={{ base: 'column', sm: 'row' }}
      overflow="hidden"
      variant="outline"
      my="12px"
    >
      <Stack className="w-full flex flex-col gap-2">
        <CardBody>
          <Text className="-mt-3" size="md">
            {label}
          </Text>
          {items?.map((item: any) => (
            <div
              className=" w-full flex justify-between"
              key={item.tempId ?? item.id}
            >
              {compost ? (
                <>
                  <Text py="2">{renderNames(item.type)}</Text>
                  <Text py="2">{renderNames(item.unit)}</Text>
                  <Text py="2">{item.quantity}</Text>
                  <IconButton
                    onClick={() => onRemoveItem(item.tempId, item.id)}
                    aria-label="Excluir"
                    size="sm"
                    colorScheme="red"
                    icon={<DeleteIcon color="white" />}
                  />
                </>
              ) : (
                <>
                  <Text py="2">{item.name ?? item.people.name}</Text>
                  <IconButton
                    onClick={() => onRemoveItem(item.tempId, item.id)}
                    aria-label="Excluir"
                    size="sm"
                    colorScheme="red"
                    icon={<DeleteIcon color="white" />}
                  />
                </>
              )}
            </div>
          ))}
        </CardBody>

        <CardFooter className="w-full flex gap-4">
          {select ? (
            <SelectInput
              id="innerSelectInput"
              onChange={handleSelectChange}
              label=""
              options={options ?? []}
              value={selectedOption.value}
            />
          ) : compost ? (
            <>
              <div className="flex flex-col w-1/3">
                <SelectInput
                  id="type"
                  label="Tipo"
                  options={typeOptions}
                  onChange={handleCompostMiniForm}
                  value={miniFormData.type ?? ''}
                />
              </div>
              <div className="flex flex-col w-1/3">
                <SelectInput
                  id="unit"
                  label="Unidade"
                  options={unitOptions}
                  onChange={handleCompostMiniForm}
                  value={miniFormData.unit ?? ''}
                />
              </div>
              <div className="-mt-2">
                <LabeledInput
                  type="number"
                  id="quantity"
                  label="Quantidade"
                  onChange={handleCompostMiniForm}
                  value={miniFormData.quantity ?? 0}
                />
              </div>
            </>
          ) : (
            <Input value={name} onChange={(e) => setName(e.target.value)} />
          )}

          <Button onClick={handleAdd} variant="solid" colorScheme="blue">
            Adicionar
          </Button>
        </CardFooter>
      </Stack>
    </Card>
  );
};
