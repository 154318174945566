import { PermissionIdentifier, UserViewModel } from '../../types/models';
import { useForm } from '../../utils/hooks/useForm';
import { MainModal } from '../shared/Modal/MainModal';
import { FormSection } from '../shared/Form/FormSection';
import { useEffect, useMemo, useState } from 'react';
import { updateUserPermissions } from '../../services/usersServices';
import { useApiCall } from '../../utils/hooks/useApiCall';
import { toast } from 'react-toastify';
import { Checkbox } from '@chakra-ui/react';

interface PermissionsModalProps {
  isOpen: boolean;
  onClose: () => void;
  refetch: () => void;
  user?: UserViewModel;
}

export type PermissionsFormProps = {
  ADMIN?: boolean;
  TEST?: boolean;
  MASTER_ADMIN: boolean;
};

const defaultForm: Partial<PermissionsFormProps> = {
  ADMIN: false,
  TEST: false,
  MASTER_ADMIN: false,
};
export const PermissionsModal = ({
  isOpen,
  onClose,
  refetch,
  user,
}: PermissionsModalProps) => {
  //   const [userLoading, setUserLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const { formData, setFormData, resetForm } = useForm(defaultForm);
  const api = useApiCall();

  const permissionIdentifiers = useMemo(
    () => Object.entries(PermissionIdentifier),
    []
  );

  useEffect(() => {
    if (user) {
      const permissionsArray =
        user.permission?.map((permission) => permission.identifier) ?? [];
      setFormData({
        ADMIN: permissionsArray.includes(PermissionIdentifier.ADMIN),
        TEST: permissionsArray.includes(PermissionIdentifier.TEST),
        MASTER_ADMIN: permissionsArray.includes(
          PermissionIdentifier.MASTER_ADMIN
        ),
      });

      permissionIdentifiers.forEach(([key, value]) => {
        const checkbox = document.getElementById(key) as HTMLInputElement;
        if (checkbox && checkbox !== null) checkbox.checked = true;
      });
    } else {
      setFormData(defaultForm);
    }
  }, [permissionIdentifiers, setFormData, user]);

  const handleSubmit = async () => {
    try {
      setLoading(true);

      if (user) {
        await updateUserPermissions(user?.id, formData, api);
      }

      resetForm();
      onClose();
      refetch();
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };
  const handleChangeCheckbox = (id: string, value: boolean) => {
    setFormData({ ...formData, [id]: value });
  };
  return (
    <MainModal
      title="Criar Usuário"
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={handleSubmit}
      loading={loading}
    >
      <div>
        <FormSection title="Permissões do usuário">
          <div className="w-full flex flex-wrap">
            {permissionIdentifiers?.map(([key, value]) => (
              <div key={Math.random()} className="w-1/2">
                <Checkbox
                  id={key}
                  onChange={(e) =>
                    handleChangeCheckbox(e.target.id, e.target.checked)
                  }
                  //@ts-ignore
                  defaultChecked={formData[value]}
                >
                  {value}
                </Checkbox>
              </div>
            ))}
          </div>
        </FormSection>
      </div>
    </MainModal>
  );
};
