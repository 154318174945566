import { ChakraProvider } from '@chakra-ui/react';
import './App.css';
import { Router } from './routes/Router';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <ChakraProvider>
      <ToastContainer />
      <Router />
    </ChakraProvider>
  );
}

export default App;
