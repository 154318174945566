import { toast } from 'react-toastify';
import { PeopleViewModel } from '../types/models';
import { ApiCallContextProps } from '../utils/hooks/useApiCall';

export const createPeople = async (
  payload: Partial<PeopleViewModel>,
  api: ApiCallContextProps
) => {
  try {
    await api.post?.('/people', payload);
    toast.success('Pessoa Criada com sucesso');
  } catch (error) {
    throw new Error('Ocorreu um erro ao criar Pessoa');
  }
};

export const modifyPeople = async (
  id: number,
  payload: Partial<PeopleViewModel>,
  api: ApiCallContextProps
) => {
  try {
    await api.put?.(`/people/${id}`, payload);
    toast.success('Pessoa Editada com sucesso');
  } catch (error) {
    throw new Error('Ocorreu um erro ao editar Pessoa');
  }
};

export const deletePeople = async (id: number, api: ApiCallContextProps) => {
  try {
    await api.del?.(`/people/${id}`);
    toast.success('Pessoa excluída com sucesso');
  } catch (error) {
    throw new Error('Ocorreu um erro ao excluir Pessoa');
  }
};

export const getManyPeople = async (api: ApiCallContextProps) => {
  try {
    await api.get?.(`/people`);
  } catch (error) {
    throw new Error('Ocorreu um erro ao recuperar informações');
  }
};

export const getOnePeople = async (id: number, api: ApiCallContextProps) => {
  try {
    return await api.get<PeopleViewModel>?.(`/people/${id}`);
  } catch (error) {
    throw new Error('Ocorreu um erro ao recuperar informações');
  }
};
