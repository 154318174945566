import {
  ResearchCenterStudyViewModel,
  ResearchCenterViewModel,
  ResearcherViewModel,
  AddInfoViewModel,
  ClinicalStudyViewModel,
  ManagerViewModel,
} from '../../types/models';
import { useForm } from '../../utils/hooks/useForm';
import { MainModal } from '../shared/Modal/MainModal';
import { FormSection } from '../shared/Form/FormSection';
import { LabeledInput } from '../shared/Form/LabeledInput';
import { SelectInput } from '../shared/Form/SelectInput';
import { useCallback, useEffect, useState } from 'react';
import { useApiCall } from '../../utils/hooks/useApiCall';
import { toast } from 'react-toastify';
import { useQuery } from '../../utils/hooks/useQuery';
import {
  createResearchCenterStudy,
  getOneResearchCenterStudy,
  modifyResearchCenterStudy,
} from '../../services/researchCenterStudiesServices';
import {
  formatDate,
  formatISODate,
  formatSelectData,
} from '../../utils/helpers/helpers';
import { AggregatorInput } from '../shared/Form/AggregatorInput';
interface ResearchCenterStudyModalProps {
  isOpen: boolean;
  onClose: () => void;
  refetch: () => void;
  centerStudyId?: number;
}

const defaultForm: Partial<ResearchCenterStudyViewModel> = {
  id: undefined,
};

export const ResearchCenterStudyModal = ({
  isOpen,
  onClose,
  refetch,
  centerStudyId,
}: ResearchCenterStudyModalProps) => {
  const [loading, setLoading] = useState(false);
  const { formData, onFormChange, onSelectFormChange, setFormData } =
    useForm(defaultForm);
  const [researchersToRemove, setResearchersToRemove] = useState({
    managers: [],
    subResearchers: [],
  });
  const [centerStudy, setCenterStudy] = useState<
    ResearchCenterStudyViewModel | undefined
  >();
  const api = useApiCall();

  const { data: researchCenterData } =
    useQuery<ResearchCenterViewModel[]>('/research-centers');
  const { data: researcherData } =
    useQuery<ResearcherViewModel[]>('/researchers');
  const { data: clinicalStudyData } =
    useQuery<ClinicalStudyViewModel[]>('/clinical-studies');
  const { data: managerData } = useQuery<ManagerViewModel[]>('/managers');

  const getCenterStudy = useCallback(async () => {
    if (centerStudyId) {
      const data = await getOneResearchCenterStudy(centerStudyId, api);
      setCenterStudy(data);
    }
  }, [api, centerStudyId]);

  useEffect(() => {
    if (centerStudyId && centerStudy?.id !== centerStudyId) {
      getCenterStudy();
    }
    if (centerStudy) {
      setFormData({
        ...centerStudy,
        cepApprovalDate: formatDate(centerStudy.cepApprovalDate),
        sivDate: formatDate(centerStudy.sivDate),
        recruitingEndDate: formatDate(centerStudy.recruitingEndDate),
        recruitingStartDate: formatDate(centerStudy.recruitingStartDate),
        eosDate: formatDate(centerStudy.eosDate),
      });
    } else {
      setFormData(defaultForm);
    }
  }, [centerStudy, centerStudyId, getCenterStudy, setFormData]);

  const handleSubmit = async () => {
    const errors: string[] = [];

    // console.log(formData.peopleId);

    // //@ts-ignore
    // if (!formData.peopleId || formData.peopleId === 'SELECIONE')
    //   errors.push('É necessário selecionar uma pessoa');
    // if (!formData.entity) errors.push('O campo Entidade é obrigatório');
    // if (!formData.entityNumber)
    //   errors.push('O campo Registro na Entidade é obrigatório');

    // formData.peopleId = Number(formData.peopleId);

    if (errors.length) return errors?.forEach((error) => toast.error(error));
    setLoading(true);
    try {
      formData.cepApprovalDate = formatISODate(formData.cepApprovalDate);
      formData.sivDate = formatISODate(formData.sivDate);
      formData.recruitingStartDate = formatISODate(
        formData.recruitingStartDate
      );
      formData.recruitingEndDate = formatISODate(formData.recruitingEndDate);
      formData.eosDate = formatISODate(formData.eosDate);
      if (centerStudy) {
        const id = formData.id ?? 0;
        delete formData.id;
        delete formData.createdAt;
        //@ts-ignore
        formData.researchersToRemove = researchersToRemove;
        formData.updatedAt = new Date().toISOString();
        await modifyResearchCenterStudy(id, formData, api);
      } else {
        await createResearchCenterStudy(formData, api);
      }

      handleClose();
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleAddSelectAggregator =
    (type: 'managers' | 'subResearchers') => (id: number, name: string) => {
      setFormData({
        ...formData,
        [type]: [
          ...(formData[type] ?? []),
          {
            id,
            //@ts-ignore
            name,
          },
        ],
      });

      setResearchersToRemove({
        ...researchersToRemove,
        [type]: [
          ...researchersToRemove[type].filter(
            (item) => Number(item) !== Number(id)
          ),
        ],
      });
    };

  const handleRemoveSelectAggregator =
    (type: 'managers' | 'subResearchers') =>
    (localId?: string, id?: number) => {
      const tempAddInfo = [...(formData[type] ?? [])];
      const filteredAddInfo = tempAddInfo.filter(
        (researcher) => String(researcher.id) !== String(id)
      );

      setResearchersToRemove({
        ...researchersToRemove,
        [type]: [...researchersToRemove[type], id],
      });

      setFormData({ ...formData, [type]: filteredAddInfo });
    };

  const handleClose = () => {
    setLoading(false);
    refetch();
    setResearchersToRemove({ managers: [], subResearchers: [] });
    setCenterStudy(undefined);
    onClose();
  };

  return (
    <MainModal
      title="Vincular Estudo ao Centro de Pesquisa"
      isOpen={isOpen}
      onClose={handleClose}
      onConfirm={handleSubmit}
      loading={loading}
    >
      <div>
        <FormSection title="Informações do Vínculo">
          <LabeledInput
            value={formData.name ?? ''}
            type="text"
            id="name"
            label="Nome"
            onChange={onFormChange}
          />
          <SelectInput
            value={String(formData.clinicalStudyId) ?? ''}
            onChange={onSelectFormChange}
            label="Estudo Clínico"
            id="clinicalStudyId"
            options={formatSelectData(clinicalStudyData ?? [], true)}
          />
        </FormSection>
        <FormSection title="Informações do Centro de Pesquisa">
          <SelectInput
            value={String(formData.researchCenterId) ?? ''}
            onChange={onSelectFormChange}
            label="Centro de Pesquisa"
            id="researchCenterId"
            options={formatSelectData(researchCenterData ?? [])}
          />
        </FormSection>
        <FormSection title="Informações do Estudo">
          <LabeledInput
            value={formData.cepApprovalDate ?? ''}
            type="date"
            id="cepApprovalDate"
            label="Aprovação CEP Local"
            onChange={onFormChange}
          />
          <LabeledInput
            value={formData.sivDate ?? ''}
            type="date"
            id="sivDate"
            label="SIV"
            onChange={onFormChange}
          />
          <LabeledInput
            value={formData.recruitingStartDate ?? ''}
            type="date"
            id="recruitingStartDate"
            label="Data Início Recrutamento"
            onChange={onFormChange}
          />
          <LabeledInput
            value={formData.recruitingEndDate ?? ''}
            type="date"
            id="recruitingEndDate"
            label="Data Fim Recrutamento"
            onChange={onFormChange}
          />
          <LabeledInput
            value={formData.eosDate ?? ''}
            type="date"
            id="eosDate"
            label="Data EOS"
            onChange={onFormChange}
          />

          <SelectInput
            value={String(formData.mainResearcherId) ?? ''}
            onChange={onSelectFormChange}
            label="Investigador Principal"
            id="mainResearcherId"
            options={formatSelectData(researcherData ?? [])}
          />

          <AggregatorInput
            label="Sub Investigador"
            items={
              (formData.subResearchers as unknown as AddInfoViewModel[]) ?? []
            }
            select
            options={formatSelectData(
              researcherData?.filter(
                (researcher) =>
                  !formData.subResearchers
                    ?.map((subResearcher) => String(subResearcher.id))
                    .includes(String(researcher.id))
              ) ?? []
            )}
            onAddItem={handleAddSelectAggregator('subResearchers')}
            onRemoveItem={handleRemoveSelectAggregator('subResearchers')}
          />

          <AggregatorInput
            label="Coordenadores"
            items={(formData.managers as unknown as AddInfoViewModel[]) ?? []}
            select
            options={formatSelectData(
              managerData?.filter(
                (man) =>
                  !formData.managers
                    ?.map((manager) => String(manager.id))
                    .includes(String(man.id))
              ) ?? []
            )}
            onAddItem={handleAddSelectAggregator('managers')}
            onRemoveItem={handleRemoveSelectAggregator('managers')}
          />
          <LabeledInput
            value={formData.randPromisedNumber ?? 0}
            id="randPromisedNumber"
            label="Num. RAND Prometido"
            type="number"
            onChange={onFormChange}
          />
          <LabeledInput
            value={formData.screenPromisedNumber ?? 0}
            id="screenPromisedNumber"
            label="Num. Screen Prometido"
            type="number"
            onChange={onFormChange}
          />
        </FormSection>
      </div>
    </MainModal>
  );
};
