import { toast } from 'react-toastify';
import { ResearchCenterStudyViewModel } from '../types/models';
import { ApiCallContextProps } from '../utils/hooks/useApiCall';

export const createResearchCenterStudy = async (
  payload: Partial<ResearchCenterStudyViewModel>,
  api: ApiCallContextProps
) => {
  try {
    await api.post?.('/research-center-studies', payload);
    toast.success('Estudo Clínico Criado com sucesso');
  } catch (error) {
    throw new Error('Ocorreu um erro ao criar Estudo Clínico');
  }
};

export const modifyResearchCenterStudy = async (
  id: number,
  payload: Partial<ResearchCenterStudyViewModel>,
  api: ApiCallContextProps
) => {
  try {
    await api.put?.(`/research-center-studies/${id}`, payload);
    toast.success('Estudo Clínico Editada com sucesso');
  } catch (error) {
    throw new Error('Ocorreu um erro ao editar Estudo Clínico');
  }
};

export const deleteResearchCenterStudy = async (
  id: number,
  api: ApiCallContextProps
) => {
  try {
    await api.del?.(`/research-center-studies/${id}`);
    toast.success('Estudo Clínico excluído com sucesso');
  } catch (error) {
    throw new Error('Ocorreu um erro ao excluir Estudo Clínico');
  }
};

export const getManyResearchCenterStudy = async (
  api: ApiCallContextProps,
  name?: string
) => {
  try {
    return await api.get<ResearchCenterStudyViewModel[]>(
      `/research-center-studies${name ? `?name=${name}` : ''}`
    );
  } catch (error) {
    throw new Error('Ocorreu um erro ao recuperar informações');
  }
};

export const getOneResearchCenterStudy = async (
  id: number,
  api: ApiCallContextProps
) => {
  try {
    return await api.get<ResearchCenterStudyViewModel>?.(
      `/research-center-studies/${id}`
    );
  } catch (error) {
    throw new Error('Ocorreu um erro ao recuperar informações');
  }
};
