import { toast } from 'react-toastify';
import { ResearchCenterViewModel } from '../types/models';
import { ApiCallContextProps } from '../utils/hooks/useApiCall';

export const createResearchCenter = async (
  payload: Partial<ResearchCenterViewModel>,
  api: ApiCallContextProps
) => {
  try {
    await api.post?.('/research-centers', payload);
    toast.success('Centro de Pesquisa Criada com sucesso');
  } catch (error) {
    throw new Error('Ocorreu um erro ao criar Centro de Pesquisa');
  }
};

export const modifyResearchCenter = async (
  id: number,
  payload: Partial<ResearchCenterViewModel>,
  api: ApiCallContextProps
) => {
  try {
    await api.put?.(`/research-centers/${id}`, payload);
    toast.success('Centro de Pesquisa Editado com sucesso');
  } catch (error) {
    throw new Error('Ocorreu um erro ao editar Centro de Pesquisa');
  }
};

export const deleteResearchCenter = async (
  id: number,
  api: ApiCallContextProps
) => {
  try {
    await api.del?.(`/research-centers/${id}`);
    toast.success('Centro de Pesquisa excluído com sucesso');
  } catch (error) {
    throw new Error('Ocorreu um erro ao excluir Centro de Pesquisa');
  }
};

export const getManyResearchCenter = async (api: ApiCallContextProps) => {
  try {
    await api.get?.(`/research-centers`);
  } catch (error) {
    throw new Error('Ocorreu um erro ao recuperar informações');
  }
};

export const getOneResearchCenter = async (
  id: number,
  api: ApiCallContextProps
) => {
  try {
    return await api.get<ResearchCenterViewModel>?.(`/research-centers/${id}`);
  } catch (error) {
    throw new Error('Ocorreu um erro ao recuperar informações');
  }
};
