import { useCallback, useEffect, useState } from 'react';
import { useApiCall } from './useApiCall';
import { toast } from 'react-toastify';

export function useQuery<T>(query: string) {
  const { get } = useApiCall();
  const [data, setData] = useState<T>();
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState<string>('');

  const refetch = useCallback(async () => {
    try {
      setLoading(true);
      const response = await get<T>?.(`${query}${search ? `?${search}` : ''}`);
      setData(response);
    } catch (error) {
      toast.error(`Algo deu errado com a consulta: ${query}`);
    } finally {
      setLoading(false);
    }
  }, [get, query, search]);

  useEffect(() => {
    refetch();
  }, [refetch, search]);

  return {
    data,
    refetch,
    loading,
    setSearch,
  };
}
