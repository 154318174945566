import { ApexOptions } from 'apexcharts';

const radialBaseOptions: ApexOptions = {
  chart: {
    height: 300,
    type: 'radialBar',
  },
  title: {
    align: 'center',
    text: '',
    style: {
      fontSize: '20px',
    },
  },
  colors: ['#B1E2E9'],
  plotOptions: {
    radialBar: {
      startAngle: -110,
      endAngle: 110,
      track: {
        background: '#333',
        startAngle: -110,
        endAngle: 110,
      },
      dataLabels: {
        name: {
          show: false,
        },
        value: {
          fontSize: '18px',
          show: true,
        },
      },
    },
  },
  fill: {
    type: 'gradient',
    gradient: {
      shade: 'dark',
      type: 'horizontal',
      gradientToColors: ['#01ACED'],
      stops: [0, 100],
    },
  },
  stroke: {
    lineCap: 'butt',
  },
  labels: ['Progress'],
};

export const generateRadialBaseChart = (title: string) => {
  let base = { ...radialBaseOptions };
  if (base.title) {
    base = { ...base, title: { ...base.title, text: title } };
  }

  return base;
};
