import { PeopleViewModel } from '../../types/models';
import { useForm } from '../../utils/hooks/useForm';
import { MainModal } from '../shared/Modal/MainModal';
import { FormSection } from '../shared/Form/FormSection';
import { LabeledInput } from '../shared/Form/LabeledInput';
import { SelectInput } from '../shared/Form/SelectInput';
import { useEffect, useState } from 'react';
import { createPeople, modifyPeople } from '../../services/peopleServices';
import { useApiCall } from '../../utils/hooks/useApiCall';
import { toast } from 'react-toastify';
import { formatDate } from 'date-fns';

interface PeopleModalProps {
  isOpen: boolean;
  onClose: () => void;
  refetch: () => void;
  people?: PeopleViewModel;
}

const defaultForm: Partial<PeopleViewModel> = {
  name: '',
  document: '',
  email: '',
  phone: '',
  gender: undefined,
  alternativePhone: '',
  birthDate: '',
  street: '',
  number: '',
  compl: '',
  zipCode: '',
};

const genderOptions = [
  { value: 'MALE', label: 'Masculino' },
  { value: 'FEMALE', label: 'Feminino' },
  { value: 'OTHER', label: 'Outro' },
];

export const PeopleModal = ({
  isOpen,
  onClose,
  refetch,
  people,
}: PeopleModalProps) => {
  const [loading, setLoading] = useState(false);
  const { formData, onFormChange, onSelectFormChange, setFormData } =
    useForm(defaultForm);
  const api = useApiCall();

  useEffect(() => {
    if (people) {
      setFormData({
        ...people,
        birthDate: formatDate(new Date(people.birthDate), 'yyyy-MM-dd'),
      });
    } else {
      setFormData(defaultForm);
    }
  }, [people, setFormData]);

  const handleClose = () => {
    setFormData(defaultForm);
    onClose();
  };

  const handleSubmit = async () => {
    formData.birthDate = formData.birthDate
      ? new Date(formData.birthDate ?? '').toISOString()
      : undefined;
    setLoading(true);
    try {
      if (people) {
        const id = formData.id ?? 0;
        delete formData.id;
        delete formData.createdAt;
        formData.updatedAt = new Date().toISOString();
        await modifyPeople(id, formData, api);
      } else {
        await createPeople(formData, api);
      }

      setLoading(false);
      refetch();
      handleClose();
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };
  return (
    <MainModal
      title="Criar Pessoa"
      isOpen={isOpen}
      onClose={handleClose}
      onConfirm={handleSubmit}
      loading={loading}
    >
      <div>
        <FormSection title="Informações Pessoais">
          <LabeledInput
            value={formData.name ?? ''}
            id="name"
            label="Nome"
            onChange={onFormChange}
          />
          <LabeledInput
            value={formData.document ?? ''}
            id="document"
            label="CPF/CNPJ"
            onChange={onFormChange}
          />

          <SelectInput
            value={formData.gender ?? ''}
            id="gender"
            options={genderOptions}
            onChange={onSelectFormChange}
            label="Gênero"
          />

          <LabeledInput
            value={formData.birthDate ?? ''}
            id="birthDate"
            label="Data de Nascimento"
            type="date"
            onChange={onFormChange}
          />
        </FormSection>
        <FormSection title="Informações de contato">
          <LabeledInput
            value={formData.email ?? ''}
            id="email"
            label="E-mail"
            onChange={onFormChange}
          />
          <LabeledInput
            value={formData.phone ?? ''}
            id="phone"
            label="Telefone"
            onChange={onFormChange}
          />
          <LabeledInput
            value={formData.alternativePhone ?? ''}
            id="alternativePhone"
            label="Telefone Alternativo"
            onChange={onFormChange}
          />
        </FormSection>

        <FormSection title="Informações de Endereço">
          <LabeledInput
            value={formData.street ?? ''}
            id="street"
            label="Rua"
            onChange={onFormChange}
          />
          <LabeledInput
            value={formData.number ?? ''}
            id="number"
            label="Número"
            onChange={onFormChange}
          />
          <LabeledInput
            value={formData.compl ?? ''}
            id="compl"
            label="Complemento"
            onChange={onFormChange}
          />
          <LabeledInput
            value={formData.zipCode ?? ''}
            id="zipCode"
            label="CEP"
            onChange={onFormChange}
          />
        </FormSection>
      </div>
    </MainModal>
  );
};
