import { toast } from 'react-toastify';
import { EquipmentViewModel } from '../types/models';
import { ApiCallContextProps } from '../utils/hooks/useApiCall';

export const createEquipment = async (
  payload: Partial<EquipmentViewModel>,
  api: ApiCallContextProps
) => {
  try {
    await api.post?.('/equipments', payload);
    toast.success('Equipamento Criado com sucesso');
  } catch (error) {
    throw new Error('Ocorreu um erro ao criar Equipamento');
  }
};

export const modifyEquipment = async (
  id: number,
  payload: Partial<EquipmentViewModel>,
  api: ApiCallContextProps
) => {
  try {
    await api.put?.(`/equipments/${id}`, payload);
    toast.success('Equipamento Editada com sucesso');
  } catch (error) {
    throw new Error('Ocorreu um erro ao editar Equipamento');
  }
};

export const deleteEquipment = async (id: number, api: ApiCallContextProps) => {
  try {
    await api.del?.(`/equipments/${id}`);
    toast.success('Equipamento excluído com sucesso');
  } catch (error) {
    throw new Error('Ocorreu um erro ao excluir Equipamento');
  }
};

export const getManyEquipment = async (api: ApiCallContextProps) => {
  try {
    await api.get?.(`/equipments`);
  } catch (error) {
    throw new Error('Ocorreu um erro ao recuperar informações');
  }
};

export const getOneEquipment = async (id: number, api: ApiCallContextProps) => {
  try {
    await api.get?.(`/equipments/${id}`);
  } catch (error) {
    throw new Error('Ocorreu um erro ao recuperar informações');
  }
};
