import {
  ClinicalStudyViewModel,
  PeopleViewModel,
  StudyPatientViewModel,
  ResearchCenterViewModel,
} from '../../types/models';
import { useForm } from '../../utils/hooks/useForm';
import { MainModal } from '../shared/Modal/MainModal';
import { FormSection } from '../shared/Form/FormSection';
import { LabeledInput } from '../shared/Form/LabeledInput';
import { SelectInput } from '../shared/Form/SelectInput';
import { useEffect, useMemo, useState } from 'react';
import { useApiCall } from '../../utils/hooks/useApiCall';
import { toast } from 'react-toastify';
import { useQuery } from '../../utils/hooks/useQuery';
import {
  createStudyPatient,
  modifyStudyPatient,
} from '../../services/studyPatientsServices';

interface StudyPatientModalProps {
  isOpen: boolean;
  onClose: () => void;
  refetch: () => void;
  studyPatient?: StudyPatientViewModel;
}

const defaultForm: Partial<StudyPatientViewModel> = {
  id: undefined,
  patientId: undefined,
  researchCenterStudyId: undefined,
  tcle: undefined,
  tcleDate: undefined,
};

export const StudyPatientModal = ({
  isOpen,
  onClose,
  refetch,
  studyPatient,
}: StudyPatientModalProps) => {
  const [loading, setLoading] = useState(false);
  const { formData, onFormChange, onSelectFormChange, setFormData } =
    useForm(defaultForm);
  const api = useApiCall();

  const { data: patientData } =
    useQuery<ResearchCenterViewModel[]>('/patients');

  const { data: researchCenterStudyData } = useQuery<ClinicalStudyViewModel[]>(
    '/research-center-studies'
  );

  useEffect(() => {
    if (studyPatient) {
      setFormData({
        ...studyPatient,
      });
    } else {
      setFormData(defaultForm);
    }
  }, [studyPatient, setFormData]);

  const handleSubmit = async () => {
    const errors = [];

    //@ts-ignore
    if (!formData.patientId || formData.patientId === 'SELECIONE')
      errors.push('É necessário selecionar uma pessoa');
    if (
      !formData.researchCenterStudyId ||
      //@ts-ignore
      formData.researchCenterStudyId === 'SELECIONE'
    )
      errors.push('É necessário selecionar um estudo');
    // if (!formData.tcle) errors.push('O campo TCLE é obrigatório');
    // //@ts-ignore
    // if (!formData.tcleDate) errors.push('O campo DATA TCLE é obrigatório');

    formData.patientId = Number(formData.patientId);
    formData.researchCenterStudyId = Number(formData.researchCenterStudyId);
    formData.tcleDate = new Date(formData.tcleDate ?? 0).toISOString();

    if (errors.length) return errors.forEach((error) => toast.error(error));
    setLoading(true);

    try {
      if (studyPatient) {
        const id = formData.id ?? 0;
        delete formData.id;
        delete formData.createdAt;
        formData.updatedAt = new Date().toISOString();
        await modifyStudyPatient(id, formData, api);
      } else {
        await createStudyPatient(formData, api);
      }

      setLoading(false);
      refetch();
      onClose();
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const formatSelectData = (items: any[], people = false) =>
    items?.map((item) => ({
      value: String(item.id),
      label: people ? item.name : item.people.name,
    }));

  const handleClose = () => {
    setFormData(defaultForm);
  };

  return (
    <MainModal
      title="Criar Paciente"
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={handleSubmit}
      loading={loading}
    >
      <div>
        <FormSection title="Informações do Paciente">
          <SelectInput
            value={String(formData.patientId) ?? ''}
            onChange={onSelectFormChange}
            label="Paciente"
            id="patientId"
            options={formatSelectData(patientData ?? [])}
          />
        </FormSection>
        <FormSection title="Informações do Estudo">
          <SelectInput
            value={String(formData.researchCenterStudyId) ?? ''}
            onChange={onSelectFormChange}
            label="Estudo"
            id="researchCenterStudyId"
            options={formatSelectData(researchCenterStudyData ?? [], true)}
          />
        </FormSection>
        <FormSection title="Informações do TCLE">
          <LabeledInput
            value={formData.tcle ?? ''}
            id="tcle"
            label="TCLE"
            onChange={onFormChange}
          />
          <LabeledInput
            value={formData.tcleDate ?? ''}
            id="tcleDate"
            type="date"
            label="Data TCLE"
            onChange={onFormChange}
          />
        </FormSection>
      </div>
    </MainModal>
  );
};
