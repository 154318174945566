import { toast } from 'react-toastify';
import { ClinicalStudyViewModel } from '../types/models';
import { ApiCallContextProps } from '../utils/hooks/useApiCall';

export const createClinicalStudy = async (
  payload: Partial<ClinicalStudyViewModel>,
  api: ApiCallContextProps
) => {
  try {
    await api.post?.('/clinical-studies', payload);
    toast.success('Estudo Clínico Criado com sucesso');
  } catch (error) {
    throw new Error('Ocorreu um erro ao criar Estudo Clínico');
  }
};

export const modifyClinicalStudy = async (
  id: number,
  payload: Partial<ClinicalStudyViewModel>,
  api: ApiCallContextProps
) => {
  try {
    await api.put?.(`/clinical-studies/${id}`, payload);
    toast.success('Estudo Clínico Editada com sucesso');
  } catch (error) {
    throw new Error('Ocorreu um erro ao editar Estudo Clínico');
  }
};

export const deleteClinicalStudy = async (
  id: number,
  api: ApiCallContextProps
) => {
  try {
    await api.del?.(`/clinical-studies/${id}`);
    toast.success('Estudo Clínico excluído com sucesso');
  } catch (error) {
    throw new Error('Ocorreu um erro ao excluir Estudo Clínico');
  }
};

export const getManyClinicalStudy = async (api: ApiCallContextProps) => {
  try {
    await api.get?.(`/clinical-studies`);
  } catch (error) {
    throw new Error('Ocorreu um erro ao recuperar informações');
  }
};

export const getOneClinicalStudy = async (
  id: number,
  api: ApiCallContextProps
) => {
  try {
    await api.get?.(`/clinical-studies/${id}`);
  } catch (error) {
    throw new Error('Ocorreu um erro ao recuperar informações');
  }
};
