import {
  Box,
  IconButton,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import { PanelWrapper } from '../../components/shared/PanelWrapper/PannelWrapper';
import { FilterContainer } from '../../components/shared/Filter/FilterContainer';
import { CalendarIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { useQuery } from '../../utils/hooks/useQuery';
import { StudyPatientViewModel } from '../../types/models';
import { useState } from 'react';
import { StudyPatientModal } from '../../components/StudyPatients/StudyPatientModal';
import { DeleteConfirmationPopover } from '../../components/shared/Popover/DeleteConfirmationPopover';
import { toast } from 'react-toastify';
import { useApiCall } from '../../utils/hooks/useApiCall';
import { deleteStudyPatient } from '../../services/studyPatientsServices';
import { format } from 'date-fns';
import { PatientEventsModal } from '../../components/StudyPatients/PatientEventsModal';

const columns = [
  { label: 'Estudo', key: 'acronym' },
  { label: 'Paciente', key: 'studyId' },
  { label: 'TCLE', key: 'name' },
  { label: 'Data TCLE', key: 'industry.name' },
  { label: 'Ações', key: 'actions' },
];

export const StudyPatientsPage = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenEvents,
    onOpen: onOpenEvents,
    onClose: onCloseEvents,
  } = useDisclosure();

  const [studyPatientData, setStudyPatient] = useState<
    StudyPatientViewModel | undefined
  >();
  const [studyPatientLoading, setStudyPatientLoading] = useState(false);
  const { data: studyPatients, refetch } =
    useQuery<StudyPatientViewModel[]>('/study-patients');
  const api = useApiCall();

  const handleClose = () => {
    setStudyPatient(undefined);
    onClose();
  };

  const handleOpenEditModal = (usr: StudyPatientViewModel) => {
    setStudyPatient(usr);
    onOpen();
  };

  const handleDelete = async (studyPatientId: number) => {
    try {
      setStudyPatientLoading(true);
      await deleteStudyPatient(studyPatientId, api);
      refetch();
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      setStudyPatientLoading(false);
    }
  };

  const handleOpenEventsModal = (usr: StudyPatientViewModel) => {
    setStudyPatient(usr);
    onOpenEvents();
  };

  const handleCloseEvents = () => {
    setStudyPatient(undefined);
    onCloseEvents();
  };

  return (
    <>
      <PatientEventsModal
        studyPatient={studyPatientData}
        isOpen={isOpenEvents}
        refetch={refetch}
        onClose={handleCloseEvents}
      />
      <StudyPatientModal
        studyPatient={studyPatientData}
        refetch={refetch}
        isOpen={isOpen}
        onClose={handleClose}
      />
      <PanelWrapper title="Pacientes x Estudos">
        <FilterContainer onOpenModal={onOpen} />
        <Box className="rounded-lg shadow p-4 pt-10 mt-5">
          <Table>
            <Thead>
              <Tr>
                {columns.map((column) => (
                  <Th key={column.key}>{column.label}</Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {studyPatients?.map((row) => {
                if (row.events.length == 2 && !row.randomized) {
                  return null;
                }
                return (
                  <Tr
                    key={row.id}
                    className="hover:bg-gray-50 hover:cursor-pointer"
                  >
                    <Td>{row.researchCenterStudy?.clinicalStudy?.name}</Td>
                    <Td>{row.patient.people.name}</Td>
                    <Td>{row.tcle}</Td>
                    <Td>
                      {row.tcleDate
                        ? format(new Date(row.tcleDate), 'dd/MM/yyyy')
                        : 'N/A'}
                    </Td>

                    <Td>
                      <div className="w-full flex justify-around">
                        <DeleteConfirmationPopover
                          loading={studyPatientLoading}
                          onConfirm={() => handleDelete(row.id)}
                        >
                          <IconButton
                            colorScheme="red"
                            aria-label="Excluir"
                            icon={<DeleteIcon color="white" />}
                          />
                        </DeleteConfirmationPopover>
                        <IconButton
                          onClick={() => handleOpenEventsModal(row)}
                          colorScheme="blue"
                          aria-label="Agenda"
                          icon={<CalendarIcon color="white" />}
                        />
                        <IconButton
                          onClick={() => handleOpenEditModal(row)}
                          colorScheme="blue"
                          aria-label="Editar"
                          icon={<EditIcon color="white" />}
                        />
                      </div>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Box>
      </PanelWrapper>
    </>
  );
};
