import {
  Box,
  Button,
  Popover,
  PopoverArrow,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
} from '@chakra-ui/react';
import { ReactNode } from 'react';

interface ConfirmationPopoverProps {
  children: ReactNode;
  triggerText: string;
  confirmationTitle?: string
  confirmationText?: string
  triggerColorScheme?: 'red' | 'blue'
}

export const ConfirmationPopover = ({
  children,
  triggerText,
  confirmationText = "",
  confirmationTitle = "Finalizar",
  triggerColorScheme = 'blue'
}: ConfirmationPopoverProps) => {
  return (
    <Popover>
      <PopoverTrigger>
        <Button colorScheme={triggerColorScheme}>{triggerText}</Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverHeader fontWeight="semibold">{confirmationTitle}?</PopoverHeader>
        <Box className="w-full py-2 px-3 ">
        {confirmationText}
        </Box>
        <PopoverArrow />
        <PopoverCloseButton />
        <Box className="w-full p-2 flex justify-center">{children}</Box>
      </PopoverContent>
    </Popover>
  );
};
