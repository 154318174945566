import {
  Box,
  IconButton,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import { PanelWrapper } from '../../components/shared/PanelWrapper/PannelWrapper';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import {
  ResearchCenterStudyViewModel,
  VisitViewModel,
} from '../../types/models';
import { useState } from 'react';
import { DeleteConfirmationPopover } from '../../components/shared/Popover/DeleteConfirmationPopover';
import { toast } from 'react-toastify';
import { useApiCall } from '../../utils/hooks/useApiCall';
import { deleteClinicalStudy } from '../../services/clinicalStudiesServices';
import { TemplateAttachmentFilter } from '../../components/shared/Filter/TemplateAttachmentFilter';
import { AttachmentModal } from '../../components/Attachments/AttachmentModal';
import { useQuery } from '../../utils/hooks/useQuery';

const columns = [
  { label: 'Acronomo', key: 'acronym' },
  { label: 'Título da Visita', key: 'name' },
  { label: 'Janela Visita', key: 'studyId' },
  { label: 'Ações', key: 'actions' },
];

export const AttachmentsPage = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [clinicalStudyData, setClinicalStudy] = useState<
    ResearchCenterStudyViewModel | undefined
  >();

  const [visit, setVisit] = useState<VisitViewModel | undefined>();
  const [clinicalStudyLoading, setClinicalStudyLoading] = useState(false);
  const { data } = useQuery<ResearchCenterStudyViewModel[]>(
    '/research-center-studies'
  );

  const api = useApiCall();

  const handleClose = () => {
    setVisit(undefined);
    onClose();
  };

  const handleOpenEditModal = (usr: VisitViewModel) => {
    setVisit(usr);
    onOpen();
  };

  const handleDelete = async (clinicalStudyId: number) => {
    try {
      setClinicalStudyLoading(true);
      await deleteClinicalStudy(clinicalStudyId, api);
      // refetch();
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      setClinicalStudyLoading(false);
    }
  };

  return (
    <>
      <AttachmentModal
        visit={visit}
        researchCenterStudy={clinicalStudyData}
        isOpen={isOpen}
        onClose={handleClose}
        researchCenterStudies={data}
        visits={clinicalStudyData?.clinicalStudy.visits}
      />
      <PanelWrapper title="Templates">
        <TemplateAttachmentFilter
          onOpenModal={onOpen}
          onSearch={setClinicalStudy}
        />
        <Box className="rounded-lg shadow p-4 pt-10 mt-5">
          <Table>
            <Thead>
              <Tr>
                {columns.map((column) => (
                  <Th key={column.key}>{column.label}</Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {clinicalStudyData?.clinicalStudy.visits?.map((row) => (
                <Tr
                  key={row.id}
                  className="hover:bg-gray-50 hover:cursor-pointer"
                >
                  <Td>{row.clinicalStudy?.acronym}</Td>
                  <Td>{row.name}</Td>
                  <Td>{row.visitWindow}</Td>

                  <Td>
                    <div className="w-full flex justify-around">
                      <DeleteConfirmationPopover
                        loading={clinicalStudyLoading}
                        onConfirm={() => handleDelete(row.id)}
                      >
                        <IconButton
                          colorScheme="red"
                          aria-label="Excluir"
                          icon={<DeleteIcon color="white" />}
                        />
                      </DeleteConfirmationPopover>
                      <IconButton
                        onClick={() => handleOpenEditModal(row)}
                        colorScheme="blue"
                        aria-label="Editar"
                        icon={<EditIcon color="white" />}
                      />
                    </div>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      </PanelWrapper>
    </>
  );
};
