import { Box, Input, Text } from '@chakra-ui/react';
import { ChangeEvent } from 'react';

interface LabeledInputProps {
  id: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  label: string;
  type?: 'text' | 'date' | 'password' | 'number' | 'datetime-local';
  value: string | number;
  disabled?: boolean;
}

export const LabeledInput = ({
  id,
  value,
  onChange,
  label,
  type = 'text',
  disabled = false,
}: LabeledInputProps) => {
  return (
    <>
      <Box className="my-2">
        <Text className="text-sm text-slate-700">{label}:</Text>
        <Input
          disabled={disabled}
          value={value}
          type={type}
          id={id}
          onChange={onChange}
        />
      </Box>
    </>
  );
};
