import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';
import { LoginPage } from '../pages/LoginPage';
import { DashboardPage } from '../pages/admin/DashboardPage';
import { DashboardPage as ResearcherDashboardPage } from '../pages/researcher/DashboardPage';
import { SideMenuWrapper } from '../components/SideMenu/SideMenuWrapper';
import { PeoplePage } from '../pages/admin/PeoplePage';
import { AuthProvider } from '../utils/hooks/useAuth';
import { ApiCallProvider } from '../utils/hooks/useApiCall';
import { UsersPage } from '../pages/admin/UsersPage';
import { ResearchCenterPage } from '../pages/admin/ResearchCenterPage';
import { ResearchersPage } from '../pages/admin/ResearcherPage';
import { ManagersPage } from '../pages/admin/ManagerPage';
import { ClinicalStudiesPage } from '../pages/admin/ClinicalStudyPage';
import { PatientsPage } from '../pages/admin/PatientsPage';
import { EquipmentsPage } from '../pages/admin/EquipmentPage';
import { ResearchCenterStudyPage } from '../pages/admin/ResearchCenterStudyPage';
import { ProceduresPage } from '../pages/admin/ProcedurePage';
import { VisitsPage } from '../pages/admin/VisitPage';
import { CalendarPage } from '../pages/admin/CalendarPage';
import { StudyPatientsPage } from '../pages/admin/StudyPatientPage';
import { ResearcherSideMenuWrapper } from '../components/SideMenu/ResearcherSideMenuWrapper';
import { ManagerSideMenuWrapper } from '../components/SideMenu/ManagerSideMenuWrapper';
import { ManagerCalendarWrapper } from '../components/Managers/ManagerCalendarWrapper';
import { PendingPage } from '../pages/researcher/PendingPage';
import { AdminPendingPage } from '../pages/admin/AdminPendingPage';
import { PatientSchedulePendingPage } from '../pages/admin/PatientSchedulePendingPage';
import { AttachmentsPage } from '../pages/manager/attachments';

export const Router = () => {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LoginPage />} />
        </Routes>
        <ApiCallProvider>
          <Routes>
            <Route
              path="manager"
              element={
                <ManagerSideMenuWrapper>
                  <Outlet />
                </ManagerSideMenuWrapper>
              }
            >
              <Route path="dashboard" element={<DashboardPage />} />
              <Route path="people" element={<PeoplePage />} />
              <Route path="equipments" element={<EquipmentsPage />} />
              <Route path="procedures" element={<ProceduresPage />} />
              <Route path="calendar" element={<ManagerCalendarWrapper />} />
              <Route path="study-patients" element={<StudyPatientsPage />} />
              <Route path="patients" element={<PatientsPage />} />
              <Route path="researchers" element={<ResearchersPage />} />
              <Route path="pending" element={<PatientSchedulePendingPage />} />
              <Route path="attachments" element={<AttachmentsPage />} />
            </Route>
          </Routes>
          <Routes>
            <Route
              path="/researcher"
              element={
                <ResearcherSideMenuWrapper>
                  <Outlet />
                </ResearcherSideMenuWrapper>
              }
            >
              <Route path="dashboard" element={<ResearcherDashboardPage />} />
              <Route path="pending" element={<PendingPage />} />
            </Route>
            <Route
              path="admin"
              element={
                <SideMenuWrapper>
                  <Outlet />
                </SideMenuWrapper>
              }
            >
              <Route path="dashboard" element={<DashboardPage />} />
              <Route path="users" element={<UsersPage />} />
              <Route path="research-center" element={<ResearchCenterPage />} />
              <Route path="researchers" element={<ResearchersPage />} />
              <Route path="managers" element={<ManagersPage />} />
              <Route
                path="clinical-studies"
                element={<ClinicalStudiesPage />}
              />
              <Route path="patients" element={<PatientsPage />} />
              <Route path="equipments" element={<EquipmentsPage />} />
              <Route
                path="research-center-studies"
                element={<ResearchCenterStudyPage />}
              />
              <Route path="calendar" element={<CalendarPage />} />
              <Route path="procedures" element={<ProceduresPage />} />
              <Route path="study-visits" element={<VisitsPage />} />
              <Route path="study-patients" element={<StudyPatientsPage />} />
              <Route path="people" element={<PeoplePage />} />
            </Route>
          </Routes>
        </ApiCallProvider>
      </BrowserRouter>
    </AuthProvider>
  );
};
