export type PeopleViewModel = {
  id: number;
  name: string;
  document: string;
  email?: string;
  phone?: string;
  gender?: 'MALE' | 'FEMALE' | 'OTHER';
  alternativePhone?: string;
  manager: ManagerViewModel[];
  researcher: ResearcherViewModel[];
  researchCenter: ResearchCenterStudyViewModel[];
  researchCenterBackup: ResearchCenterViewModel[];
  birthDate: string;
  Patient: PatientViewModel[];
  street?: string;
  number?: string;
  compl?: string;
  zipCode?: string;
  createdAt: string;
  updatedAt?: string;
};

export type UserViewModel = {
  id: number;
  username: string;
  peopleId: number;
  people?: PeopleViewModel;
  permission: PermissionViewModel[];
  researchCenterId?: number;
  researchCenter?: ResearchCenterViewModel;
  createdAt: string;
};

export enum PermissionIdentifier {
  MASTER_ADMIN = 'MASTER_ADMIN',
  ADMIN = 'ADMIN',
  TEST = 'TEST',
}

export type PermissionViewModel = {
  id: number;
  identifier: PermissionIdentifier;
  userId: number;
  user: UserViewModel;
  createdAt: string;
  updatedAt?: string;
};

export type ResearchCenterViewModel = {
  id: number;
  cnes?: string;
  people: PeopleViewModel;
  peopleId: number;
  backupHospital?: PeopleViewModel;
  backupHospitalId?: number;
  centerType: 'SATELLITE' | 'MAIN';
  createdAt: string;
  updatedAt?: string;
};

export type ResearcherViewModel = {
  id: number;
  people: PeopleViewModel;
  peopleId: number;
  agenda?: AgendaViewModel[];
  agendaId?: number;
  crm: string;
  researchCenterId?: number;
  researchCenter?: ResearchCenterViewModel;
  createdAt: string;
  updatedAt?: string;
};

export type ManagerViewModel = {
  id: number;
  people: PeopleViewModel;
  peopleId: number;
  profession: string;
  entity?: string;
  entityNumber?: string;
  researchCenterId?: number;
  researchCenter?: ResearchCenterViewModel;
  createdAt: string;
  updatedAt?: string;
};

export type VisitMaterialViewModel = {
  id: number;
  type: 'BLOOD' | 'URINE' | 'PLASMA' | 'SERUM';
  unit: string;
  quantity: number;
  createdAt?: string;
  updatedAt?: string;
  visit: VisitViewModel;
  tempId?: string;
  visitId: number;
};

export type VisitViewModel = {
  id: number;
  name: string;
  kitName: string;
  clinicalStudy?: ClinicalStudyViewModel;
  clinicalStudyId?: number;
  visitMaterials?: VisitMaterialViewModel[];
  kitInfo: KitInfoViewModel[];
  procedures: ProcedureViewModel[];
  visitWindow: number;
  visitGte: number;
  visitLte: number;
  internalReminder: string;
  internalNotes: string;
  messageToPatient: string;
  sendMessageWithin: number;
  createdAt?: string;
  updatedAt?: string;
  duration?: number;
  randomized: boolean;
};

export type KitInfoViewModel = {
  id: number;
  batchNumber: string;
  dueDate: string;
  visitId: number;
  visit: VisitViewModel;
  createdAt?: string;
  updatedAt?: string;
};

export type AddInfoViewModel = {
  id?: number;
  name: string;
  batchNumber?: String;
  dueDate?: string;
  visitId?: number;
  visit?: VisitViewModel;
  createdAt?: string;
  tempId?: string;
  updatedAt?: string;
  type:
    | 'EXCLUSION_CRITERIA'
    | 'INCLUSION_CRITERIA'
    | 'KIT_INFO'
    | 'VISIT_MATERIAL'
    | 'DESCRIPTOR'
    | 'OUTCOME'
    | 'STUDY_COMPARATOR';
  clinicalStudy?: ClinicalStudyViewModel;
  clinicalStudyId?: number;
};

export type ClinicalStudyViewModel = {
  id: number;
  name: string;
  industry: PeopleViewModel;
  industryId: number;
  acronym?: string;
  intervention: string;
  studyId: string;
  originCountry: string;
  researchCenterId?: number;
  researchCenter?: ResearchCenterViewModel;
  mainResearcherId?: number;
  mainResearcher?: ResearcherViewModel[];
  subResearchers?: ResearcherViewModel[];
  managers?: ManagerViewModel[];
  visits: VisitViewModel[];
  randPromisedNumber?: string;
  population?: string;
  type?: string;
  subType?: string;
  addInfo: AddInfoViewModel[];
  startDate?: string;
  endDate?: string;
  createdAt?: string;
  updatedAt?: string;
  cepApprovalDate?: string;
  recruitingStartDate?: string;
  recruitingEndDate?: string;
  sivDate?: string;
  eosDate?: string;
};

export type PatientViewModel = {
  id: number;
  peopleId: number;
  people: PeopleViewModel;
  createdAt?: string;
  updatedAt?: string;
  researchCenterId?: number;
  researchCenter?: ResearchCenterViewModel;
  clinicalStudies: ClinicalStudyViewModel[];
};

export type EquipmentViewModel = {
  id: number;
  name?: string;
  brand?: string;
  patrimonyCode?: string;
  validationDate?: string;
  validationDueDate?: string;
  researchCenterId?: number;
  researchCenter: ResearchCenterViewModel;
  createdAt?: string;
  updatedAt?: string;
};

export type ResearchCenterStudyViewModel = {
  id: number;
  name: string;
  clinicalStudyId: number;
  clinicalStudy: ClinicalStudyViewModel;
  researchCenterId?: number;
  researchCenter?: ResearchCenterViewModel;
  mainResearcherId?: number;
  mainResearcher?: ResearcherViewModel;
  subResearchers?: ResearcherViewModel[];
  managers?: ManagerViewModel[];
  cepApprovalDate?: string;
  recruitingStartDate?: string;
  recruitingEndDate?: string;
  sivDate?: string;
  eosDate?: string;
  randPromisedNumber?: number;
  screenPromisedNumber?: number;
  patients?: PatientViewModel[];
  createdAt?: string;
  updatedAt?: string;
};

export type ProcedureViewModel = {
  id: number;
  name?: string;
  identifier?: string;
  equipments: EquipmentViewModel[];
  type: 'DATA' | 'MATERIAL';
  createdAt?: string;
  updatedAt?: string;
};

export type StudyPatientViewModel = {
  id: number;
  studyStartDate?: string;
  events: EventViewModel[];
  tcle?: string;
  tcleDate?: string;
  patientId: number;
  patient: PatientViewModel;
  hasPendingSchedule: boolean;
  researchCenterStudyId: number;
  researchCenterStudy: ResearchCenterStudyViewModel;
  randomized: boolean;
  passedRandomization: Boolean;
  createdAt?: string;
  updatedAt?: string;
};

export type EventVisitViewModel = {
  id: number;
  eventId: number;
  event: EventViewModel;
  visitId: number;
  visit: VisitViewModel;
  successProcedures?: string;
  unsuccessProcedures?: string;
  createdAt?: string;
  updatedAt?: string;
};

export type EventViewModel = {
  id: number;
  title?: string;
  start: string;
  end: string;
  researchCenterStudyId: number;
  researchCenterStudy: ResearchCenterStudyViewModel;
  agendaId: number;
  agenda: AgendaViewModel;
  createdAt?: string;
  updatedAt?: string;
  studyPatient: StudyPatientViewModel;
  studyPatientId: number;
  eventVisit: EventVisitViewModel;
  eventVisitId: number;
  finished: boolean;
  finishedAt?: string;
  unproductive: boolean;
  pending: boolean;
  rescheduled: boolean;
};

export type AgendaViewModel = {
  id: number;
  name: string;
  researcherId?: number;
  researcher?: ResearcherViewModel;
  events: EventViewModel[];
  createdAt?: string;
  updatedAt?: string;
};

export type AttachmentViewModel = {
  id: number;
  fileName: string;
  uuid: string;
  provider: string;
  researchCenterStudy: ResearchCenterStudyViewModel;
  researchCenterStudyId: number;
  visitId: number;
  visit: VisitViewModel;
  createdAt: string;
};

export type DashboardViewModel = {
  newPatients: StudyPatientViewModel[];
  randomizedPatients: StudyPatientViewModel[];
  screenFailurePatients: StudyPatientViewModel[];
  screenPatients: StudyPatientViewModel[];
  researchCenterStudies: {
    studyName: string | null | undefined;
    studyAcronym: string | null | undefined;
    randPromisedNumber: number | null | undefined;
    screenPromisedNumber: number | null | undefined;
    researchCenterStudyId: number;
    newPatients: StudyPatientViewModel[];
    screenFailurePatients: StudyPatientViewModel[];
    screenPatients: StudyPatientViewModel[];
    randomizedPatients: StudyPatientViewModel[];
  }[];
  pagination: {
    page: number;
    limit: number;
    totalItems: number;
    totalPages: number;
  };
};
