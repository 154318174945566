import {
  Box,
  IconButton,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import { PanelWrapper } from '../../components/shared/PanelWrapper/PannelWrapper';
import { FilterContainer } from '../../components/shared/Filter/FilterContainer';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { useQuery } from '../../utils/hooks/useQuery';
import { EventViewModel } from '../../types/models';
import { useState } from 'react';
import { DeleteConfirmationPopover } from '../../components/shared/Popover/DeleteConfirmationPopover';
import { toast } from 'react-toastify';
import { useApiCall } from '../../utils/hooks/useApiCall';
import { deleteClinicalStudy } from '../../services/clinicalStudiesServices';
import { useAuth } from '../../utils/hooks/useAuth';
import { format } from 'date-fns';
import { PendingEventModal } from '../../components/Researchers/PendingEventModal';

const columns = [
  { label: 'Paciente', key: 'acronym' },
  { label: 'Nome Visita', key: 'name' },
  { label: 'Proc Pendentes', key: 'studyId' },
  { label: 'Data Ult Visita', key: 'industry.name' },
  { label: 'Ações', key: 'actions' },
];

export const PendingPage = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [event, setEvent] = useState<EventViewModel | undefined>();
  const auth = useAuth();
  const [clinicalStudyLoading, setClinicalStudyLoading] = useState(false);
  const { data: pendingData, refetch } = useQuery<EventViewModel[]>(
    `/events/agenda/${auth.user?.people?.researcher?.[0]?.agenda?.[0]?.id}?pending=true`
  );
  const api = useApiCall();

  const handleClose = () => {
    setEvent(undefined);
    onClose();
  };

  const handleOpenEditModal = (usr: EventViewModel) => {
    setEvent(usr);
    onOpen();
  };

  const handleDelete = async (clinicalStudyId: number) => {
    try {
      setClinicalStudyLoading(true);
      await deleteClinicalStudy(clinicalStudyId, api);
      refetch();
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      setClinicalStudyLoading(false);
    }
  };

  return (
    <>
      <PendingEventModal
        eventId={event?.id}
        refetch={refetch}
        isOpen={isOpen}
        onClose={handleClose}
      />
      <PanelWrapper title="Estudos Clínicos">
        <FilterContainer onOpenModal={onOpen} />
        <Box className="rounded-lg shadow p-4 pt-10 mt-5">
          <Table>
            <Thead>
              <Tr>
                {columns.map((column) => (
                  <Th key={column.key}>{column.label}</Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {pendingData?.map((row) => (
                <Tr
                  key={row.id}
                  className="hover:bg-gray-50 hover:cursor-pointer"
                >
                  <Td>{row.studyPatient.patient.people.name}</Td>
                  <Td>{row.eventVisit?.visit.name}</Td>
                  <Td>
                    {
                      JSON.parse(row.eventVisit?.unsuccessProcedures ?? '[]')
                        .length
                    }
                  </Td>
                  <Td>{format(new Date(row.start), 'dd/MM/yyyy')}</Td>

                  <Td>
                    <div className="w-full flex justify-around">
                      {/* <DeleteConfirmationPopover
                        loading={clinicalStudyLoading}
                        onConfirm={() => handleDelete(row.id)}
                      > */}
                      <IconButton
                        disabled
                        colorScheme="red"
                        aria-label="Excluir"
                        icon={<DeleteIcon color="white" />}
                      />
                      {/* </DeleteConfirmationPopover> */}
                      <IconButton
                        onClick={() => {
                          handleOpenEditModal(row);
                        }}
                        colorScheme="blue"
                        aria-label="Editar"
                        icon={<EditIcon color="white" />}
                      />
                    </div>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      </PanelWrapper>
    </>
  );
};
