import {
  Box,
  Button,
  IconButton,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import { PanelWrapper } from '../../components/shared/PanelWrapper/PannelWrapper';
import { FilterContainer } from '../../components/shared/Filter/FilterContainer';
import { CloseIcon, EditIcon, DeleteIcon } from '@chakra-ui/icons';
import { useQuery } from '../../utils/hooks/useQuery';
import {
  EquipmentViewModel,
  EventViewModel,
  StudyPatientViewModel,
  VisitViewModel,
} from '../../types/models';
import { useState } from 'react';
import { EquipmentModal } from '../../components/Equipments/EquipmentModal';
import { DeleteConfirmationPopover } from '../../components/shared/Popover/DeleteConfirmationPopover';
import { toast } from 'react-toastify';
import { useApiCall } from '../../utils/hooks/useApiCall';
import { deleteEquipment } from '../../services/equipmentsServices';
import { differenceInDays, format } from 'date-fns';
import { NewEventModal } from '../../components/StudyPatients/NewEventModal';
import { modifyStudyPatient } from '../../services/studyPatientsServices';
import { ConfirmationPopover } from '../../components/shared/Popover/ConfirmationPopover';
import {
  getNextSuggestedVisit,
  VisitSuggestion,
} from '../../services/visitsServices';
import { SuggestVisitModal } from '../../components/Researchers/SuggestVisitModal';

const columns = [
  { label: 'Nome Paciente', key: 'acronym' },
  { label: 'Últ. Visita', key: 'studyId' },
  { label: 'Data Últ Visita', key: 'name' },
  { label: 'Dias restantes Próx Visita', key: 'industry.name' },
  { label: 'Ações', key: 'actions' },
];

export interface StudyPatientPendingScheduleProps
  extends StudyPatientViewModel {
  nextVisit: VisitViewModel;
  bestSuggestion: string;
  startSuggestion: string;
  endSuggestion: string;
}

export const PatientSchedulePendingPage = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [event, setEvent] = useState<EventViewModel | undefined>();
  const [studyPatient, setStudyPatient] = useState();
  const [suggested, setSuggested] = useState<
    StudyPatientPendingScheduleProps | undefined
  >();
  const [nextSuggestedPatientVisit, setNextSuggestedPatientVisit] = useState<
    VisitSuggestion | undefined
  >();
  const [equipmentLoading, setEquipmentLoading] = useState(false);
  const { data: pendingData, refetch } = useQuery<
    StudyPatientPendingScheduleProps[]
  >('/study-patients/pending-schedule');
  const {
    isOpen: isOpenSuggestion,
    onOpen: onOpenSuggestion,
    onClose: onCloseSuggestion,
  } = useDisclosure();
  const api = useApiCall();

  const handleClose = () => {
    onCloseSuggestion();
    setEvent(undefined);
    onClose();
  };

  const handleDelete = async (equipmentId: number) => {
    try {
      setEquipmentLoading(true);
      await deleteEquipment(equipmentId, api);
      refetch();
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      setEquipmentLoading(false);
    }
  };

  const markAsSolved = async (id: number) => {
    try {
      await modifyStudyPatient(id, { hasPendingSchedule: false }, api);
      refetch();
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  const handleOpenEditModal = (row: any) => {
    setSuggested(row);
    setStudyPatient(row);
    onOpen();
  };

  const handleRemoveFromStudy = async (
    row: StudyPatientPendingScheduleProps
  ) => {
    const localEvent = row.events[0];
    await markAsSolved(localEvent.studyPatientId);
    await modifyStudyPatient(
      localEvent.studyPatientId,
      {
        randomized: false,
      },
      api
    );
  };

  const handleNewSchedule = async (event: EventViewModel) => {
    const nextSuggestedDate = await getNextSuggestedVisit(
      event.eventVisit.visit.clinicalStudyId ?? 0,
      event.eventVisit.visitId,
      event.studyPatientId,
      event.agendaId,
      api
    );

    if (!nextSuggestedDate.nextVisit) {
      toast.error('Nenhuma visita disponivel');
      return;
    }
    console.log(nextSuggestedDate);
    alert();
    setEvent(event);

    setNextSuggestedPatientVisit(nextSuggestedDate);

    onOpenSuggestion();
  };

  return (
    <>
      <SuggestVisitModal
        agendaId={event?.agendaId ?? 0}
        studyPatient={event?.studyPatient}
        isOpen={isOpenSuggestion}
        onClose={handleClose}
        refetch={refetch}
        nextVisitSuggestion={nextSuggestedPatientVisit}
      />
      <NewEventModal
        isOpen={isOpen}
        visitId={suggested?.nextVisit.id}
        onClose={onClose}
        studyPatient={studyPatient}
        refetch={refetch}
        startSuggestion={suggested?.startSuggestion}
        endSuggestion={suggested?.endSuggestion}
      />
      <PanelWrapper title="Pacientes com Pendencia de Agendamento">
        <FilterContainer onOpenModal={onOpen} />
        <Box className="rounded-lg shadow p-4 pt-10 mt-5">
          <Table>
            <Thead>
              <Tr>
                {columns.map((column) => (
                  <Th key={column.key}>{column.label}</Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {pendingData?.map((row) => (
                <Tr
                  key={row.id}
                  className={
                    'hover:cursor-pointer ' + row.randomized
                      ? 'bg-red-300 hover:bg-red-200'
                      : 'hover:bg-gray-50'
                  }
                >
                  <Td>{row.patient.people.name}</Td>
                  <Td>{row.events[0]?.eventVisit.visit.name}</Td>
                  <Td>
                    {row.events.length
                      ? format(
                          new Date(row.events[0]?.start ?? 0),
                          'dd/MM/yyyy HH:mm'
                        )
                      : undefined}
                  </Td>
                  <Td>
                    {row.events.length
                      ? differenceInDays(
                          new Date(row.bestSuggestion),
                          new Date()
                        )
                      : undefined}
                  </Td>
                  {row.randomized ? (
                    <Td>
                      <div className="w-full flex justify-around">
                        <ConfirmationPopover
                          confirmationTitle="Reagendar"
                          confirmationText="Tem certeza de que deseja reagendar esta visita?"
                          triggerText="Reagendar"
                        >
                          <Button
                            isLoading={equipmentLoading}
                            onClick={() => handleNewSchedule(row.events[0])}
                          >
                            Confirmar
                          </Button>
                        </ConfirmationPopover>
                        <DeleteConfirmationPopover
                          loading={equipmentLoading}
                          onConfirm={() => handleRemoveFromStudy(row)}
                        >
                          <IconButton
                            colorScheme="red"
                            aria-label="Remover"
                            icon={<CloseIcon color="white" />}
                          />
                        </DeleteConfirmationPopover>
                      </div>
                    </Td>
                  ) : (
                    <Td>
                      <div className="w-full flex justify-around">
                        <ConfirmationPopover
                          triggerText="Resolvido"
                          confirmationTitle="Resolvido"
                          confirmationText="Marcar como resolvido?"
                        >
                          <Button
                            onClick={() => markAsSolved(row.id)}
                            colorScheme="blue"
                            aria-label="Editar"
                          >
                            Resolvido
                          </Button>
                        </ConfirmationPopover>
                        <DeleteConfirmationPopover
                          loading={equipmentLoading}
                          onConfirm={() => handleDelete(row.id)}
                        >
                          <IconButton
                            colorScheme="red"
                            aria-label="Excluir"
                            icon={<DeleteIcon color="white" />}
                          />
                        </DeleteConfirmationPopover>
                        <IconButton
                          onClick={() => handleOpenEditModal(row)}
                          colorScheme="blue"
                          aria-label="Editar"
                          icon={<EditIcon color="white" />}
                        />
                      </div>
                    </Td>
                  )}
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      </PanelWrapper>
    </>
  );
};
