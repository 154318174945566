import { PanelWrapper } from '../../components/shared/PanelWrapper/PannelWrapper';
import Chart from 'react-apexcharts';
import { generateRadialBaseChart } from '../../utils/configs/apexConfig';
import {
  Divider,
  StatGroup,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { DashboardViewModel } from '../../types/models';
import { useAuth } from '../../utils/hooks/useAuth';
import { PacientStats } from '../../components/Dashboard/PacientsStats';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { useQuery } from '../../utils/hooks/useQuery';
import DashboardRow from '../../components/Dashboard/DashboardRow';

export const DashboardPage = () => {
  const { user } = useAuth();
  const [page, setPage] = useState(1);
  const limit = 2;
  const { data: dashboardData, setSearch } = useQuery<DashboardViewModel>(
    `/dashboard/data/${user?.researchCenterId}`
  );

  const columns = [
    { label: 'Realizado Mensal', key: 'accomplishedMontly' },
    { label: 'Realizado Total', key: 'accomplishedTotal' },
    { label: 'Prometido Total', key: 'promissedTotal' },
  ];

  const radialBaseOptions1 = generateRadialBaseChart('Tx Screen Falliure'); // Tx Screen Falliure
  const radialBaseOptions2 = generateRadialBaseChart('Tx de Rand'); // Tx Screen Falliure

  useEffect(() => {
    setSearch(
      `${limit ? `limit=${limit}&` : ''}${page ? `page=${page}&` : ''}`
    );
  }, [page]);

  return (
    <div>
      <PanelWrapper
        title="Dashboard"
        subtitle={user?.researchCenter?.people.name}
      >
        <div>
          <StatGroup>
            <PacientStats
              label={'Novos Pacientes'}
              pacients={dashboardData?.newPatients}
            />
            <PacientStats
              label={'Pacientes Em Triagem'}
              pacients={dashboardData?.screenPatients}
            />
            <PacientStats
              label={'Screen Failure'}
              pacients={dashboardData?.screenFailurePatients}
            />
            <PacientStats
              label={'Pacientes Randomizados'}
              pacients={dashboardData?.randomizedPatients}
            />
          </StatGroup>
        </div>
        <Divider marginY={5} />

        <div className="flex items-center gap-5">
          {dashboardData && (
            <ChevronLeftIcon
              onClick={() => (page > 1 ? setPage(page - 1) : null)}
              className="cursor-pointer"
              boxSize={9}
            />
          )}
          <div className="w-full">
            {dashboardData?.researchCenterStudies?.map((st) => (
              <div key={st.studyName}>
                <div className="w-full grid grid-cols-6">
                  <div className="w-full  h-[43vh] justify-center mx-auto flex-col flex gap-20 col-span-5 ">
                    <TableContainer overflowY="scroll">
                      <Table variant="striped">
                        <Thead>
                          <Tr>
                            <Th fontSize="1xl">
                              {st.studyAcronym} - {st.studyName}
                            </Th>
                            {columns.map((column) => (
                              <Th isNumeric key={column.key}>
                                {column.label}
                              </Th>
                            ))}
                          </Tr>
                        </Thead>
                        <Tbody>
                          <DashboardRow
                            firstCell="Novos Pacientes"
                            array1={st.newPatients}
                          />
                          <DashboardRow
                            firstCell="Pacientes Screen"
                            array1={st.screenPatients}
                            lastCell={st.screenPromisedNumber ?? 0}
                          />

                          <DashboardRow
                            firstCell="Screen Failure"
                            array1={st.screenFailurePatients}
                          />
                          <DashboardRow
                            firstCell="Taxa de Screen Failure"
                            array1={st.screenFailurePatients}
                            array2={st.screenPatients}
                          />

                          <DashboardRow
                            firstCell="Pacientes Randomizados"
                            array1={st.randomizedPatients}
                            lastCell={st.randPromisedNumber ?? 0}
                          />
                          <DashboardRow
                            firstCell="Taxa de Randomização"
                            array1={st.randomizedPatients}
                            array2={st.screenPatients}
                          />

                          <DashboardRow
                            firstCell="Pacientes a Revisar"
                            array1={st.newPatients}
                            array2={st.screenFailurePatients}
                            array3={st.randomizedPatients}
                          />
                        </Tbody>
                      </Table>
                    </TableContainer>
                  </div>
                  <div className="flex flex-col justify-between">
                    <Chart
                      options={radialBaseOptions1}
                      series={[
                        Number(
                          (st?.screenFailurePatients.length
                            ? (st?.screenPatients.length /
                                st?.screenFailurePatients.length) *
                              100
                            : 0
                          ).toFixed(1)
                        ),
                      ]}
                      type="radialBar"
                      width="230"
                    />
                    <Chart
                      options={radialBaseOptions2}
                      series={[
                        Number(
                          (st?.screenPatients.length
                            ? (st?.randomizedPatients.length /
                                st?.screenPatients.length) *
                              100
                            : 0
                          ).toFixed(1)
                        ),
                      ]}
                      type="radialBar"
                      width="230"
                    />
                  </div>
                </div>
                <Divider marginY={5} />
              </div>
            ))}
          </div>

          {dashboardData && (
            <ChevronRightIcon
              onClick={() =>
                page < dashboardData.pagination.totalPages
                  ? setPage(page + 1)
                  : null
              }
              className="cursor-pointer"
              boxSize={9}
            />
          )}
        </div>
      </PanelWrapper>
    </div>
  );
};
