import { PeopleViewModel, ResearchCenterViewModel } from '../../types/models';
import { useForm } from '../../utils/hooks/useForm';
import { MainModal } from '../shared/Modal/MainModal';
import { FormSection } from '../shared/Form/FormSection';
import { LabeledInput } from '../shared/Form/LabeledInput';
import { SelectInput } from '../shared/Form/SelectInput';
import { useEffect, useMemo, useState } from 'react';
import { useApiCall } from '../../utils/hooks/useApiCall';
import { toast } from 'react-toastify';
import { useQuery } from '../../utils/hooks/useQuery';
import {
  createResearchCenter,
  modifyResearchCenter,
} from '../../services/researchCentersServices';

interface ResearchCenterModalProps {
  isOpen: boolean;
  onClose: () => void;
  refetch: () => void;
  researchCenter?: ResearchCenterViewModel;
}

const defaultForm: Partial<ResearchCenterViewModel> = {
  id: undefined,
  peopleId: undefined,
  cnes: '',
  centerType: undefined,
  backupHospitalId: undefined,
};

const certerTypeOptions = [
  { value: 'MAIN', label: 'Principal' },
  { value: 'SATELLITE', label: 'Satélite' },
];

export const ResearchCenterModal = ({
  isOpen,
  onClose,
  refetch,
  researchCenter,
}: ResearchCenterModalProps) => {
  const [loading, setLoading] = useState(false);
  const { formData, onFormChange, onSelectFormChange, setFormData } =
    useForm(defaultForm);
  const api = useApiCall();

  const { data: peopleData } = useQuery<PeopleViewModel[]>('/people');

  const formattedOptions = useMemo(
    () =>
      peopleData?.map((ppl) => ({
        value: String(ppl.id),
        label: ppl.name,
      })),
    [peopleData]
  );

  useEffect(() => {
    if (researchCenter) {
      setFormData({
        ...researchCenter,
      });
    } else {
      setFormData(defaultForm);
    }
  }, [researchCenter, setFormData]);

  const handleSubmit = async () => {
    const errors = [];

    //@ts-ignore
    if (!formData.peopleId || formData.peopleId === 'SELECIONE')
      errors.push('É necessário selecionar uma pessoa');
    if (!formData.cnes) errors.push('O campo CNES é obrigatório');
    //@ts-ignore
    if (formData.centerType === 'SELECIONE')
      errors.push('O campo TIPO é obrigatório');

    formData.peopleId = Number(formData.peopleId);
    formData.backupHospitalId = formData.backupHospitalId
      ? Number(formData.backupHospitalId)
      : undefined;
    if (errors.length) return errors.forEach((error) => toast.error(error));
    setLoading(true);
    try {
      if (researchCenter) {
        const id = formData.id ?? 0;
        delete formData.id;
        delete formData.createdAt;
        formData.updatedAt = new Date().toISOString();
        await modifyResearchCenter(id, formData, api);
      } else {
        await createResearchCenter(formData, api);
      }

      setLoading(false);
      refetch();
      onClose();
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };
  return (
    <MainModal
      title="Criar Centro de Pesquisa"
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={handleSubmit}
      loading={loading}
    >
      <div>
        <FormSection title="Informações do Centro de Pesquisa">
          <SelectInput
            value={String(formData.peopleId) ?? ''}
            id="peopleId"
            options={formattedOptions ?? []}
            onChange={onSelectFormChange}
            label="Pessoa"
          />
          <LabeledInput
            value={formData.cnes ?? ''}
            id="cnes"
            label="CNES"
            onChange={onFormChange}
          />

          <SelectInput
            value={formData.centerType ?? ''}
            id="centerType"
            options={certerTypeOptions}
            onChange={onSelectFormChange}
            label="Tipo do Centro de Pesquisa"
          />
        </FormSection>
        <FormSection title="Informações de contato">
          <SelectInput
            value={String(formData.backupHospitalId) ?? ''}
            id="backupHospitalId"
            options={formattedOptions ?? []}
            onChange={onSelectFormChange}
            label="Hospital de Retaguarda"
          />
        </FormSection>
      </div>
    </MainModal>
  );
};
