import {
  Box,
  IconButton,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import { PanelWrapper } from '../../components/shared/PanelWrapper/PannelWrapper';
import { FilterContainer } from '../../components/shared/Filter/FilterContainer';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { useQuery } from '../../utils/hooks/useQuery';
import { EquipmentViewModel } from '../../types/models';
import { useState } from 'react';
import { EquipmentModal } from '../../components/Equipments/EquipmentModal';
import { DeleteConfirmationPopover } from '../../components/shared/Popover/DeleteConfirmationPopover';
import { toast } from 'react-toastify';
import { useApiCall } from '../../utils/hooks/useApiCall';
import { deleteEquipment } from '../../services/equipmentsServices';
import { format } from 'date-fns';

const columns = [
  { label: 'Nome', key: 'acronym' },
  { label: 'Marca', key: 'studyId' },
  { label: 'Patrimonio', key: 'name' },
  { label: 'Data Vencimento', key: 'industry.name' },
  { label: 'Ações', key: 'actions' },
];

export const EquipmentsPage = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [equipmentData, setEquipment] = useState<
    EquipmentViewModel | undefined
  >();
  const [equipmentLoading, setEquipmentLoading] = useState(false);
  const { data: equipments, refetch } =
    useQuery<EquipmentViewModel[]>('/equipments');
  const api = useApiCall();

  const handleClose = () => {
    setEquipment(undefined);
    onClose();
  };

  const handleOpenEditModal = (usr: EquipmentViewModel) => {
    setEquipment(usr);
    onOpen();
  };

  const handleDelete = async (equipmentId: number) => {
    try {
      setEquipmentLoading(true);
      await deleteEquipment(equipmentId, api);
      refetch();
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      setEquipmentLoading(false);
    }
  };

  return (
    <>
      <EquipmentModal
        equipment={equipmentData}
        refetch={refetch}
        isOpen={isOpen}
        onClose={handleClose}
      />
      <PanelWrapper title="Equipamentos">
        <FilterContainer onOpenModal={onOpen} />
        <Box className="rounded-lg shadow p-4 pt-10 mt-5">
          <Table>
            <Thead>
              <Tr>
                {columns.map((column) => (
                  <Th key={column.key}>{column.label}</Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {equipments?.map((row) => (
                <Tr
                  key={row.id}
                  className="hover:bg-gray-50 hover:cursor-pointer"
                >
                  <Td>{row.name}</Td>
                  <Td>{row.brand}</Td>
                  <Td>{row.patrimonyCode}</Td>
                  <Td>
                    {row.validationDueDate
                      ? format(new Date(row.validationDueDate), 'dd/MM/yyyy')
                      : 'N/A'}
                  </Td>

                  <Td>
                    <div className="w-full flex justify-around">
                      <DeleteConfirmationPopover
                        loading={equipmentLoading}
                        onConfirm={() => handleDelete(row.id)}
                      >
                        <IconButton
                          colorScheme="red"
                          aria-label="Excluir"
                          icon={<DeleteIcon color="white" />}
                        />
                      </DeleteConfirmationPopover>
                      <IconButton
                        onClick={() => handleOpenEditModal(row)}
                        colorScheme="blue"
                        aria-label="Editar"
                        icon={<EditIcon color="white" />}
                      />
                    </div>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      </PanelWrapper>
    </>
  );
};
