import { toast } from 'react-toastify';
import { VisitViewModel } from '../types/models';
import { ApiCallContextProps } from '../utils/hooks/useApiCall';

export const createVisit = async (
  payload: Partial<VisitViewModel>,
  api: ApiCallContextProps
) => {
  try {
    await api.post?.('/visits', payload);
    toast.success('Visita Criada com sucesso');
  } catch (error) {
    throw new Error('Ocorreu um erro ao criar Visita');
  }
};

export const modifyVisit = async (
  id: number,
  payload: Partial<VisitViewModel>,
  api: ApiCallContextProps
) => {
  try {
    await api.put?.(`/visits/${id}`, payload);
    toast.success('Visita Editado com sucesso');
  } catch (error) {
    throw new Error('Ocorreu um erro ao editar Visita');
  }
};

export const deleteVisit = async (id: number, api: ApiCallContextProps) => {
  try {
    await api.del?.(`/visits/${id}`);
    toast.success('Visita excluída com sucesso');
  } catch (error) {
    throw new Error('Ocorreu um erro ao excluir Visita');
  }
};

export const getManyVisit = async (api: ApiCallContextProps) => {
  try {
    return await api.get?.(`/visits`);
  } catch (error) {
    throw new Error('Ocorreu um erro ao recuperar informações');
  }
};

export const getOneVisit = async (id: number, api: ApiCallContextProps) => {
  try {
    return await api.get<VisitViewModel>?.(`/visits/${id}`);
  } catch (error) {
    throw new Error('Ocorreu um erro ao recuperar informações');
  }
};
export type VisitSuggestion = {
  nextVisit: VisitViewModel;
  startSuggestion: string;
  endSuggestion: string;
  bestSuggestion: string;
  firstOption: string;
};

export const getNextSuggestedVisit = async (
  clinicalStudyId: number,
  currentVisitId: number,
  studyPatientId: number,
  agendaId: number,
  api: ApiCallContextProps
) => {
  try {
    return await api.get<VisitSuggestion>?.(
      `visits/date-suggestions/${clinicalStudyId}/${currentVisitId}/${studyPatientId}/${agendaId}`
    );
  } catch (error) {
    throw new Error('Ocorreu um erro ao recuperar informações');
  }
};
