import { Box, Text } from '@chakra-ui/react';
import { ReactNode } from 'react';

interface FormSectionProps {
  title: string;
  children: ReactNode;
  boldTitle?: boolean;
}

export const FormSection = ({
  title,
  children,
  boldTitle,
}: FormSectionProps) => {
  return (
    <Box className="rounded-lg border border-x-slate-200 p-1 my-4">
      <Text
        className={`text-slate-600 -mt-[18px] bg-white w-fit ml-2 ${boldTitle ? 'font-bold' : ''}`}
      >
        {title}
      </Text>
      <Box className="p-2">{children}</Box>
    </Box>
  );
};
