import { ChangeEvent, useState } from 'react';

export function useForm<T>(defaultForm: T) {
  const [formData, setFormData] = useState<T>(defaultForm);

  const onFormChange = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const id = target.id;
    let value: string | boolean | number;
    switch (target.type) {
      case 'checkbox':
        value = target.checked;
        break;
      case 'number':
        value = Number(target.value);
        break;
      default:
        value = target.value;
        break;
    }
    setFormData({ ...formData, [id]: value });
  };

  const onSelectFormChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const { id, value } = event.target;
    setFormData({ ...formData, [id]: value });
  };

  const resetForm = () => {
    setFormData(defaultForm);
  };

  return { onFormChange, formData, onSelectFormChange, setFormData, resetForm };
}
