import { DownloadIcon } from '@chakra-ui/icons';
import { Button, Tooltip } from '@chakra-ui/react';
import React, { useState } from 'react'
import { useApiCall } from '../../utils/hooks/useApiCall';
import { toast } from 'react-toastify';
import { getPdfsFromEvents } from '../../services/eventsServices';
import { EventViewModel } from '../../types/models';
import { handleDownload } from '../../utils/helpers/helpers';

const DownloadDayCell = ({events}: any) => {
    const api = useApiCall();

    const [loading, setLoading] = useState(false);
  
    const handleDownloadTemplates = async () => {
      setLoading(true)
      try {
        const pdfBuffer = await getPdfsFromEvents(events,api);
        if (!pdfBuffer) {
          toast.warning("Nenhum documento encontrado!")
          return 
        }
  
        handleDownload(pdfBuffer, "ArquivosVisita.pdf")
        toast.success("Documentos baixados com sucesso!")
  
      } catch (error) {
        console.error(error);
        toast.error((error as Error).message);
      } finally {
        setLoading(false)
      }
    }
  return (
    <>
        <Tooltip label='Baixar documentos do dia'>
            <Button isLoading={loading} onClick={handleDownloadTemplates}>
            <DownloadIcon /> 
            </Button>
        </Tooltip>
    </>
  )
}

export default DownloadDayCell