import {
  PeopleViewModel,
  ResearchCenterViewModel,
  ResearcherViewModel,
} from '../../types/models';
import { useForm } from '../../utils/hooks/useForm';
import { MainModal } from '../shared/Modal/MainModal';
import { FormSection } from '../shared/Form/FormSection';
import { LabeledInput } from '../shared/Form/LabeledInput';
import { SelectInput } from '../shared/Form/SelectInput';
import { useEffect, useMemo, useState } from 'react';
import { useApiCall } from '../../utils/hooks/useApiCall';
import { toast } from 'react-toastify';
import { useQuery } from '../../utils/hooks/useQuery';
import {
  createResearcher,
  modifyResearcher,
} from '../../services/researchersServices';
import { formatSelectData } from '../../utils/helpers/helpers';

interface ResearcherModalProps {
  isOpen: boolean;
  onClose: () => void;
  refetch: () => void;
  researcher?: ResearcherViewModel;
}

const defaultForm: Partial<ResearcherViewModel> = {
  id: undefined,
  peopleId: undefined,
  crm: '',
};

export const ResearcherModal = ({
  isOpen,
  onClose,
  refetch,
  researcher,
}: ResearcherModalProps) => {
  const [loading, setLoading] = useState(false);
  const { formData, onFormChange, onSelectFormChange, setFormData } =
    useForm(defaultForm);
  const api = useApiCall();

  const { data: researchCenterData } =
    useQuery<ResearchCenterViewModel[]>('/research-centers');

  const { data: peopleData } = useQuery<PeopleViewModel[]>('/people');

  const formattedOptions = useMemo(
    () =>
      peopleData?.map((ppl) => ({
        value: String(ppl.id),
        label: ppl.name,
      })),
    [peopleData]
  );

  useEffect(() => {
    if (researcher) {
      setFormData({
        ...researcher,
      });
    } else {
      setFormData(defaultForm);
    }
  }, [researcher, setFormData]);

  const handleSubmit = async () => {
    const errors = [];

    //@ts-ignore
    if (!formData.peopleId || formData.peopleId === 'SELECIONE')
      errors.push('É necessário selecionar uma pessoa');
    if (!formData.crm) errors.push('O campo CRM é obrigatório');
    //@ts-ignore
    if (formData.centerType === 'SELECIONE')
      errors.push('O campo TIPO é obrigatório');

    formData.peopleId = Number(formData.peopleId);

    if (errors.length) return errors.forEach((error) => toast.error(error));
    setLoading(true);
    try {
      formData.researchCenterId = Number(formData.researchCenterId);
      if (researcher) {
        const id = formData.id ?? 0;
        delete formData.id;
        delete formData.createdAt;
        formData.updatedAt = new Date().toISOString();
        await modifyResearcher(id, formData, api);
      } else {
        await createResearcher(formData, api);
      }

      setLoading(false);
      refetch();
      onClose();
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };
  return (
    <MainModal
      title="Criar Investigador"
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={handleSubmit}
      loading={loading}
    >
      <div>
        <FormSection title="Informações do Centro de Pesquisa">
          <SelectInput
            value={String(formData.researchCenterId) ?? ''}
            onChange={onSelectFormChange}
            label="Centro de Pesquisa"
            id="researchCenterId"
            options={formatSelectData(researchCenterData ?? [])}
          />
        </FormSection>
        <FormSection title="Informações do Investigador">
          <SelectInput
            value={String(formData.peopleId) ?? ''}
            id="peopleId"
            options={formattedOptions ?? []}
            onChange={onSelectFormChange}
            label="Pessoa"
          />
          <LabeledInput
            value={formData.crm ?? ''}
            id="crm"
            label="CRM"
            onChange={onFormChange}
          />
        </FormSection>
      </div>
    </MainModal>
  );
};
