import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import {
  AgendaViewModel,
  EventViewModel,
  StudyPatientViewModel,
} from '../../types/models';
import { formatSelectData } from '../../utils/helpers/helpers';
import { useForm } from '../../utils/hooks/useForm';
import { useQuery } from '../../utils/hooks/useQuery';
import { FormSection } from '../shared/Form/FormSection';
import { LabeledInput } from '../shared/Form/LabeledInput';
import { SelectInput } from '../shared/Form/SelectInput';
import { MainModal } from '../shared/Modal/MainModal';
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { getAgendaEvents } from '../../services/agendasServices';
import { useApiCall } from '../../utils/hooks/useApiCall';
import { Box, Button } from '@chakra-ui/react';
import { add, format } from 'date-fns';
import { createEvent, modifyEvent } from '../../services/eventsServices';
import { toast } from 'react-toastify';
import { StudyPatientPendingScheduleProps } from '../../pages/admin/PatientSchedulePendingPage';
import { modifyStudyPatient } from '../../services/studyPatientsServices';

interface CustomEventFormProps extends Partial<EventViewModel> {
  visitId?: number;
}

const defaultForm: CustomEventFormProps = {
  agendaId: undefined,
  studyPatientId: undefined,
  start: '',
  end: '',
};

interface NewEventModalProps {
  onClose: () => void;
  isOpen: boolean;
  studyPatient?: StudyPatientViewModel | StudyPatientPendingScheduleProps;
  refetch: () => void;
  eventId?: number;
  visitId?: number;
  startSuggestion?: string;
  endSuggestion?: string;
}

export const NewEventModal = ({
  onClose,
  isOpen,
  studyPatient,
  refetch,
  eventId,
  visitId,
  startSuggestion,
  endSuggestion,
}: NewEventModalProps) => {
  const { formData, onSelectFormChange, onFormChange, resetForm, setFormData } =
    useForm(defaultForm);
  const [agenda, setAgenda] = useState<string | undefined>();
  const [patient, setPatient] = useState<string | undefined>();
  const [duration, setDuration] = useState(30);
  const [loadingEvents, setLoadingEvents] = useState(false);
  const [events, setEvents] = useState<EventViewModel[]>();
  const { data: researchersAgendaData } = useQuery<AgendaViewModel[]>(
    `/agendas/research-center-study/${studyPatient?.researchCenterStudyId ?? 1}`
  );

  const { data: studyVisitsData } = useQuery<AgendaViewModel[]>(
    `/visits/research-center-study/${studyPatient?.researchCenterStudyId ?? 1}`
  );

  const calendarRef = useRef(null);

  const { data: studyPatientData } =
    useQuery<StudyPatientViewModel[]>('/study-patients');

  const [step, setStep] = useState(1);

  useEffect(() => {
    if (
      studyPatient &&
      formData.studyPatientId !== studyPatient.id &&
      !visitId
    ) {
      setFormData({ ...formData, studyPatientId: studyPatient.id });
    } else if (
      studyPatient &&
      formData.studyPatientId !== studyPatient.id &&
      visitId
    ) {
      setFormData({
        ...formData,
        studyPatientId: studyPatient.id,
        visitId,
        title: `${studyVisitsData?.find((studyVisit) => studyVisit.id === visitId)?.name} - ${studyPatient.patient.people.name}`,
      });
    }
  }, [studyPatient, setFormData, formData, visitId, studyVisitsData]);

  const api = useApiCall();

  const handleSelectAgenda = (event: ChangeEvent<HTMLSelectElement>) => {
    if (event.target.value === 'SELECT') return;
    setAgenda(event.target.value);
    onSelectFormChange(event);
  };

  const handleSelectPatient = (event: ChangeEvent<HTMLSelectElement>) => {
    if (event.target.value === 'SELECT') return;
    setPatient(event.target.value);
    onSelectFormChange(event);
  };

  const handleGetAgendaEvents = useCallback(async () => {
    setLoadingEvents(true);
    try {
      if (agenda) {
        const data = await getAgendaEvents(Number(agenda), api);
        setEvents(data?.events);
      }
    } catch (error) {
    } finally {
      setLoadingEvents(false);
    }
  }, [agenda, api]);

  useEffect(() => {
    if (agenda) {
      handleGetAgendaEvents();
    }
  }, [agenda, handleGetAgendaEvents]);

  const handleClose = () => {
    setAgenda(undefined);
    setEvents(undefined);
    resetForm();
    setStep(1);
    onClose();
  };

  const handleSelectDate = (event: any) => {
    if (startSuggestion && event.date < new Date(startSuggestion)) {
      alert('Data inválida, menor que janela da visita');
      return;
    }
    if (endSuggestion && event.date > new Date(endSuggestion)) {
      alert('Data inválida, maior que janela da visita');
      return;
    }
    setFormData({
      ...formData,
      start: format(new Date(event.date), "yyyy-MM-dd'T'HH:mm"),
      end: format(
        add(new Date(event.date ?? 0), { minutes: duration }),
        "yyyy-MM-dd'T'HH:mm"
      ),
    });

    setStep(2);
  };

  const handleSubmit = async () => {
    try {
      const errors = [];
      if (!formData.title) errors.push('O campo título é obritatório');
      if (!formData.visitId) errors.push('É necessário selecionar uma visita');
      if (!formData.agendaId) errors.push('É necessário selecionar uma agenda');
      if (!formData.start || !formData.end)
        errors.push('É necessário selecionar uma data de início e fim');
      if (errors.length) return errors.forEach((error) => toast.error(error));
      formData.agendaId = Number(formData.agendaId);
      formData.visitId = Number(formData.visitId);
      formData.start = new Date(formData.start ?? 0).toISOString();
      formData.end = new Date(formData.end ?? 0).toISOString();
      if (studyPatient) formData.studyPatientId = Number(studyPatient?.id);
      if (studyPatient?.hasPendingSchedule) {
        await modifyStudyPatient(
          studyPatient.id,
          { hasPendingSchedule: false },
          api
        );
      }
      if (eventId) {
        formData.pending = false;
        formData.rescheduled = true;
        formData.finished = false;
        formData.finishedAt = undefined;
        await modifyEvent(eventId, formData, api);
      } else {
        await createEvent(formData, api);
      }

      handleClose();
      resetForm();
      refetch();
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  return (
    <>
      <MainModal
        title="Criar Evento"
        zIndex={300}
        isOpen={isOpen}
        onClose={handleClose}
        onConfirm={() => {}}
        loading={false}
        hideConfirmButton
      >
        <div>
          <FormSection title="Informações do Investigador">
            <SelectInput
              value={String(formData.agendaId) ?? ''}
              onChange={handleSelectAgenda}
              label="Agenda Investigador"
              id="agendaId"
              options={formatSelectData(researchersAgendaData ?? [], true)}
            />
          </FormSection>

          <FormSection title="Informações do Paciente">
            <SelectInput
              disabled={Boolean(studyPatient)}
              value={String(formData.studyPatientId) ?? ''}
              onChange={handleSelectPatient}
              label="Paciente"
              id="studyPatientId"
              options={formatSelectData(
                studyPatientData?.map((studyPatient) => ({
                  id: studyPatient.id,
                  name: studyPatient.patient.people.name,
                })) ?? [],
                true
              )}
            />
          </FormSection>

          {agenda &&
            (studyPatient || patient) &&
            agenda !== 'SELECIONE' &&
            patient !== 'SELECIONE' && (
              <FormSection title="Agenda">
                {!loadingEvents ? (
                  <>
                    {step === 1 ? (
                      <FullCalendar
                        locale="pt-Br"
                        initialView={
                          startSuggestion ? 'timeGridWeek' : 'timeGridDay'
                        }
                        initialDate={
                          // @ts-ignore
                          studyPatient?.bestSuggestion
                            ? // @ts-ignore
                              new Date(studyPatient.bestSuggestion) < new Date()
                              ? new Date()
                              : //@ts-ignore
                                new Date(studyPatient.bestSuggestion)
                            : new Date()
                        }
                        plugins={[
                          dayGridPlugin,
                          timeGridPlugin,
                          interactionPlugin,
                        ]}
                        ref={calendarRef}
                        events={events as any}
                        dateClick={handleSelectDate}
                      />
                    ) : (
                      <>
                        <LabeledInput
                          id="title"
                          label="Título"
                          value={formData.title ?? ''}
                          onChange={onFormChange}
                        />
                        <LabeledInput
                          id="start"
                          label="Data Início"
                          type="datetime-local"
                          value={formData.start ?? ''}
                          onChange={onFormChange}
                          disabled
                        />
                        <LabeledInput
                          id="end"
                          label="Data Fim"
                          type="datetime-local"
                          value={formData.end ?? ''}
                          onChange={onFormChange}
                          disabled
                        />
                        <FormSection title="Informações da Visita">
                          <SelectInput
                            value={String(formData.visitId) ?? ''}
                            onChange={handleSelectAgenda}
                            disabled={Boolean(visitId)}
                            label="Visita"
                            id="visitId"
                            options={formatSelectData(
                              studyVisitsData ?? [],
                              true
                            )}
                          />
                        </FormSection>
                        <Box className="w-full flex justify-between">
                          <Button onClick={() => setStep(1)}>Voltar</Button>
                          <Button colorScheme="blue" onClick={handleSubmit}>
                            Agendar
                          </Button>
                        </Box>
                      </>
                    )}
                  </>
                ) : (
                  <p>Carregando Eventos</p>
                )}
              </FormSection>
            )}
        </div>
      </MainModal>
    </>
  );
};
