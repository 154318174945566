import { toast } from 'react-toastify';
import { EventVisitViewModel, EventViewModel } from '../types/models';
import { ApiCallContextProps } from '../utils/hooks/useApiCall';

export const createEventVisit = async (
  payload: Partial<EventVisitViewModel>,
  api: ApiCallContextProps
) => {
  try {
    await api.post?.('/event-visits', payload);
    toast.success('EventVisit Criada com sucesso');
  } catch (error) {
    throw new Error('Ocorreu um erro ao criar EventVisit');
  }
};

export const modifyEventVisit = async (
  id: number,
  payload: Partial<EventVisitViewModel>,
  api: ApiCallContextProps
) => {
  try {
    await api.put?.(`/event-visits/${id}`, payload);
    toast.success('EventVisit Editada com sucesso');
  } catch (error) {
    throw new Error('Ocorreu um erro ao editar EventVisit');
  }
};

export const deleteEventVisit = async (id: number, api: ApiCallContextProps) => {
  try {
    await api.del?.(`/event-visits/${id}`);
    toast.success('EventVisit excluído com sucesso');
  } catch (error) {
    throw new Error('Ocorreu um erro ao excluir EventVisit');
  }
};

export const getManyEventVisit = async (api: ApiCallContextProps) => {
  try {
    await api.get?.(`/event-visits`);
  } catch (error) {
    throw new Error('Ocorreu um erro ao recuperar informações');
  }
};

export const getOneEventVisit = async (id: number, api: ApiCallContextProps) => {
  try {
    await api.get?.(`/event-visits/${id}`);
  } catch (error) {
    throw new Error('Ocorreu um erro ao recuperar informações');
  }
};

export const getOneEventVisitByResearcherId = async (
  id: number,
  api: ApiCallContextProps
) => {
  try {
    return await api.get<EventVisitViewModel>?.(`/event-visits/researcher/${id}`);
  } catch (error) {
    throw new Error('Ocorreu um erro ao recuperar informações');
  }
};
