import {
  Box,
  IconButton,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import { PanelWrapper } from '../../components/shared/PanelWrapper/PannelWrapper';
import { FilterContainer } from '../../components/shared/Filter/FilterContainer';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { useQuery } from '../../utils/hooks/useQuery';
import { ProcedureViewModel } from '../../types/models';
import { useState } from 'react';
import { ProcedureModal } from '../../components/Procedures/ProcedureModal';
import { DeleteConfirmationPopover } from '../../components/shared/Popover/DeleteConfirmationPopover';
import { toast } from 'react-toastify';
import { useApiCall } from '../../utils/hooks/useApiCall';
import { deleteProcedure } from '../../services/proceduresServices';

const columns = [
  { label: 'Sigla Identificadora', key: 'acronym' },
  { label: 'Nome', key: 'studyId' },
  { label: 'Tipo', key: 'name' },
  { label: 'Ações', key: 'actions' },
];

export const ProceduresPage = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [procedureData, setProcedure] = useState<
    ProcedureViewModel | undefined
  >();
  const [procedureLoading, setProcedureLoading] = useState(false);
  const { data: procedures, refetch } =
    useQuery<ProcedureViewModel[]>('/procedures');
  const api = useApiCall();

  const handleClose = () => {
    setProcedure(undefined);
    onClose();
  };

  const handleOpenEditModal = (usr: ProcedureViewModel) => {
    setProcedure(usr);
    onOpen();
  };

  const handleDelete = async (procedureId: number) => {
    try {
      setProcedureLoading(true);
      await deleteProcedure(procedureId, api);
      refetch();
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      setProcedureLoading(false);
    }
  };

  return (
    <>
      <ProcedureModal
        procedure={procedureData}
        refetch={refetch}
        isOpen={isOpen}
        onClose={handleClose}
      />
      <PanelWrapper title="Procedimentos">
        <FilterContainer onOpenModal={onOpen} />
        <Box className="rounded-lg shadow p-4 pt-10 mt-5">
          <Table>
            <Thead>
              <Tr>
                {columns.map((column) => (
                  <Th key={column.key}>{column.label}</Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {procedures?.map((row) => (
                <Tr
                  key={row.id}
                  className="hover:bg-gray-50 hover:cursor-pointer"
                >
                  <Td>{row.identifier}</Td>
                  <Td>{row.name}</Td>
                  <Td>{row.type}</Td>

                  <Td>
                    <div className="w-full flex justify-around">
                      <DeleteConfirmationPopover
                        loading={procedureLoading}
                        onConfirm={() => handleDelete(row.id)}
                      >
                        <IconButton
                          colorScheme="red"
                          aria-label="Excluir"
                          icon={<DeleteIcon color="white" />}
                        />
                      </DeleteConfirmationPopover>
                      <IconButton
                        onClick={() => handleOpenEditModal(row)}
                        colorScheme="blue"
                        aria-label="Editar"
                        icon={<EditIcon color="white" />}
                      />
                    </div>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      </PanelWrapper>
    </>
  );
};
