import {
  Box,
  IconButton,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import { PanelWrapper } from '../../components/shared/PanelWrapper/PannelWrapper';
import { FilterContainer } from '../../components/shared/Filter/FilterContainer';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { useQuery } from '../../utils/hooks/useQuery';
import { useState } from 'react';
import { DeleteConfirmationPopover } from '../../components/shared/Popover/DeleteConfirmationPopover';
import { toast } from 'react-toastify';
import { useApiCall } from '../../utils/hooks/useApiCall';
import { ResearchCenterModal } from '../../components/ResearchCenters/ResearchCenterModal';
import { ResearchCenterViewModel } from '../../types/models';
import { deleteResearchCenter } from '../../services/researchCentersServices';

const columns = [
  { label: 'Nome', key: 'name' },
  { label: 'CNPJ', key: 'document' },
  { label: 'CNES', key: 'cnes' },
  { label: 'Hospital Backup', key: 'backupHospital' },
  { label: 'Ações', key: 'actions' },
];

export const ResearchCenterPage = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const api = useApiCall();
  const [loading, setLoading] = useState(false);
  const [peopleData, setPeople] = useState<
    ResearchCenterViewModel | undefined
  >();
  const {
    data: researchCenter,
    // loading: peopleLoading,
    refetch,
  } = useQuery<ResearchCenterViewModel[]>('/research-centers');

  const handleClose = () => {
    setPeople(undefined);
    onClose();
  };

  const handleOpenEditModal = (ppl: ResearchCenterViewModel) => {
    setPeople(ppl);
    onOpen();
  };

  const handleDelete = async (id: number) => {
    try {
      setLoading(true);
      await deleteResearchCenter(id, api);
      refetch();
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <ResearchCenterModal
        researchCenter={peopleData}
        refetch={refetch}
        isOpen={isOpen}
        onClose={handleClose}
      />

      <PanelWrapper title="Centros de Pesquisa">
        <FilterContainer onOpenModal={onOpen} />
        <Box className="rounded-lg shadow p-4 pt-10 mt-5">
          <Table>
            <Thead>
              <Tr>
                {columns.map((column) => (
                  <Th key={column.key}>{column.label}</Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {researchCenter?.map((ppl) => (
                <Tr
                  key={ppl.id}
                  className="hover:bg-gray-50 hover:cursor-pointer"
                >
                  <Td>{ppl.people.name}</Td>
                  <Td>{ppl.people.document}</Td>
                  <Td>{ppl.cnes}</Td>
                  <Td>{ppl.backupHospital?.name ?? 'N/A'}</Td>
                  <Td>
                    <div className="w-full flex justify-around">
                      <DeleteConfirmationPopover
                        loading={loading}
                        onConfirm={() => handleDelete(ppl.id)}
                      >
                        <IconButton
                          colorScheme="red"
                          aria-label="teste"
                          icon={<DeleteIcon color="white" />}
                        />
                      </DeleteConfirmationPopover>
                      <IconButton
                        onClick={() => handleOpenEditModal(ppl)}
                        colorScheme="blue"
                        aria-label="teste"
                        icon={<EditIcon color="white" />}
                      />
                    </div>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      </PanelWrapper>
    </>
  );
};
