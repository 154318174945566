import { toast } from 'react-toastify';
import { EventViewModel } from '../types/models';
import { ApiCallContextProps } from '../utils/hooks/useApiCall';

export const createEvent = async (
  payload: Partial<EventViewModel>,
  api: ApiCallContextProps
) => {
  try {
    await api.post?.('/events', payload);
    toast.success('Evento Criado com sucesso');
  } catch (error) {
    throw new Error('Ocorreu um erro ao criar Evento');
  }
};

export const modifyEvent = async (
  id: number,
  payload: Partial<EventViewModel>,
  api: ApiCallContextProps
) => {
  try {
    return await api.put<Partial<EventViewModel>, EventViewModel>?.(
      `/events/${id}`,
      payload
    );
  } catch (error) {
    throw new Error('Ocorreu um erro ao editar Evento');
  }
};

export const deleteEvent = async (id: number, api: ApiCallContextProps) => {
  try {
    return await api.del?.(`/events/${id}`);
  } catch (error) {
    throw new Error('Ocorreu um erro ao excluir Evento');
  }
};

export const getManyEvents = async (api: ApiCallContextProps) => {
  try {
    return await api.get<EventViewModel[]>?.(`/events`);
  } catch (error) {
    throw new Error('Ocorreu um erro ao recuperar informações');
  }
};

export const getOneEvent = async (id: number, api: ApiCallContextProps) => {
  try {
    return api.get<EventViewModel>?.(`/events/${id}`);
  } catch (error) {
    throw new Error('Ocorreu um erro ao recuperar informações');
  }
};

export const getStudyPatientEvents = async (
  id: number,
  api: ApiCallContextProps
) => {
  try {
    return await api.get<EventViewModel[]>?.(`/events/study-patient/${id}`);
  } catch (error) {
    throw new Error('Ocorreu um erro ao recuperar informações');
  }
};

export const getPdfsFromEvents = async (
  payload: EventViewModel[],
  api: ApiCallContextProps
) => {
  try {
  return await api?.post<EventViewModel[], Uint8Array>?.('/events/pdfs', payload)
  } catch (error) {
    throw new Error('Ocorreu um erro ao gerar folha de rosto.');
  }
};

