import { Box, Stat, StatLabel, StatNumber } from '@chakra-ui/react';
import { useState } from 'react';
import { StudyPatientViewModel } from '../../types/models';
import { useNavigate } from 'react-router-dom';

interface PacientStatsProps {
  label: string;
  pacients: StudyPatientViewModel[] | undefined;
}

export const PacientStats = ({ label, pacients }: PacientStatsProps) => {
  const [showPacientList, setShowPatientsList] = useState(false);
  return (
    <>
      <Stat
        onMouseLeave={() => setShowPatientsList(false)}
        onClick={() => setShowPatientsList(!showPacientList)}
        className="m-2 rounded hover:cursor-pointer hover:bg-slate-100 "
      >
        <Box className="p-2">
          <StatLabel>{label}</StatLabel>
          <StatNumber>{pacients && pacients.length}</StatNumber>
        </Box>

        {showPacientList && (
          <div className="relative h-auto border bg-white z-50">
            <div className="absolute  w-full shadow">
              {pacients &&
                pacients.map((pac) => (
                  <div
                    key={pac.id}
                    className="bg-white hover:bg-slate-200 px-2 py-1 cursor-pointer flex justify-between"
                    onClick={() =>
                      window.open(
                        `/admin/patients?&name=${pac.patient.people.name}`,
                        '_blank'
                      )
                    }
                  >
                    <div className="truncate">{pac.patient.people.name}</div>
                    <div className="truncate">
                      {pac.researchCenterStudy.clinicalStudy.acronym}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        )}
      </Stat>
    </>
  );
};
