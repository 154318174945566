import { useState } from 'react';
import { AttachmentViewModel } from '../../types/models';
import { DeleteConfirmationPopover } from '../shared/Popover/DeleteConfirmationPopover';
import { IconButton } from '@chakra-ui/react';
import { DeleteIcon, DownloadIcon } from '@chakra-ui/icons';
import { useApiCall } from '../../utils/hooks/useApiCall';
import { toast } from 'react-toastify';

interface AttachmentCardProps {
  file: AttachmentViewModel;
  refresh: () => Promise<void>;
  readOnly?: boolean;
}

export const AttachmentCard = ({
  file,
  refresh,
  readOnly,
}: AttachmentCardProps) => {
  const [loading, setLoading] = useState(false);
  const api = useApiCall();

  const handleDelete = async (fileId: number) => {
    try {
      setLoading(true);
      await api?.del?.(`/attachments/${fileId}`);
      toast.success('Anexo Excluído com sucesso');
      refresh();
    } catch (error) {
      toast.error('Ocorreu um erro ao excluir o anexo');
    } finally {
      setLoading(false);
    }
  };

  const handleDownload = async (fileId: number) => {
    try {
      setLoading(true);
      const { data } = await api?.download<any>?.(`/attachments/${fileId}`);

      const bufferArray = new Uint8Array(data.data.file.data).buffer;
      const blob = new Blob([bufferArray]);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.href = url;
      a.download = data.data.uuid;
      a.click();
      window.URL.revokeObjectURL(url);
      refresh();
    } catch (error) {
      console.error(error);
      toast.error('Ocorreu um erro ao baixar o anexo');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="w-full flex justify-between pb-2 border-b">
      <div>{file.fileName}</div>
      <div className="flex gap-2">
        {!readOnly && (
          <DeleteConfirmationPopover
            loading={loading}
            onConfirm={() => handleDelete(file.id)}
          >
            <IconButton
              colorScheme="red"
              aria-label="Excluir"
              icon={<DeleteIcon color="white" />}
            />
          </DeleteConfirmationPopover>
        )}

        <IconButton
          isLoading={loading}
          onClick={() => handleDownload(file.id)}
          colorScheme="blue"
          aria-label="Excluir"
          icon={<DownloadIcon color="white" />}
        />
      </div>
    </div>
  );
};
