import {
  PeopleViewModel,
  ClinicalStudyViewModel,
  AddInfoViewModel,
} from '../../types/models';
import { useForm } from '../../utils/hooks/useForm';
import { MainModal } from '../shared/Modal/MainModal';
import { FormSection } from '../shared/Form/FormSection';
import { LabeledInput } from '../shared/Form/LabeledInput';
import { SelectInput } from '../shared/Form/SelectInput';
import { useEffect, useMemo, useState } from 'react';
import { useApiCall } from '../../utils/hooks/useApiCall';
import { toast } from 'react-toastify';
import { useQuery } from '../../utils/hooks/useQuery';
import {
  createClinicalStudy,
  modifyClinicalStudy,
} from '../../services/clinicalStudiesServices';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { AggregatorInput } from '../shared/Form/AggregatorInput';
import { formatDate, formatSelectData } from '../../utils/helpers/helpers';

interface ClinicalStudyModalProps {
  isOpen: boolean;
  onClose: () => void;
  refetch: () => void;
  clinicalStudy?: ClinicalStudyViewModel;
}

const defaultForm: Partial<ClinicalStudyViewModel> = {
  id: undefined,
  industryId: undefined,
  acronym: '',
  name: '',
  startDate: '',
  endDate: '',
  originCountry: '',
  studyId: '',
};

export const ClinicalStudyModal = ({
  isOpen,
  onClose,
  refetch,
  clinicalStudy,
}: ClinicalStudyModalProps) => {
  const [loading, setLoading] = useState(false);
  const [addInfoToRemove, setAddInfoToRemove] = useState<number[]>([]);
  const { formData, onFormChange, onSelectFormChange, setFormData } =
    useForm(defaultForm);
  const api = useApiCall();

  const { data: peopleData } = useQuery<PeopleViewModel[]>('/people');

  const formattedPeopleOptions = useMemo(
    () => formatSelectData(peopleData ?? [], true),
    [peopleData]
  );

  useEffect(() => {
    if (clinicalStudy) {
      setFormData({
        ...clinicalStudy,
        startDate: formatDate(clinicalStudy.startDate),
        endDate: formatDate(clinicalStudy.endDate),
        cepApprovalDate: formatDate(clinicalStudy.cepApprovalDate),
        sivDate: formatDate(clinicalStudy.sivDate),
        recruitingEndDate: formatDate(clinicalStudy.recruitingEndDate),
        recruitingStartDate: formatDate(clinicalStudy.recruitingStartDate),
        eosDate: formatDate(clinicalStudy.eosDate),
      });
    } else {
      setFormData(defaultForm);
    }
  }, [clinicalStudy, setFormData]);

  const handleSubmit = async () => {
    const errors: string[] = [];
    if (!formData.industryId) errors.push('O campo industria é obrigatório');
    if (!formData.studyId) errors.push('O campo ID Estudo é obrigatório');
    if (!formData.startDate) errors.push('O campo Data Início é obrigatório');
    if (!formData.endDate) errors.push('O campo Data Final é obrigatório');
    if (errors.length) return errors.forEach((error) => toast.error(error));
    setLoading(true);
    if (formData.industryId) {
      formData.industryId = Number(formData.industryId);
    }
    if (formData.startDate) {
      formData.startDate = new Date(formData.startDate).toISOString();
    }
    if (formData.endDate) {
      formData.endDate = new Date(formData.endDate).toISOString();
    }
    try {
      if (clinicalStudy) {
        const id = clinicalStudy.id ?? 0;
        delete formData.id;
        delete formData.createdAt;
        //@ts-ignore
        formData.addInfoToRemove = addInfoToRemove;
        formData.updatedAt = new Date().toISOString();
        await modifyClinicalStudy(id, formData, api);
      } else {
        await createClinicalStudy(formData, api);
      }

      setLoading(false);
      refetch();
      onClose();
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleAddAggregated =
    (type: AddInfoViewModel['type']) => (name: string) => {
      setFormData({
        ...formData,
        addInfo: [
          ...(formData.addInfo ?? []),
          {
            name,
            tempId: String(Math.random()),
            type,
          },
        ],
      });
    };

  const handleRemoveAggregated = (tempId?: string, id?: number) => {
    const tempAddInfo = [...(formData.addInfo ?? [])];
    const filteredAddInfo = tempAddInfo.filter(
      (info) => info.tempId !== tempId
    );

    if (id) {
      setAddInfoToRemove([...addInfoToRemove, id]);
    }

    setFormData({ ...formData, addInfo: filteredAddInfo });
  };

  return (
    <MainModal
      title="Criar Estudo Clínico"
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={handleSubmit}
      loading={loading}
      size="xl"
    >
      <Tabs>
        <TabList>
          <Tab>1 Protocolo</Tab>
          <Tab>2 PICOS</Tab>
          <Tab>3 Critérios</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <div>
              <FormSection title="Informações do Centro de Pesquisa">
                <SelectInput
                  value={String(formData.industryId) ?? ''}
                  id="industryId"
                  options={formattedPeopleOptions ?? []}
                  onChange={onSelectFormChange}
                  label="Industria"
                />
                <LabeledInput
                  value={formData.name ?? ''}
                  id="name"
                  label="Nome do Estudo"
                  onChange={onFormChange}
                />
                <LabeledInput
                  value={formData.acronym ?? ''}
                  id="acronym"
                  label="Acronomo"
                  onChange={onFormChange}
                />

                <LabeledInput
                  value={formData.studyId ?? ''}
                  id="studyId"
                  label="ID do Estudo"
                  onChange={onFormChange}
                />

                <LabeledInput
                  value={formData.startDate ?? ''}
                  type="date"
                  id="startDate"
                  label="Data início"
                  onChange={onFormChange}
                />
                <LabeledInput
                  value={formData.endDate ?? ''}
                  type="date"
                  id="endDate"
                  label="Data final"
                  onChange={onFormChange}
                />
              </FormSection>
            </div>
          </TabPanel>
          <TabPanel>
            <div>
              <FormSection title="PICOS">
                <LabeledInput
                  value={formData.population ?? ''}
                  id="population"
                  label="População Estudada"
                  onChange={onFormChange}
                />
                <SelectInput
                  value={String(formData.type) ?? ''}
                  id="type"
                  options={
                    [{ value: 'ENSAIO_CLINICO', label: 'Ensaio Clínico' }] ?? []
                  }
                  onChange={onSelectFormChange}
                  label="Tipo de Estudo"
                />
                <SelectInput
                  value={String(formData.subType) ?? ''}
                  id="subType"
                  options={[{ value: 'FASE_3', label: 'Fase 3' }] ?? []}
                  onChange={onSelectFormChange}
                  label="Sub-tipo"
                />
                <LabeledInput
                  value={formData.intervention ?? ''}
                  id="intervention"
                  label="Intervenção"
                  onChange={onFormChange}
                />
                <AggregatorInput
                  label="Comparadores"
                  items={
                    formData.addInfo?.filter(
                      (info) => info.type === 'STUDY_COMPARATOR'
                    ) ?? []
                  }
                  onAddItem={handleAddAggregated('STUDY_COMPARATOR')}
                  onRemoveItem={handleRemoveAggregated}
                />
                <AggregatorInput
                  label="Desfechos"
                  items={
                    formData.addInfo?.filter(
                      (info) => info.type === 'OUTCOME'
                    ) ?? []
                  }
                  onAddItem={handleAddAggregated('OUTCOME')}
                  onRemoveItem={handleRemoveAggregated}
                />
                <AggregatorInput
                  label="Descritores"
                  items={
                    formData.addInfo?.filter(
                      (info) => info.type === 'DESCRIPTOR'
                    ) ?? []
                  }
                  onAddItem={handleAddAggregated('DESCRIPTOR')}
                  onRemoveItem={handleRemoveAggregated}
                />
              </FormSection>
            </div>
          </TabPanel>
          <TabPanel>
            <div>
              <FormSection title="Critérios">
                <AggregatorInput
                  label="Critérios de Inclusão"
                  items={
                    formData.addInfo?.filter(
                      (info) => info.type === 'INCLUSION_CRITERIA'
                    ) ?? []
                  }
                  onAddItem={handleAddAggregated('INCLUSION_CRITERIA')}
                  onRemoveItem={handleRemoveAggregated}
                />
                <AggregatorInput
                  label="Critérios de Exclusão"
                  items={
                    formData.addInfo?.filter(
                      (info) => info.type === 'EXCLUSION_CRITERIA'
                    ) ?? []
                  }
                  onAddItem={handleAddAggregated('EXCLUSION_CRITERIA')}
                  onRemoveItem={handleRemoveAggregated}
                />
              </FormSection>
            </div>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </MainModal>
  );
};
