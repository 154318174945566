import { toast } from 'react-toastify';
import { ResearcherViewModel } from '../types/models';
import { ApiCallContextProps } from '../utils/hooks/useApiCall';

export const createResearcher = async (
  payload: Partial<ResearcherViewModel>,
  api: ApiCallContextProps
) => {
  try {
    await api.post?.('/researchers', payload);
    toast.success('Investigador Criado com sucesso');
  } catch (error) {
    throw new Error('Ocorreu um erro ao criar Investigador');
  }
};

export const modifyResearcher = async (
  id: number,
  payload: Partial<ResearcherViewModel>,
  api: ApiCallContextProps
) => {
  try {
    await api.put?.(`/researchers/${id}`, payload);
    toast.success('Investigador Editada com sucesso');
  } catch (error) {
    throw new Error('Ocorreu um erro ao editar Investigador');
  }
};

export const deleteResearcher = async (
  id: number,
  api: ApiCallContextProps
) => {
  try {
    await api.del?.(`/researchers/${id}`);
    toast.success('Investigador excluído com sucesso');
  } catch (error) {
    throw new Error('Ocorreu um erro ao excluir Investigador');
  }
};

export const getManyResearcher = async (api: ApiCallContextProps) => {
  try {
    await api.get?.(`/researchers`);
  } catch (error) {
    throw new Error('Ocorreu um erro ao recuperar informações');
  }
};

export const getOneResearcher = async (
  id: number,
  api: ApiCallContextProps
) => {
  try {
    await api.get?.(`/researchers/${id}`);
  } catch (error) {
    throw new Error('Ocorreu um erro ao recuperar informações');
  }
};
