import { Button } from '@chakra-ui/react'
import { format } from 'date-fns';

const DayCell = ({day, calendarRef}: any) => {
  return (
    <>
        <Button
            onClick={() =>
            calendarRef.current.calendar.changeView(
                'timeGridDay',
                format(day.date, 'yyyy-MM-dd')
            )
            }
        >
        {day.dayNumberText}
      </Button >
    </>
  )
}

export default DayCell