import {
  PeopleViewModel,
  ManagerViewModel,
  ResearchCenterViewModel,
} from '../../types/models';
import { useForm } from '../../utils/hooks/useForm';
import { MainModal } from '../shared/Modal/MainModal';
import { FormSection } from '../shared/Form/FormSection';
import { LabeledInput } from '../shared/Form/LabeledInput';
import { SelectInput } from '../shared/Form/SelectInput';
import { useEffect, useMemo, useState } from 'react';
import { useApiCall } from '../../utils/hooks/useApiCall';
import { toast } from 'react-toastify';
import { useQuery } from '../../utils/hooks/useQuery';
import { createManager, modifyManager } from '../../services/managersServices';
import { formatSelectData } from '../../utils/helpers/helpers';

interface ManagerModalProps {
  isOpen: boolean;
  onClose: () => void;
  refetch: () => void;
  manager?: ManagerViewModel;
}

const defaultForm: Partial<ManagerViewModel> = {
  id: undefined,
  peopleId: undefined,

  entity: '',
  entityNumber: '',
};

export const ManagerModal = ({
  isOpen,
  onClose,
  refetch,
  manager,
}: ManagerModalProps) => {
  const [loading, setLoading] = useState(false);
  const { formData, onFormChange, onSelectFormChange, setFormData } =
    useForm(defaultForm);
  const api = useApiCall();

  const { data: researchCenterData } =
    useQuery<ResearchCenterViewModel[]>('/research-centers');

  const { data: peopleData } = useQuery<PeopleViewModel[]>('/people');

  const formattedOptions = useMemo(
    () =>
      peopleData?.map((ppl) => ({
        value: String(ppl.id),
        label: ppl.name,
      })),
    [peopleData]
  );

  useEffect(() => {
    if (manager) {
      setFormData({
        ...manager,
      });
    } else {
      setFormData(defaultForm);
    }
  }, [manager, setFormData]);

  const handleSubmit = async () => {
    const errors = [];

    //@ts-ignore
    if (!formData.peopleId || formData.peopleId === 'SELECIONE')
      errors.push('É necessário selecionar uma pessoa');
    if (!formData.entity) errors.push('O campo Entidade é obrigatório');
    if (!formData.entityNumber)
      errors.push('O campo Registro na Entidade é obrigatório');

    formData.peopleId = Number(formData.peopleId);

    if (errors.length) return errors.forEach((error) => toast.error(error));
    setLoading(true);
    try {
      formData.researchCenterId = Number(formData.researchCenterId);
      if (manager) {
        const id = formData.id ?? 0;
        delete formData.id;
        delete formData.createdAt;
        formData.updatedAt = new Date().toISOString();
        await modifyManager(id, formData, api);
      } else {
        await createManager(formData, api);
      }

      setLoading(false);
      refetch();
      onClose();
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };
  return (
    <MainModal
      title="Criar Coordenador"
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={handleSubmit}
      loading={loading}
    >
      <div>
        <FormSection title="Informações do Centro de Pesquisa">
          <SelectInput
            value={String(formData.researchCenterId) ?? ''}
            onChange={onSelectFormChange}
            label="Centro de Pesquisa"
            id="researchCenterId"
            options={formatSelectData(researchCenterData ?? [])}
          />
        </FormSection>
        <FormSection title="Informações do Coordenador">
          <SelectInput
            value={String(formData.peopleId) ?? ''}
            id="peopleId"
            options={formattedOptions ?? []}
            onChange={onSelectFormChange}
            label="Pessoa"
          />
          <LabeledInput
            value={formData.profession ?? ''}
            id="profession"
            label="Profissão"
            onChange={onFormChange}
          />
          <LabeledInput
            value={formData.entity ?? ''}
            id="entity"
            label="Entidade de Classe"
            onChange={onFormChange}
          />
          <LabeledInput
            value={formData.entityNumber ?? ''}
            id="entityNumber"
            label="Número de registro na entidade"
            onChange={onFormChange}
          />
        </FormSection>
      </div>
    </MainModal>
  );
};
