import { AxiosRequestConfig } from 'axios';
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { useAuth } from './useAuth';
import { api } from '../configs/axiosConfig';
import { useNavigate } from 'react-router-dom';

export interface ApiCallContextProps {
  get: <T>(
    url: string,
    customHeaders?: AxiosRequestConfig['headers']
  ) => Promise<T>;
  post: <Y, T>(
    url: string,
    payload: Y,
    customHeaders?: AxiosRequestConfig['headers']
  ) => Promise<T>;
  put?: <Y, T>(
    url: string,
    payload: Y,
    customHeaders?: AxiosRequestConfig['headers']
  ) => Promise<T>;
  del?: <T>(
    url: string,
    customHeaders?: AxiosRequestConfig['headers']
  ) => Promise<T>;
  download?: <T>(
    url: string,
    customHeaders?: AxiosRequestConfig['headers']
  ) => any;
}

interface ApiCallProviderProps {
  children: ReactNode;
}

const ApiCallContext = createContext<ApiCallContextProps>(
  {} as ApiCallContextProps
);

export const ApiCallProvider = ({ children }: ApiCallProviderProps) => {
  const { token, loading, initialized } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!token && !loading && initialized) {
      navigate('/');
    }
  }, [initialized, loading, navigate, token]);

  const { get, post, put, del, download } = useMemo(
    () => api(token ?? ''),
    [token]
  );

  return (
    <ApiCallContext.Provider
      value={{
        get,
        post,
        put,
        del,
        download,
      }}
    >
      {children}
    </ApiCallContext.Provider>
  );
};

export const useApiCall = () => useContext(ApiCallContext);
