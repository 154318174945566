import { Box, Button, useDisclosure } from '@chakra-ui/react';
import { CalendarPage } from '../../pages/admin/CalendarPage';
import { NewEventModal } from '../StudyPatients/NewEventModal';

export const ManagerCalendarWrapper = () => {
  const { onOpen, onClose, isOpen } = useDisclosure();

  const handleOpen = () => {
    onOpen();
  };

  return (
    <>
      <NewEventModal isOpen={isOpen} onClose={onClose} refetch={() => {}} />
      <Box>
        <Box className="w-full flex justify-between p-5">
          <Box>
            <p>Agendas:</p>
          </Box>
          <Box>
            <Button colorScheme="blue" onClick={handleOpen}>
              Adicionar Unscheduled
            </Button>
          </Box>
        </Box>
        <CalendarPage />
      </Box>
    </>
  );
};
