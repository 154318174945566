import { format } from 'date-fns';
import { toast } from 'react-toastify';

export const formatSelectData = (items: any[], people = false) =>
  items?.map((item) => ({
    value: String(item.id),
    label: people ? item?.name : item.people?.name,
  }));

export const formatDate = (date?: string) =>
  date ? format(new Date(date), 'yyyy-MM-dd') : undefined;

export const formatISODate = (date?: string) =>
  date ? new Date(date).toISOString() : undefined;

export const formatLocaleDate = (date: string) =>
  format(new Date(date), 'dd/MM HH:mm');


export const formatDateTime = (date: string) =>
  format(new Date(date), 'dd/MM/yy HH:mm');



export const isInDayRange = (start: Date, end:Date, selectedDate: Date) => {
  const adjustedStart = start;
  adjustedStart.setHours(adjustedStart.getHours() + 3); 
  const adjustedEnd = end;
  adjustedEnd.setHours(adjustedEnd.getHours() + 3); 
  const nextDay = new Date(selectedDate.getTime());
  if (adjustedStart.getMonth() !== nextDay.getMonth() ) return false
  return (
    adjustedStart.getDate() === nextDay.getDate() &&
    adjustedEnd.getDate() === nextDay.getDate()
  );
}


export const handleDownload = async (bytes: Uint8Array, fileName: string) => {
  try {
    const file = new File([new Uint8Array(bytes)],fileName)
    const blob = new Blob([file],{type:"application/pdf"});
    const url = window.URL.createObjectURL(blob);
    window.open(url)
  } catch (error) {
    toast.error((error as Error).message);
  } 
};