import { toast } from 'react-toastify';
import { ProcedureViewModel } from '../types/models';
import { ApiCallContextProps } from '../utils/hooks/useApiCall';

export const createProcedure = async (
  payload: Partial<ProcedureViewModel>,
  api: ApiCallContextProps
) => {
  try {
    await api.post?.('/procedures', payload);
    toast.success('Procedimento Criada com sucesso');
  } catch (error) {
    throw new Error('Ocorreu um erro ao criar Procedimento');
  }
};

export const modifyProcedure = async (
  id: number,
  payload: Partial<ProcedureViewModel>,
  api: ApiCallContextProps
) => {
  try {
    await api.put?.(`/procedures/${id}`, payload);
    toast.success('Procedimento Editado com sucesso');
  } catch (error) {
    throw new Error('Ocorreu um erro ao editar Procedimento');
  }
};

export const deleteProcedure = async (id: number, api: ApiCallContextProps) => {
  try {
    await api.del?.(`/procedures/${id}`);
    toast.success('Procedimento excluída com sucesso');
  } catch (error) {
    throw new Error('Ocorreu um erro ao excluir Procedimento');
  }
};

export const getManyProcedure = async (api: ApiCallContextProps) => {
  try {
    await api.get?.(`/procedures`);
  } catch (error) {
    throw new Error('Ocorreu um erro ao recuperar informações');
  }
};

export const getOneProcedure = async (id: number, api: ApiCallContextProps) => {
  try {
    await api.get?.(`/procedures/${id}`);
  } catch (error) {
    throw new Error('Ocorreu um erro ao recuperar informações');
  }
};
