import { toast } from 'react-toastify';
import { StudyPatientViewModel } from '../types/models';
import { ApiCallContextProps } from '../utils/hooks/useApiCall';

export const createStudyPatient = async (
  payload: Partial<StudyPatientViewModel>,
  api: ApiCallContextProps
) => {
  try {
    await api.post?.('/study-patients', payload);
    toast.success('Vínculo Criado com sucesso');
  } catch (error) {
    throw new Error('Ocorreu um erro ao criar StudyPatient');
  }
};

export const modifyStudyPatient = async (
  id: number,
  payload: Partial<StudyPatientViewModel>,
  api: ApiCallContextProps
) => {
  try {
    await api.put?.(`/study-patients/${id}`, payload);
    toast.success('Vínculo Editado com sucesso');
  } catch (error) {
    throw new Error('Ocorreu um erro ao editar StudyPatient');
  }
};

export const deleteStudyPatient = async (
  id: number,
  api: ApiCallContextProps
) => {
  try {
    await api.del?.(`/study-patients/${id}`);
    toast.success('Vínculo excluído com sucesso');
  } catch (error) {
    throw new Error('Ocorreu um erro ao excluir Vínculo');
  }
};

export const getManyStudyPatient = async (api: ApiCallContextProps) => {
  try {
    await api.get?.(`/study-patients`);
  } catch (error) {
    throw new Error('Ocorreu um erro ao recuperar informações');
  }
};

export const getOneStudyPatient = async (
  id: number,
  api: ApiCallContextProps
) => {
  try {
    await api.get?.(`/study-patients/${id}`);
  } catch (error) {
    throw new Error('Ocorreu um erro ao recuperar informações');
  }
};

export const setPendingSchedule = async (
  studyPatientId: number,
  value: boolean,
  api: ApiCallContextProps
) => {
  try {
    await api.put?.(`/study-patients/${studyPatientId}`, {
      hasPendingSchedule: value,
    });
  } catch (error) {
    throw new Error('Ocorreu um erro ao recuperar informações');
  }
};
