import { Box, Button, Container, Heading, Input } from '@chakra-ui/react';
import { ChangeEvent, useEffect, useState } from 'react';
import { useAuth } from '../utils/hooks/useAuth';
import { useNavigate } from 'react-router-dom';

const defaultForm = {
  username: '',
  password: '',
};

export const LoginPage = () => {
  const [formData, setFormData] = useState(defaultForm);
  const { signIn, loading, isLoggedIn, initialized } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (initialized && isLoggedIn) navigate('/admin/people');
  }, [initialized, isLoggedIn, navigate]);

  const handleFormChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.target;

    setFormData({ ...formData, [id]: value });
  };

  const handleLogin = async (e: any) => {
    e.preventDefault()
    await signIn?.(formData);
  };

  return (
    <div className="w-screen h-screen top-0 left-0 relative bg-gray-50">
      <Box className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[480px]">
        <form onSubmit={handleLogin}>
          <Container className="flex flex-col gap-4 shadow py-4  rounded-lg">
            <Heading>Entrar</Heading>
            <Input
              id="username"
              onChange={handleFormChange}
              placeholder="Usuário..."
            />
            <Input
              id="password"
              onChange={handleFormChange}
              placeholder="Senha..."
              type="password"
            />

            <Box className="w-full flex justify-between">
              <Button variant="link">Esqueci a senha</Button>
              <Button
                type='submit'
                isLoading={loading}
                w={200}
                disabled={loading}
                colorScheme="blue"
              >
                {loading ? 'Entrando...' : 'Entrar'}
              </Button>
            </Box>
          </Container>
        </form>
      </Box>
    </div>
  );
};
