import { AgendaViewModel, ResearcherViewModel } from '../../types/models';
import { useForm } from '../../utils/hooks/useForm';
import { MainModal } from '../shared/Modal/MainModal';
import { FormSection } from '../shared/Form/FormSection';
import { LabeledInput } from '../shared/Form/LabeledInput';
import { SelectInput } from '../shared/Form/SelectInput';
import { useCallback, useEffect, useState } from 'react';
import { useApiCall } from '../../utils/hooks/useApiCall';
import { toast } from 'react-toastify';
import {
  createAgenda,
  getOneAgendaByResearcherId,
  modifyAgenda,
} from '../../services/agendasServices';

interface AgendaModalProps {
  isOpen: boolean;
  onClose: () => void;
  refetch: () => void;
  researcher?: ResearcherViewModel;
}

const defaultForm: Partial<AgendaViewModel> = {
  id: undefined,
};

export const AgendaModal = ({
  isOpen,
  onClose,
  refetch,
  researcher,
}: AgendaModalProps) => {
  const [loading, setLoading] = useState(false);
  const { formData, onFormChange, onSelectFormChange, setFormData } =
    useForm(defaultForm);
  const [agenda, setAgenda] = useState<AgendaViewModel | undefined>();
  const api = useApiCall();

  const getAgenda = useCallback(async () => {
    if (researcher?.id) {
      const data = await getOneAgendaByResearcherId(researcher.id, api);
      setAgenda(data);
    }
  }, [api, researcher?.id]);

  useEffect(() => {
    if (researcher?.id && agenda?.id !== researcher?.agenda?.[0]?.id) {
      getAgenda();
    }
    if (agenda) {
      setFormData({
        ...agenda,
        researcherId: researcher?.id,
      });
    } else {
      setFormData({ ...defaultForm, researcherId: researcher?.id });
    }
  }, [agenda, getAgenda, researcher?.agenda, researcher?.id, setFormData]);

  const handleSubmit = async () => {
    const errors = [];

    //@ts-ignore
    if (!formData.researcherId || formData.researcherId === 'SELECIONE')
      errors.push('É necessário selecionar um Investigador');
    if (errors.length) return errors.forEach((error) => toast.error(error));
    setLoading(true);
    try {
      formData.researcherId = Number(formData.researcherId);
      if (agenda) {
        const id = formData.id ?? 0;
        delete formData.id;
        delete formData.createdAt;
        formData.updatedAt = new Date().toISOString();
        await modifyAgenda(id, formData, api);
      } else {
        await createAgenda(formData, api);
      }

      setLoading(false);
      refetch();
      onClose();
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      setLoading(false);
      setAgenda(undefined);
    }
  };
  return (
    <MainModal
      title="Gerenciamento de Agenda"
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={handleSubmit}
      loading={loading}
      confirmText={agenda ? 'Salvar' : 'Criar'}
    >
      <div>
        <FormSection title="Informações do Investigador">
          <SelectInput
            value={String(formData.researcherId ?? researcher?.id)}
            onChange={onSelectFormChange}
            label="Investigador"
            id="researcherId"
            disabled
            options={[
              {
                label: researcher?.people.name ?? '',
                value: String(researcher?.id) ?? '',
              },
            ]}
          />
        </FormSection>
        <FormSection title="Informações da Agenda">
          <LabeledInput
            value={formData.name ?? ''}
            id="name"
            label="Nome de exibição"
            onChange={onFormChange}
          />
        </FormSection>
      </div>
    </MainModal>
  );
};
