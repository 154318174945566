import { Box, Button, Input, Text } from '@chakra-ui/react';
import { FilterColumn } from './FilterColumn';
import { Dispatch, SetStateAction, useState } from 'react';
import { useForm } from '../../../utils/hooks/useForm';

interface FilterContainerProps {
  onOpenModal: () => void;
  onSearch?: Dispatch<SetStateAction<string>>;
}

const defaultForm = {
  name: undefined,
  document: undefined,
};

export const FilterContainer = ({
  onOpenModal,
  onSearch,
}: FilterContainerProps) => {
  const { onFormChange, formData } = useForm(defaultForm);

  const handleSubmit = () => {
    const parsedQuery = Object.entries(formData)
      .filter(([_, value]) => value)
      .map(([key, value]) => `&${key}=${value}`)
      .join('');

    if (parsedQuery.length) {
      onSearch?.(parsedQuery);
    } else {
      onSearch?.('');
    }
  };

  return (
    <Box className="rounded-lg shadow p-4 pt-5">
      <Text className="mb-5 text-lg font-bold">Filtro</Text>
      <Box className=" w-full flex gap-4">
        <FilterColumn>
          <Input
            id="name"
            onChange={onFormChange}
            value={formData.name}
            placeholder="Nome..."
          />
        </FilterColumn>
        <FilterColumn>
          <Input
            id="document"
            onChange={onFormChange}
            value={formData.document}
            placeholder="CPF/CNPJ..."
          />
        </FilterColumn>
        <FilterColumn>
          <Input id="address" placeholder="Endereço..." />
        </FilterColumn>
      </Box>
      <Box className="w-full flex justify-end mt-4">
        <Box className="w-1/2 flex justify-end gap-6">
          <Button onClick={onOpenModal} colorScheme="blue">
            Adicionar
          </Button>
          <Button onClick={handleSubmit} colorScheme="blue" variant="outline">
            Pesquisar
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
