import { StudyPatientViewModel, EventViewModel } from '../../types/models';
import { useForm } from '../../utils/hooks/useForm';
import { MainModal } from '../shared/Modal/MainModal';
import { FormSection } from '../shared/Form/FormSection';
import { useCallback, useEffect, useState } from 'react';
import { useApiCall } from '../../utils/hooks/useApiCall';
import { Button, useDisclosure } from '@chakra-ui/react';
import { NewEventModal } from './NewEventModal';
import { getStudyPatientEvents } from '../../services/eventsServices';
import { toast } from 'react-toastify';
import { PatientVisitCard } from './PatientsVisitCard';

interface PatientEventsModalProps {
  isOpen: boolean;
  onClose: () => void;
  refetch: () => void;
  studyPatient?: StudyPatientViewModel;
}

const defaultForm: Partial<StudyPatientViewModel> = {
  id: undefined,
  patientId: undefined,
  researchCenterStudyId: undefined,
  tcle: undefined,
  tcleDate: undefined,
};

export const PatientEventsModal = ({
  isOpen,
  onClose,
  refetch,
  studyPatient,
}: PatientEventsModalProps) => {
  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState<EventViewModel[] | undefined>([]);
  const { formData, onFormChange, onSelectFormChange, setFormData } =
    useForm(defaultForm);
  const {
    isOpen: isOpenEvent,
    onClose: onCloseEvent,
    onOpen: onOpenEvent,
  } = useDisclosure();
  const api = useApiCall();

  const getPatientEvents = useCallback(async () => {
    try {
      if (studyPatient) {
        const data = await getStudyPatientEvents(studyPatient?.id, api);
        setEvents(data);
      }
    } catch (error: any) {
      toast.error(error.message);
    }
  }, [api, studyPatient]);

  useEffect(() => {
    if (studyPatient?.id) {
      getPatientEvents();
    }
  }, [getPatientEvents, studyPatient]);

  return (
    <>
      <NewEventModal
        studyPatient={studyPatient}
        isOpen={Boolean(studyPatient && isOpenEvent)}
        onClose={onCloseEvent}
        refetch={getPatientEvents}
      />
      <MainModal
        title="Gerenciar Eventos"
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={() => {}}
        loading={loading}
        hideConfirmButton
      >
        <div>
          <Button onClick={onOpenEvent}>Adicionar Novo Evento</Button>
        </div>

        <FormSection title="Visitas do Paciente">
          {events?.map((event) => (
            <PatientVisitCard
              refetch={getPatientEvents}
              key={event.id}
              event={event}
            />
          ))}
        </FormSection>
      </MainModal>
    </>
  );
};
