import {
  Box,
  IconButton,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import { PanelWrapper } from '../../components/shared/PanelWrapper/PannelWrapper';
import { FilterContainer } from '../../components/shared/Filter/FilterContainer';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { useQuery } from '../../utils/hooks/useQuery';
import { PatientViewModel } from '../../types/models';
import { useEffect, useState } from 'react';
import { PatientModal } from '../../components/Patients/PatientModal';
import { DeleteConfirmationPopover } from '../../components/shared/Popover/DeleteConfirmationPopover';
import { toast } from 'react-toastify';
import { useApiCall } from '../../utils/hooks/useApiCall';
import { deletePatient } from '../../services/patientsServices';
import { format } from 'date-fns';
import { useSearchParams } from 'react-router-dom';

const columns = [
  { label: 'Nome', key: 'acronym' },
  { label: 'Documento', key: 'name' },
  { label: 'Ações', key: 'actions' },
];

export const PatientsPage = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [searchParams, setSearchParams] = useSearchParams();

  const [patientData, setPatient] = useState<PatientViewModel | undefined>();
  const [patientLoading, setPatientLoading] = useState(false);
  const {
    data: patients,
    refetch,
    setSearch,
  } = useQuery<PatientViewModel[]>('/patients');
  const api = useApiCall();

  const handleClose = () => {
    setPatient(undefined);
    onClose();
  };

  const handleOpenEditModal = (usr: PatientViewModel) => {
    setPatient(usr);
    onOpen();
  };

  const handleDelete = async (patientId: number) => {
    try {
      setPatientLoading(true);
      await deletePatient(patientId, api);
      refetch();
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      setPatientLoading(false);
    }
  };

  useEffect(() => {
    const paramName = searchParams.get('name');
    if (paramName) {
      setSearch(`name=${paramName}`);
      refetch();
    }
  }, [searchParams, patientData, patientLoading]);

  return (
    <>
      <PatientModal
        patient={patientData}
        refetch={refetch}
        isOpen={isOpen}
        onClose={handleClose}
      />
      <PanelWrapper title="Pacientes">
        <FilterContainer onSearch={setSearch} onOpenModal={onOpen} />
        <Box className="rounded-lg shadow p-4 pt-10 mt-5">
          <Table>
            <Thead>
              <Tr>
                {columns.map((column) => (
                  <Th key={column.key}>{column.label}</Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {patients?.map((row) => (
                <Tr
                  key={row.id}
                  className="hover:bg-gray-50 hover:cursor-pointer"
                >
                  <Td>{row.people.name}</Td>
                  <Td>{row.people.document}</Td>
                  <Td>
                    <div className="w-full flex justify-around">
                      <DeleteConfirmationPopover
                        loading={patientLoading}
                        onConfirm={() => handleDelete(row.id)}
                      >
                        <IconButton
                          colorScheme="red"
                          aria-label="Excluir"
                          icon={<DeleteIcon color="white" />}
                        />
                      </DeleteConfirmationPopover>
                      <IconButton
                        onClick={() => handleOpenEditModal(row)}
                        colorScheme="blue"
                        aria-label="Editar"
                        icon={<EditIcon color="white" />}
                      />
                    </div>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      </PanelWrapper>
    </>
  );
};
