import { FormErrorIcon, IconButton } from '@chakra-ui/react';
import { ProcedureViewModel } from '../../types/models';
import { CheckIcon, CloseIcon, SpinnerIcon } from '@chakra-ui/icons';

interface ResearchVisitProcedureCardProps {
  procedure: ProcedureViewModel;
  onSuccess: (procedureId: number) => Promise<void>;
  onUnsuccess: (procedureId: number) => Promise<void>;
  onReset: (procedureId: number) => Promise<void>;
  unSelected?: boolean;
  unsuccess: boolean

}

export const ResearcherVisitProcedureCard = ({
  procedure,
  onSuccess,
  onUnsuccess,
  onReset,
  unSelected = false,
  unsuccess
}: ResearchVisitProcedureCardProps) => {
  return (
    <div className="border rounded-lg border-slate-200 w-full flex justify-between items-center p-4" style={{backgroundColor: unsuccess ? "rgb(237, 83, 83, 0.5)" : ""}}>
      <div>{procedure.name}</div>
      <div className="flex gap-3" >
        {unSelected ? (
          <>
            <IconButton
              onClick={() => onSuccess(procedure.id)}
              aria-label="realizado"
              colorScheme="green"
              icon={<CheckIcon />}
            />
            <IconButton
              onClick={() => onUnsuccess(procedure.id)}
              aria-label="Não Realizado"
              colorScheme="red"
              icon={<CloseIcon />}
            />
          </>
        ) : (
          <>
            <IconButton
              onClick={() => onReset(procedure.id)}
              aria-label="Não Realizado"
              colorScheme="red"
              icon={<SpinnerIcon />}
            />
          </>
        )}
      </div>
    </div>
  );
};
