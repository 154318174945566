import { ChangeEvent, useState } from 'react';
import {
  ApiCallContextProps,
  useApiCall,
} from '../../../utils/hooks/useApiCall';
import { debounce } from 'lodash';

interface AutoCompleteSelectInputProps<T> {
  filterApiGetter: (
    api: ApiCallContextProps,
    filterParam?: string
  ) => Promise<T[]>;
  filterKey: string;
  onFormChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export interface DataBaseModelViewCommonProp {
  id: number;
  name: string;
}

export function AutoCompleteSelectInput<T extends DataBaseModelViewCommonProp>({
  filterApiGetter,
  onFormChange,
  filterKey,
}: AutoCompleteSelectInputProps<T>) {
  const [results, setResults] = useState<T[]>([]);
  const [searchField, setSearchField] = useState('');
  const [selectedOption, setSelectedOption] = useState(false);

  const api = useApiCall();

  const debounceFilterFn = debounce(async (name: string) => {
    const search = await filterApiGetter(api, name);
    setResults(search);
  }, 500);

  const handleChangeFilterInput = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchField(value);
    debounceFilterFn(value);
    setSelectedOption(false);
  };

  const onSelectItem = (result: T) => {
    setSearchField(result.name);
    handleChangeForm(result.id);
    setSelectedOption(true);
  };

  const handleChangeForm = (value: any) => {
    onFormChange({
      target: {
        id: filterKey,
        value,
      },
    } as ChangeEvent<HTMLInputElement>);
  };

  const onClearSearchField = () => {
    setSearchField('');
    setSelectedOption(false);
    handleChangeForm(undefined);
  };

  return (
    <div className="w-full">
      <div className="flex">
        <input
          className="w-full border px-2 py-1"
          value={searchField}
          onChange={handleChangeFilterInput}
        />
        <div
          onClick={onClearSearchField}
          className="-ml-8 cursor-pointer px-2 py-1 hover:bg-slate-200 rounded m-1"
        >
          X
        </div>
      </div>
      <div className="relative h-auto border bg-white z-50">
        {searchField && !selectedOption && (
          <div className="absolute  w-full shadow">
            {results.map((result) => (
              <div
                className="hover:bg-slate-200 px-2 py-1 cursor-pointer"
                onClick={() => onSelectItem(result)}
                key={result.id}
              >
                {result.name}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
