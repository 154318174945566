import {
  PeopleViewModel,
  ResearchCenterViewModel,
  UserViewModel,
} from '../../types/models';
import { useForm } from '../../utils/hooks/useForm';
import { MainModal } from '../shared/Modal/MainModal';
import { FormSection } from '../shared/Form/FormSection';
import { LabeledInput } from '../shared/Form/LabeledInput';
import { SelectInput } from '../shared/Form/SelectInput';
import { useEffect, useMemo, useState } from 'react';
import { useQuery } from '../../utils/hooks/useQuery';
import { createUser, modifyUser } from '../../services/usersServices';
import { useApiCall } from '../../utils/hooks/useApiCall';
import { toast } from 'react-toastify';
import { formatSelectData } from '../../utils/helpers/helpers';

interface UserModalProps {
  isOpen: boolean;
  onClose: () => void;
  refetch: () => void;
  user?: UserViewModel;
}

export type UserFormProps = {
  researchCenterId: number;
  username: string;
  password: string;
  confirmPassword?: string;
  peopleId?: string;
};

const defaultForm: Partial<UserFormProps> = {
  username: '',
  password: '',
  confirmPassword: '',
  peopleId: '',
};
export const UserModal = ({
  isOpen,
  onClose,
  refetch,
  user,
}: UserModalProps) => {
  const [userLoading, setUserLoading] = useState(false);
  const { formData, onFormChange, onSelectFormChange, setFormData, resetForm } =
    useForm(defaultForm);
  const api = useApiCall();

  const { data: researchCenterData } =
    useQuery<ResearchCenterViewModel[]>('/research-centers');

  const { data: peopleData } = useQuery<PeopleViewModel[]>('/people');

  const formattedOptions = useMemo(
    () =>
      peopleData?.map((ppl) => ({ value: String(ppl.id), label: ppl.name })),
    [peopleData]
  );

  useEffect(() => {
    if (user) {
      setFormData({
        username: user.username,
        peopleId: String(user.peopleId),
      });
    } else {
      setFormData(defaultForm);
    }
  }, [user, setFormData]);

  const formValidation = (formData: any, editing?: boolean): string[] => {
    const errors = [];

    if (!formData.username)
      errors.push('O campo nome de usuário é obrigatório');
    if ((!formData.password || formData.password.length < 6) && !editing)
      errors.push(
        'O campo senha é obrigatório e precisa ter pelo menos 6 caractéres'
      );
    if (
      (formData.password || formData.confirmPassword) &&
      formData.password !== formData.confirmPassword
    )
      errors.push(
        'A confirmação de senha precisa ser idêntica à senha informada'
      );
    if (!formData.peopleId)
      errors.push('É obrigatória a seleção de uma pessoa para este cadastro');
    return errors;
  };

  const handleSubmit = async () => {
    try {
      if (user) {
        const errors = formValidation(formData, true);
        if (errors.length) return errors.forEach((error) => toast.error(error));

        await modifyUser(user.id, formData, api);
      } else {
        const errors = formValidation(formData);

        if (errors.length) return errors.forEach((error) => toast.error(error));

        setUserLoading(true);
        delete formData.confirmPassword;
        await createUser(formData, api);
        toast.success('Usuário criado com sucesso!');
      }

      resetForm();
      onClose();
      refetch();
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      setUserLoading(false);
    }
  };
  return (
    <MainModal
      title="Criar Usuário"
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={handleSubmit}
      loading={userLoading}
    >
      <div>
        <FormSection title="Informações do Centro de Pesquisa">
          <SelectInput
            value={String(formData.researchCenterId) ?? ''}
            onChange={onSelectFormChange}
            label="Centro de Pesquisa"
            id="researchCenterId"
            options={formatSelectData(researchCenterData ?? [])}
          />
        </FormSection>
        <FormSection title="Informações do usuário">
          <LabeledInput
            value={formData.username ?? ''}
            id="username"
            label="Nome de Usuário"
            onChange={onFormChange}
          />

          <SelectInput
            value={formData.peopleId ?? ''}
            id="peopleId"
            options={formattedOptions ?? []}
            onChange={onSelectFormChange}
            label="Pessoa"
          />
        </FormSection>
        <FormSection title="Senha">
          <LabeledInput
            type="password"
            value={formData.password ?? ''}
            id="password"
            label="Senha"
            onChange={onFormChange}
          />
          <LabeledInput
            type="password"
            value={formData.confirmPassword ?? ''}
            id="confirmPassword"
            label="Confirmação de Senha"
            onChange={onFormChange}
          />
        </FormSection>
      </div>
    </MainModal>
  );
};
