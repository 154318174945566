import { ReactNode } from 'react';
import logo from '../../assets/logo.png';
import { Link } from 'react-router-dom';
import { MenuItem } from './MenuItem';
import { useAuth } from '../../utils/hooks/useAuth';

interface SideMenuProps {
  children: ReactNode;
}

export const SideMenuWrapper = ({ children }: SideMenuProps) => {
  const { logout, user } = useAuth();

  return (
    <div className="relative top-0 left-0 w-screen h-screen flex">
      <div className="h-screen shadow bg-[#B1E2E9] w-56 py-3 flex justify-start flex-col">
        <Link to="/admin/dashboard">
          <img className="h-[60px] w-auto" src={logo} alt="logo" />
        </Link>
        <div className="flex flex-col justify-between h-full">
          <ul className="flex flex-col gap-3 font-sans mt-5 text-sm text-blue-900 w-full">
            <MenuItem to="/admin/users" title="Usuários" />
            <MenuItem to="/admin/people" title="Pessoas" />
            <MenuItem to="/admin/research-center" title="Centros de Pesquisa" />
            <MenuItem to="/admin/clinical-studies" title="Estudos Clínicos" />
            <MenuItem to="/admin/study-visits" title="Visitas" />
            <MenuItem
              to="/admin/research-center-studies"
              title="Estudos por Centro"
            />
            <MenuItem to="/admin/researchers" title="Investigadores" />

            <MenuItem to="/admin/managers" title="Coordenadores" />
            <MenuItem to="/admin/calendar" title="Calendário" />
            <MenuItem to="/admin/patients" title="Pacientes" />
            <MenuItem to="/admin/equipments" title="Equipamentos" />
            <MenuItem to="/admin/procedures" title="Procedimentos" />
            <MenuItem
              to="/admin/study-patients"
              title="Pacientes dos Estudos"
            />
            <MenuItem onClick={logout} to="/" title="Sair" />
          </ul>
          <ul className="flex flex-col gap-3 font-sans mt-5 text-sm text-blue-900 w-full">
            <li className="w-full flex flex-grow flex-col">
              <div className=" p-2 w-full pl-5 font-bold text-lg">
                {user ? user.username : null}
              </div>
            </li>
            <MenuItem onClick={logout} to="/" title="Sair" />
          </ul>
        </div>
      </div>
      <div className="w-full overflow-auto">{children}</div>
    </div>
  );
};
