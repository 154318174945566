import {
  Button,
  ButtonGroup,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
} from '@chakra-ui/react';
import { ReactNode } from 'react';

interface DeleteConfirmationPopoverProps {
  children: ReactNode;
  onConfirm: () => Promise<void>;
  loading: boolean;
}

export const DeleteConfirmationPopover = ({
  onConfirm,
  children,
  loading,
}: DeleteConfirmationPopoverProps) => {
  //   const { isOpen, onToggle, onClose } = useDisclosure();

  return (
    <>
      <Popover returnFocusOnClose={false} placement="right" closeOnBlur={false}>
        <PopoverTrigger>{children}</PopoverTrigger>
        <PopoverContent>
          <PopoverHeader fontWeight="semibold">Confirmar</PopoverHeader>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverBody>
            Tem certeza de que deseja excluir este registro?
          </PopoverBody>
          <PopoverFooter display="flex" justifyContent="flex-end">
            <ButtonGroup size="sm">
              {/* <Button variant="outline">Cancelar</Button> */}
              <Button
                onClick={onConfirm}
                isLoading={loading}
                loadingText="Apagando"
                colorScheme="red"
              >
                Confirmar
              </Button>
            </ButtonGroup>
          </PopoverFooter>
        </PopoverContent>
      </Popover>
    </>
  );
};
