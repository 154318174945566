import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { ReactNode } from 'react';

interface MainModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  children: ReactNode;
  onConfirm: () => void;
  onCancel?: () => void;
  loading: boolean;
  size?: 'sm' | 'md' | 'lg' | 'xl' | 'full';
  confirmText?: string;
  zIndex?: number;
  hideConfirmButton?: boolean;
}

export const MainModal = ({
  isOpen,
  onClose,
  title,
  children,
  onConfirm,
  onCancel,
  loading,
  size = 'lg',
  confirmText = 'Salvar',
  zIndex,
  hideConfirmButton = false,
}: MainModalProps) => {
  const handleClose = () => {
    onCancel?.();
    onClose();
  };
  return (
    <Modal size={size} isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent zIndex={zIndex} minW="650px" maxW="700px">
        <ModalCloseButton />
        <ModalHeader>{title}</ModalHeader>
        <ModalBody>{children}</ModalBody>
        <ModalFooter gap="16px">
          <Button onClick={handleClose} variant="outline">
            Fechar
          </Button>
          {!hideConfirmButton && (
            <Button
              loadingText="Salvando"
              isLoading={loading}
              onClick={onConfirm}
              colorScheme="blue"
            >
              {confirmText}
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
