import { toast } from 'react-toastify';
import { ApiCallContextProps } from '../utils/hooks/useApiCall';
import { UserFormProps } from '../components/Users/UserModal';

export const createUser = async (
  payload: Partial<UserFormProps>,
  api: ApiCallContextProps
) => {
  try {
    await api.post?.('/users', payload);
  } catch (error) {
    throw new Error('Ocorreu um erro ao criar o usuário');
  }
};

export const modifyUser = async (
  id: number,
  payload: Partial<UserFormProps>,
  api: ApiCallContextProps
) => {
  try {
    await api.put?.(`/users/${id}`, payload);
  } catch (error) {
    throw new Error('Ocorreu um erro ao editar Usuário');
  }
};

export const deleteUser = async (id: number, api: ApiCallContextProps) => {
  try {
    await api.del?.(`/users/${id}`);
    toast.success('Usuário excluído com sucesso');
  } catch (error) {
    throw new Error('Ocorreu um erro ao excluir Usuário');
  }
};

export const getManyUser = async (api: ApiCallContextProps) => {
  try {
    await api.get?.(`/users`);
  } catch (error) {
    throw new Error('Ocorreu um erro ao recuperar informações');
  }
};

export const getOneUser = async (id: number, api: ApiCallContextProps) => {
  try {
    await api.get?.(`/users/${id}`);
  } catch (error) {
    throw new Error('Ocorreu um erro ao recuperar informações');
  }
};

export const updateUserPermissions = async (
  id: number,
  incomingPermissions: { [key: string]: boolean },
  api: ApiCallContextProps
) => {
  try {
    await api.put?.(`/users/permissions/${id}`, incomingPermissions);
  } catch (error) {
    throw new Error('Ocorreu um erro ao atualizar as permissões');
  }
};
