import axios, { AxiosRequestConfig } from 'axios';

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const getAuthBearer = (
  token: string,
  customHeaders: AxiosRequestConfig['headers']
) => ({
  headers: {
    authorization: `Bearer ${token}`,
    ...customHeaders,
  },
});

interface RequestData<T> {
  data: { data: T };
}

const getRequestData = async <T>(request: Promise<RequestData<T>>) => {
  const {
    data: { data },
  } = await request;

  return data;
};

export const api = (token: string) => ({
  get: async <T>(url: string, customHeaders?: AxiosRequestConfig['headers']) =>
    await getRequestData<T>(
      axiosClient.get(url, getAuthBearer(token, customHeaders))
    ),
  post: async <Y, T>(
    url: string,
    payload: Y,
    customHeaders?: AxiosRequestConfig['headers']
  ) =>
    await getRequestData<T>(
      axiosClient.post(url, payload, getAuthBearer(token, customHeaders))
    ),
  put: async <Y, T>(
    url: string,
    payload: Y,
    customHeaders?: AxiosRequestConfig['headers']
  ) =>
    await getRequestData<T>(
      axiosClient.put(url, payload, getAuthBearer(token, customHeaders))
    ),
  del: async <T>(url: string, customHeaders?: AxiosRequestConfig['headers']) =>
    await getRequestData<T>(
      axiosClient.delete(url, getAuthBearer(token, customHeaders))
    ),
  download: async (
    url: string,
    customHeaders?: AxiosRequestConfig['headers']
  ) => axiosClient.get(url, getAuthBearer(token, customHeaders)),
});

export const publicApi = () => ({
  get: async <T>(url: string) => await getRequestData<T>(axiosClient.get(url)),
  post: async <Y, T>(url: string, payload: Partial<Y>) =>
    await getRequestData<T>(axiosClient.post(url, payload)),
});
